import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/authSlice";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { userRoleTypes } from "../utils/userRoleTypes";
import { useSetAtom } from "jotai";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import JSCookies from "js-cookie";

export const useAuthService = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setUserData = useSetAtom(userInfoAtom);

  const loggedInUser = (userData) => {
    const { role, data } = userData;

    if (role === userRoleTypes.USER || role === userRoleTypes.ADMIN) {
      const { firstname, lastname } = data || {};

      // Set user info in local state
      setUserInfo({ firstname, lastname });

      // Store user data in local storage
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("token", JSON.stringify(userData?.token));
      localStorage.setItem("role", JSON.stringify(role));
      // Set user data in cookies
    //   Cookies.set("user", userData);
    //   Cookies.set("token", userData?.token);
      cookies.set("user", userData);
      cookies.set("token", userData?.token);
      JSCookies.set("token", userData?.token, { expires: 90000000 });
      // Dispatch user info to Redux store
      dispatch(setUserInfo(userData));

      // Dispatch to atom
      setUserData({
        token: userData?.token,
        user: userData,
        role: role,
      });
      // Redirect based on user role
      const redirectPath =
        role === userRoleTypes.ADMIN ? "/AdminDashboard/AllVideos" : "/";
      navigate(redirectPath);

      return;
    }

    // Handle invalid user type
    toast.error("User Type is Invalid");
  };

  const loggedOutUser = (redirectToOtherUrl) => {
    JSCookies.remove("token");
    cookies.remove("user");
    dispatch(setUserInfo(null));
    localStorage.removeItem("userData");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    dispatch(setUserInfo(null));

    
      // Dispatch to atom
      setUserData({
        token: null,
        user: null,
        role: null,
        subscriptionId: null,
      });

    navigate(redirectToOtherUrl ? redirectToOtherUrl : "/login");
  };

  return {
    loggedInUser,
    loggedOutUser,
  };
};
