import { useSelector } from "react-redux";
import { useState } from 'react';
import PersonIcon from "@mui/icons-material/Person";

function UserImage({
  user,
  width = 38,
  height = 38,
  isComment,
  withBorder,
  imageURL,
  ...props
}) {
  const userInfo = useSelector((state) => state.authState.userInfo);

  // if ((!user?.image && isComment) || !userInfo?.image) {
  if (!imageURL) {
    return (
      <PersonIcon
        style={{
          width,
          height,
          borderRadius: "5000px",
          fill: "white",
          backgroundColor: "#D24545",
        }}
        {...props}
      />
    );
  }

  return (
    <img
      style={{
        width,
        height,
        borderRadius: "5000px",
        objectFit: "cover",
        border: withBorder ? "2px solid #383838" : "none",
      }}
      // src={user?.image ?? userInfo.image}
      src={imageURL}
      width={width}
      height={height}
      {...props}
    />
  );
}

export default UserImage;
