import React, { useState, useEffect } from "react";
import "../../App.css";
import Filter from "../../Asset/images/icon/Filters-lines.svg";
import Video_title from "../Video_title";
import Card from "../Card";
import axios from "axios";
import { IoMdHeartEmpty } from "react-icons/io";
import FilterModal from "../FilterModal";
import { FcLike } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../Pagination/CustomPagination";
import tagApi from "../../api/tagApi";
import checkApiStatus from "../SubscripStatus";
import MobileViewSearch from "../MobileViewSearch";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import toast from "react-hot-toast"
import AddToPlayListModal from "../playlist/personal-playlist/AddToPlayListModal";
import api from "../../data/api";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import { useAtomValue } from "jotai";


const Savedvideos = () => {
  axios.defaults.withCredentials = true;
  const [responseData, setResponseData] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [startIndex, setStartIndex] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [selectedLevels, setselectedLevels] = useState([]);
  const [tags, setTags] = useState([]);
  const [sortby, setSortBy] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [videos, setVideos] = useState("");
  const [modal, setModal] = useState(false);
  const [videoData2, setVideoData2] = useState({});
  const userData = useAtomValue(userInfoAtom)

  const redirect = useNavigate();

  useEffect(() => {
    if (!userData.token) {
      redirect("/SignUp");
    }
  }, [redirect, userData.token]);
  //================Add playList================
  const handelSubmitPlaylist = async (_id) => {
    //setModal(true)
    try {
      const response = await api.videoPlayerPage(_id);
      if (response.status === 200) {
        setModal(false)
        const jsonData = (response.data[0]);
        setVideoData2(jsonData);
        setModal(true)
      }
    } catch (error) {
      setModal(false);
      toast.error('Something went wrong', {
        position: 'top-center',
        duration: 3000
      })
    }
  }

  const handleSubmit = async (id, video) => {
    console.log(id);
    const isLiked = video.videoDetails?.savedvideo || video.savedvideo;
    try {
      if (isLiked) {
        const response = await api.unsaveWishlist(id);
        console.log(response.data);
        const uniqueIdentifier = id;
        if (response.status === 200) {
          const updatedResponseData = responseData.filter(
            (video) => video?._id !== uniqueIdentifier
          );

          // Update the state (setResponseData) with the updated responseData
          setResponseData(updatedResponseData);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  //--------for video player page------
  const handlePlayerVideo = async (id) => {
    const apiStatus = await checkApiStatus(userData.token);
      if (apiStatus) {
        redirect(`/PlayerPage?v=${id}`);
      } else {
        // User is logged in but not subscribed, redirect to PlanPage
        redirect("/Plans");
      }
  };
  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    console.log(newPage);
    if (newPage <= 0) {
      newPage = 1;
    }
    setCurrentPage(newPage);
    const newStartIndex = (newPage - 1) * itemsPerPage;
    setStartIndex(newStartIndex);
  };

  const handleTagChange = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((prevTag) => prevTag !== tag)
        : [...prevTags, tag]
    );
  };

  useEffect(() => {
    fetchVideosByTags(selectedTags, selectedDurations, selectedLevels);
  }, [currentPage, itemsPerPage, startIndex, selectedTags, selectedDurations, selectedLevels, sortby]);

  useEffect(() => {
    tagApi.getAllTags().then((response) => {
      setTags(response.data);
    });
  }, []);

  const handleFilterChange = (event) => {
    setSortBy(event.target.value);
  };

  const fetchData = async (selectedTags, selectedDurations, selectedLevels) => {
    try {
      const endIndex = startIndex + itemsPerPage - 1;
      const selectedTagsIds = selectedTags?.map((tag) => tag).join(",");
      const durations = selectedDurations
        ?.map((duration) => duration)
        .join(",");
      const levels = selectedLevels?.map((level) => level).join(",");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/save/all/video`,
        {
          startIndex,
          endIndex,
          selectedTags: selectedTagsIds,
          selectedDurations: durations,
          selectedLevels: levels,
          sortby: sortby || "latest",
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          }
        }
      );
      console.log(response.data.videoCount);
      setTotalUsers(Math.ceil(response.data.videoCount / itemsPerPage));
      console.log("reponse from backend", response.data.savesVideosData);
      setResponseData(response.data.savesVideosData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchVideosByTags = async (
    selectedTags,
    selectedDurations,
    selectedLevels
  ) => {
    try {
      fetchData(selectedTags, selectedDurations, selectedLevels);
    } catch (error) {
      console.error("Error fetching filtered videos: ", error);
    }
  };

  useEffect(() => {
    // Add event listener to detect clicks outside c-filter_btn-one
    const handleClickOutside = (event) => {
      const filterBtn = document.querySelector(".unique");
      if (filterBtn && !filterBtn.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Attach the event listener
    document.addEventListener("click", handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOpenFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleApplyFilter = (
    selectedTags,
    selectedDurations,
    selectedLevels
  ) => {
    console.log("tags from filtermodal", selectedTags);
    setSelectedTags(selectedTags);
    setselectedLevels(selectedLevels);
    setSelectedDurations(selectedDurations);
    setIsFilterModalOpen(false);
  };

  const handleResetFilter = () => {
    // Reset your filter options
    setSelectedTags([]);
    // You may need additional logic to reset other filter options
  };

  return (
    <>
      {/* ///////////////////////////////////////////////Mobile */}
      <div id="c-mobile_search">
        <div class="row m-0 c-tab_box">
          <div class="p-0">
            <div className="c-filter_btn">
              <MobileViewSearch />
              <div className="c-filter_btn-one c-mobile_filter_btn1">
                <img src={Filter} onClick={handleOpenFilterModal} alt="" />
                <span className="filterby-2">
                  {isFilterModalOpen && (
                    <FilterModal
                      isOpen={isFilterModalOpen}
                      tags={tags}
                      checkedTags={selectedTags}
                      handleTagChange={handleTagChange}
                      checkedDuration={selectedDurations}
                      checkedLevels={selectedLevels}
                      handleApplyFilter={handleApplyFilter}
                      handleResetFilter={handleResetFilter}
                      handleClose={handleCloseFilterModal}
                    />
                  )}
                </span>
              </div>
              <div className="c-filter_btn-one c-mobile_filter_btn2">
                <span>Sort by</span>
                <select className="filterby" onChange={handleFilterChange}>
                  <option value={"latest"}>Latest First</option>
                  <option value={"oldest"}>Oldest First</option>
                  <option value={"title_asc"}>Title (A - Z)</option>
                  <option value={"title_desc"}>Title (Z - A)</option>
                  <option value={"long_duration"}>Long duration</option>
                  <option value={"short_duration"}>Short duration</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="tab-content">
          <div id="menu2" className="tab-pane fade active show">
            <div>
              <div className="c-tab_cata-text">
                <div id="c-mobile_tab_hide" className=" c-tab_cata mb-n9">
                  <div className="row m-0 c-tab_box">
                    <div className="col p-0">
                      <div className="c-filter_btn c-filter_btn-lessons">
                        <div
                          className="c-filter_btn-one c-filter_btn-tags unique "
                          style={{ cursor: "pointer", padding: "0px" }}
                        >
                          {/* <div className="c-filter_btn-tags"> */}
                          <span
                            onClick={handleOpenFilterModal}
                            className="c-filters-btn"
                            style={{
                              padding: "10px 16px",
                              display: "inline-block",
                            }}
                          >
                            {" "}
                            <img src={Filter} alt="" />
                            Filters
                          </span>
                          {isFilterModalOpen && (
                            <FilterModal
                              isOpen={isFilterModalOpen}
                              tags={tags}
                              checkedTags={selectedTags}
                              handleTagChange={handleTagChange}
                              checkedDuration={selectedDurations}
                              checkedLevels={selectedLevels}
                              handleApplyFilter={handleApplyFilter}
                              handleResetFilter={handleResetFilter}
                              handleClose={handleCloseFilterModal}
                            />
                          )}
                        </div>
                        <div className="c-filter_btn-one">
                          <span>Sort by</span>
                          <select
                            className="filterby"
                            onChange={handleFilterChange}
                          >
                            <option value={"latest"}>Latest First</option>
                            <option value={"oldest"}>Oldest First</option>
                            <option value={"title_asc"}>Title (A - Z)</option>
                            <option value={"title_desc"}>Title (Z - A)</option>
                            <option value={"long_duration"}>
                              Long duration
                            </option>
                            <option value={"short_duration"}>
                              Short duration
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>SAVED</h4>
              </div>
              <div className="c-video ">
                <div className="row c-video_textrow c-video_list pb-0">
                  <div className="col-md-8 c-video_textcol c-mobile_videotext-1 saved">
                    <h4>SAVED</h4>
                  </div>
                </div>
                {/* Your video list here */}
                <div className="c-video_list">
                  {responseData?.length === 0 ? (
                    <p className="c-save_page-p">
                      Your saved videos will appear here once you've added them,
                      making it easy to revisit your favorites anytime.
                    </p>
                  ) : (
                    <>
                      <div className="row m-0">
                    {responseData?.map((video, index) => (
                      <div key={index} className="col-md-3 c-mobile_col">
                        <div className="c-video_box">
                          <div className="c-video_icon-box">
                            <div
                              onClick={() =>
                                handlePlayerVideo(
                                  video.videoDetails?._id || video._id
                                )
                              }
                            >
                              <Card
                                thumb_url={
                                  video.videoDetails?.thumnailLink ||
                                  video.thumnailLink
                                }
                                video_url={
                                  video.videoDetails?.videolink ||
                                  video.videoDetails
                                }
                                thumbnail_pic={
                                  video.videoDetails?.thumnailLink ||
                                  video.thumnailLink
                                }
                                video_duration={
                                  video.videoDetails?.duration || video.duration
                                }
                              />
                            </div>
                            {userData.token && (
                              <PlaylistAddRoundedIcon
                                fontSize="large"
                                style={{
                                  cursor: "pointer",
                                  boxSizing: "border-box",
                                  position: "absolute",
                                  top: "1px",
                                  right: "1px",
                                }}
                                onClick={() => handelSubmitPlaylist(video.videoDetails?._id || video?._id)}
                              />
                            )}
                            {userData.token && (
                              <div className="c-video_time">
                                <span
                                  id={video.videoDetails?._id || video._id}
                                  onClick={(e) => {
                                    const id = e.currentTarget.id; // Accessing the id from the clicked element
                                    handleSubmit(id, video);
                                  }}
                                >
                                  {video.videoDetails?.savedvideo ||
                                    video.savedvideo ? (
                                    <FcLike />
                                  ) : (
                                    <IoMdHeartEmpty />
                                  )}
                                </span>
                              </div>
                            )}
                          </div>

                          {/* Add conditional rendering for Video_title */}
                          <div
                            onClick={() =>
                              handlePlayerVideo(
                                video.videoDetails?._id || video._id
                              )
                            }
                          >
                            {video?.videoDetails ? (
                              <Video_title
                                title={video.videoDetails.title || ""}
                                tags={
                                  (video.videoDetails.tagsDetails || [])
                                    .map((tag) => ({
                                      name: tag.name,
                                      color: tag.color,
                                      priority: tag.priority || 0, // Assign priority or default to 0
                                    }))
                                    .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                                }
                                releaseDate={video.videoDetails.createdAt || ""}
                              />
                            ) : (
                              <Video_title
                                title={video?.title || ""}
                                tags={
                                  (video?.tagsDetails || [])
                                    .map((tag) => ({
                                      name: tag.name,
                                      color: tag.color,
                                      priority: tag.priority || 0, // Assign priority or default to 0
                                    }))
                                    .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                                }
                                releaseDate={video?.createdAt || ""}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  </div>  
                  </>
                  )}
                </div>

                <CustomPagination
                  totalPages={totalUsers}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddToPlayListModal
        modal={modal}
        setModal={setModal}
        activeObject={videoData2}
        viewData={videos}
      />
    </>
  );
};

export default Savedvideos;
