import { createSlice } from "@reduxjs/toolkit";

const initState = {
  personalPlayList: [],
};

export const personalPlayListSlice = createSlice({
  name: "personalPlayList",
  initialState: initState,
  reducers: {
    getPersonalPlayList: (state, { payload }) => {
      if (payload?.length > 0 && payload[0]?.videos?.length > 0) {
        const tmpStr = JSON.stringify(payload);
        const tmp = JSON.parse(tmpStr);

        tmp[0].videos = tmp[0].videos.map((item) => {
          if (item?.tagsDetails && item.tagsDetails.length > 0) {
            item.tagsDetails.sort((a, b) => {
              return a.priority - b.priority;
            });
            item.tagsData = item.tagsDetails;
          }

          if (item?.tagsData && item.tagsData.length > 0) {
            item.tagsData.sort((a, b) => {
              return a.priority - b.priority;
            });
          }

          return item;
        });

        state.personalPlayList = tmp;
      } else {
        state.personalPlayList = payload;
      }
    },
  },
});

export const { getPersonalPlayList } = personalPlayListSlice.actions;

export default personalPlayListSlice.reducer;
