import React from 'react'
import { ProduceBanners } from './components/ProduceBanners'
import { useLocation } from 'react-router-dom'
import { useFetchBannerById } from '../../data/swrs/banners'
import api from '../../data/api'
import { toast } from 'react-toastify'

const EditBanners = () => {

  //get banner id from url using useLocation
  const location = useLocation()
  const bannerId = location.pathname.split('/')[3]

  const {data:bannerDetails, error} = useFetchBannerById(bannerId)

  if(error){
    return <div>{"Something Went Wrong"}</div>
  }

  const initialValues = {
    title: bannerDetails?.data?.title,
    image: bannerDetails?.data?.image,
    isActive: bannerDetails?.data?.isActive,
    releaseDate: bannerDetails?.data?.releaseDate ? new Date(bannerDetails?.data?.releaseDate) : new Date(),
  }

  const handleSubmit = async(title, image, releaseDate, isActive, token) => {
    return await api.editBannerApi(bannerId, title, releaseDate, isActive, image , token)
  }


  return <ProduceBanners initialValues={initialValues} onSubmit={handleSubmit}/>
}

export default EditBanners