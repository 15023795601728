
import React from "react";
import { Link } from "react-router-dom";
import videoTime from "../hook/useVideoTime";
import dayjs from "dayjs";
import getTimeElapsedWithFormat from "../hook/useTimeElapsed";
import { useDispatch, useSelector } from "react-redux";
import { FcLike } from "react-icons/fc";
import { IoMdHeartEmpty } from "react-icons/io";

const VideoCard = ({ video, handleWishList, wishListArr, savedVideos, handleSaveUnsave, fld, handleDeleteVideo, featuredPlayListView, subscriptionStatus }) => {

  const userInfo = useSelector((state) => state.authState.userInfo);
  console.log("videoCard", fld)

  const getLink = () => {
    if (subscriptionStatus) {
      return `/${(featuredPlayListView) ? "featured-playlist-view" : "personal-playlist-view"}/${fld?._id}/${video?._id}`
    }
    return `/Plans`
  }

  return (
    <div className="c-featured_box-rightd1 c-featured_box-rightd1nth">
      <div className="thumbnail__box position-relative">
        <Link to={`${getLink()}`}>
          <div className="c-featured_box-rightd2">
            <div className="c-featured_box-rightd22">
              <img src={video?.thumnailLink} alt="" />
              <span>{video?.duration ? videoTime(video?.duration) : ""}</span>
            </div>
          </div>
        </Link>
        <div className="c-featured_box-right-d1">
          {/* has__trash */}
          <ul className={fld?.type === "PPL" ? "m-0 d-flex flex-column has__trash" : "m-0 d-flex flex-column"} style={{ gap: "0.35rem" }}>
            {userInfo && fld?.type === "PPL" &&
              <li className="mb-0">
                <button type="button" className="trash__btn btn p-0 " onClick={(e) =>
                  handleDeleteVideo(
                    e,
                    fld,
                    video
                  )
                }>
                  <i className="fa-regular fa-trash-can"></i>
                </button>
              </li>
            }
            {userInfo &&
              <li className="mb-0">
                <span
                  id={video?._id}
                  className="c-featured_box-right-ha c-video_time"
                  // onClick={(e) => handleWishList(e, fld, video)}
                  onClick={(e) => {
                    const id = e.currentTarget.id
                    handleSaveUnsave(id, video)
                  }}
                >
                  {/* {wishListArr?.some(
                    (ws) =>
                      ws?.object_id === fld?._id &&
                      ws?.video_id === video?._id &&
                      ws?.wishlist
                  ) ? (
                    <i className="fa-solid fa-heart"></i>
                  ) : (
                    <i className="fa-regular fa-heart"></i>
                  )} */}
                  {
                    savedVideos?.length > 0 && savedVideos.includes(video?._id) ? (
                      <FcLike />
                    ) : (
                      <IoMdHeartEmpty />
                    )
                  }
                </span>
              </li>
            }
          </ul>
        </div>
      </div>
      <div className="caption c-mobile_box-fea-la position-relative">
        <div className="c-featured_box-right-b1">
          <Link to={`${getLink()}`}>
            <h4>{video?.title}</h4>
          </Link>
          <div className="c-video_tag">
            {video?.tagsData &&
              Array.isArray(video?.tagsData) &&
              video?.tagsData?.length > 0 && (
                <ul>
                  {video?.tagsData?.map((tg, tinx) => {
                    return (
                      <li style={{ backgroundColor: tg?.color }} key={tinx}>
                        <a href={`/AllVideos?tag=${tg?._id}`}>{tg?.name}</a>
                      </li>
                    );
                  })}
                </ul>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
