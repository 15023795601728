import React from 'react'
import { ModalTitle } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

function DeleteModalPlayList({ modal, onConfirmAction, onCancelAction }) {
  return (
    <Modal centered isOpen={modal} toggle={onCancelAction}>
      <ModalHeader>Delete Confirmation</ModalHeader>
      <ModalBody>
        Are you sure you want to delete this playlist?
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={() => onCancelAction()}>Cancel</Button>
        <Button color='danger' onClick={() => onConfirmAction()}>Confirm</Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModalPlayList
