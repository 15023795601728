import { useState, useEffect, useRef } from "react";
import "./Asset/Font-family/GoodTimesRg-Regular.ttf";
import "./Asset/Font-family/GoodTimesRg-Regular.woff";
import "./Asset/Font-family/GoodTimesRg-Regular.eot";
import Header from "./Layouts/UserLayout/Header";
import Sidebar from "./Layouts/UserLayout/Sidebar";
import Futter from "./component/Futter";
import GoToTop from "./component/GoToTop";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

function App() {
  const userInfo = useSelector((state) => state.authState.userInfo);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === "/personal-playlist" && !userInfo?.userId) {
      navigate("/");
    }
  }, [location, navigate, userInfo]);

  const [isSidebarVisible, setIsSidebarVisible] = useState(
    window.innerWidth > 991
  );
  
  const sidebarRef = useRef(null);

  const toggleSidebar = (event) => {
    event.stopPropagation(); // Stop the click event from propagating
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleOutsideClick = (event) => {
    if (window.innerWidth > 991) {
      setIsSidebarVisible(isSidebarVisible);
    } else if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target)
    ) {
      // Clicked outside the sidebar, so close it
      setIsSidebarVisible(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarVisible(window.innerWidth > 991);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isSidebarVisible) {
      window.addEventListener("click", handleOutsideClick);
    } else {
      window.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick, isSidebarVisible]);


  return (
    <div>
      <Header HideSideBar={toggleSidebar} />
      <section className="c-full_body row m-0 p-0">
        {isSidebarVisible && (
          <div className="c-side_bar p-0" ref={sidebarRef}>
            <Sidebar
              closeCallback={() => {
                if (window.innerWidth < 991) {
                  setIsSidebarVisible(false);
                }
              }}
            />
          </div>
        )}
        <div className="c-body_section">
          <Outlet/>
          <Futter />
        </div>
      </section>
      <Toaster />
      <GoToTop />
    </div>
  );
}

export default App;
