import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const featuredPlaylistApi = createApi({
  tagTypes: ["featuredPlaylist"],
  reducerPath: "featuredPlaylistApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      if (getState()?.authState?.userToken) {
        headers.set("Authorization", "Bearer " + getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    featuredPlayList: builder.query({
      query: (payload) => ({
        url: "featurevideo",
        headers: {
          "Authorization": `Bearer ${payload.token}`,
        }
      }),
      providesTags: ["featuredPlaylist"],
    }),
    submitFeaturedPlayList: builder.mutation({
      query: (payload) => ({
        url: "featurevideo/save",
        method: "POST",
        body: payload
      }),
      invalidatesTags: ["featuredPlaylist"],
    }),
    featuredPlayListById: builder.query({
      query: (data) => ({
        url: `featurevideo/${data.playlist}/${data.id}`,
        method: "GET",
      }),
      providesTags: ["featuredPlaylist"],
    }),
    deleteFeaturedPlayList: builder.mutation({
      query: (id) => ({
        url: `featurevideo/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["featuredPlaylist"],
    }),
  }),
});
export const {
  useFeaturedPlayListQuery,
  useSubmitFeaturedPlayListMutation,
  useFeaturedPlayListByIdQuery,
  useDeleteFeaturedPlayListMutation,
} = featuredPlaylistApi;

export const personalPlaylistApi = createApi({
  tagTypes: ["personalPlaylist"],
  reducerPath: "personalPlaylistApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      if (getState()?.authState?.userToken) {
        headers.set("Authorization", "Bearer " + getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    personalPlayList: builder.mutation({
      query: (payload) => ({
        url: "personalvideo",
        method: "POST",
        body: payload,
      }),
      providesTags: ["personalPlaylist"],
    }),
    submitPersonalPlayList: builder.mutation({
      query: (payload) => ({
        url: "personalvideo/save",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["personalPlaylist"],
    }),
    personalPlayListById: builder.query({
      query: (data) => ({
        url: `personalvideo/${data.playlist}/${data.id}`,
        method: "GET",
      }),
      providesTags: ["personalPlaylist"],
    }),
    deletePersonalPlayList: builder.mutation({
      query: (payload) => ({
        url: `personalvideo/delete/${payload}`,
        method: "DELETE",
      }),
      invalidatesTags: ["personalPlaylist"],
    }),
    addToPlayList: builder.mutation({
      query: (payload) => ({
        url: "personalvideo/playlist/add",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["personalPlaylist"],
    }),
  }),
});

export const {
  usePersonalPlayListMutation,
  useSubmitPersonalPlayListMutation,
  usePersonalPlayListByIdQuery,
  useDeletePersonalPlayListMutation,
  useAddToPlayListMutation,
} = personalPlaylistApi;

export const combinePlaylistApi = createApi({
  reducerPath: "combinePlaylistApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      if (getState()?.authState?.userToken) {
        headers.set("Authorization", "Bearer " + getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCombinedVideosById: builder.query({
      query: (id) => `combined/videos/${id}`,
    }),
  }),
});

export const { useGetCombinedVideosByIdQuery } = combinePlaylistApi;
export const videoListApi = createApi({
  tagTypes: ["videoList"],
  reducerPath: "videoListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      if (getState()?.authState?.userToken) {
        headers.set("Authorization", "Bearer " + getState()?.authState?.userToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getVideosList: builder.query({
      query: (id) => ({
        url: `video/fetch`,
        method: "GET",
      }),
      providesTags: ["videoList"],
    }),
    dropDownPersonalList: builder.query({
      query: (userId) => ({
        url: `personalvideo/fetch/all/${userId}`,
        method: "GET",
      }),
      providesTags: ["videoList"],
    }),
  }),
});
export const { useGetVideosListQuery, useDropDownPersonalListQuery } =
  videoListApi;

export const wishlistApi = createApi({
  tagTypes: ["wishlist"],
  reducerPath: "wishlistApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      if (getState()?.authState?.userToken) {
        headers.set("Authorization", "Bearer " + getState()?.authState?.userToken);
      }
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => ({
    saveWishlist: builder.mutation({
      query: (payload) => ({
        url: "save/Wishlist/video",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["wishlist"],
    }),
    unSaveWishlist: builder.mutation({
      query: (payload) => ({
        url: "unsave/Wishlist/video",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["wishlist"],
    }),
    personalWishlist: builder.mutation({
      query: (payload) => ({
        url: "personalvideo/wishlist",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["wishlist"],
    }),
    featuredWishlist: builder.mutation({
      query: (payload) => ({
        url: "featurevideo/wishlist",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["wishlist"],
    }),
  }),
});
export const {
  useSaveWishlistMutation,
  useUnSaveWishlistMutation,
  usePersonalWishlistMutation,
  useFeaturedWishlistMutation,
} = wishlistApi;
