import axios from 'axios';

const token = localStorage.getItem('token');
const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    ...(token && { 'Authorization': `Bearer ${token.replace(/"/g, '')}` }),
  },
});

axiosConfig.defaults.withCredentials = true;

export default axiosConfig;
