import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      // Add any common headers here
    },
})

const tagApi = {
    getAllTags: () => instance.get(`/get/tags`),
    addTag: (newTag) => instance.post(`/add/tags`, { newTag })
}

export default tagApi;