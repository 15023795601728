import { generateReactHelpers } from "@uploadthing/react/hooks";

// DEV
const baseUrl = "http://localhost:9002";

// STAGING
// const baseUrl = "https://orca-app-ixna2.ondigitalocean.app";

// PROD
// const baseUrl = "https://app.thegodfreymethod.com";

export const { useUploadThing, uploadFiles } = generateReactHelpers({
  url: baseUrl + "/api/uploadthing",
  headers: {
    "Authorization": "Bearer " + extractTokenFromCookie(),
  }
});

console.log(useUploadThing, uploadFiles);

function extractTokenFromCookie() {
  const cookies = document.cookie.split("; ");
  const tokenCookie = cookies.find(cookie => cookie.startsWith("token="));
  if (tokenCookie) {
    return tokenCookie.split("=")[1];
  } else {
    return "";
  }
}