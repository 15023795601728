import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFeaturedPlayListByIdQuery } from "../../../service";
import videoTime from "../../../hook/useVideoTime";
import ReadMoreLess from "../../common/ReadMoreLess";
import Rectangle124 from "../../../Asset/images/Rectangle-124.png";
import LazyImage from "../../LazyImage";
import SearchButton from "../../searchPage/SearchButton";

function FeturedPlayListView() {
  const params = useParams();
  console.log("params", params);
  let resGetById = useFeaturedPlayListByIdQuery({playlist: params.playlist, id: params.id});
  const [activeObject, setActiveObject] = useState(null);
  const [viewData, setViewData] = useState(null);
  console.log("viewData", viewData);
  console.log("activeObject", activeObject);

  const [searchKey, setSearchKey] = useState("");
  const redirect = useNavigate();
  const handleSearchVideo = async (e) => {
    if (searchKey.length >= 3) {
      redirect(`/result?search_query=${searchKey}`);
    }
  };

  useEffect(() => {
    if (resGetById?.isSuccess && resGetById?.data?.existingVideos) {
      setViewData(resGetById?.data?.existingVideos);
      setActiveObject(resGetById?.data?.existingVideos?.videos[0]);
    }
  }, [resGetById]);

  const handleActiveObject = (e, dt) => {
    e.preventDefault();
    setActiveObject(dt);
  };

  return (
    <div>
      <div className="row m-0 c-mobile_honm-serch">
        <div className="c-header_search">
          <form action="">
            <input
              onChange={(e) => {
                // Handle onChange logic here if needed
                setSearchKey(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  handleSearchVideo(e.target.value);
                }
              }}
              value={searchKey}
              type="text"
              id="search"
              placeholder="Search"
            />
          </form>
          <SearchButton searchKey={searchKey}/>
        </div>
      </div>
      <div className="c-video-section">
        <div className="row">
          <div className="col-md-10">
            <div className="c-video-player_frame">
              {activeObject && activeObject?.videolink ? (
                <iframe
                  width="100%"
                  height="100%"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                  src={activeObject?.videolink}
                ></iframe>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          {viewData?.videos &&
            Array.isArray(viewData?.videos) &&
            viewData?.videos?.length > 0 && (
              <div className="col-md-2">
                <div className="c-video-set-list">
                  <div className="c-video-set-list-h3">
                    <h3>NEXT UP</h3>
                  </div>
                  <div className="c-video-set-listbox" style={{height: '100%'}}>
                    { viewData.videos.length > 1 ? viewData?.videos?.map((el, inx) => {
                      if (inx > 0)
                      return (
                        <Link to={`/featured-playlist-view/${params.playlist}/${el._id}`}>
                          <div
                            className="c-video_thumbnail"
                            key={inx}
                            // onClick={(e) => handleActiveObject(e, el)}
                          >
                            <LazyImage
                              src={el?.thumnailLink}
                              alt="Video Thumbnail"
                              loading="lazy"
                            />
                            {el?.duration && (
                              <div className="c-video_time">
                                <span>{videoTime(el?.duration)}</span>
                              </div>
                            )}
                            {/* {isLoggedIn ? (
                      <iframe src={video_url}></iframe>
                    ) : (
                      <img src={thumbnail_pic} alt="Video Thumbnail" />
                    )} */}

                            {/* {isPlayerPage ? null : (
                      <div className="c-video_time">
                        <span>{formattedDuration}</span>
                      </div>
                    )} */}
                          </div>
                        </Link>
                      );
                      })
                    : 
                    <p>This is the end of this playlist.</p>
                    }
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>

      <div className="c-video_description">
        <div className="row m-0">
          <div className="col-md-9">
            <div className="c-video_description-h4">
              <h4>{activeObject?.title}</h4>
            </div>
            {activeObject?.tagsData &&
              Array.isArray(activeObject?.tagsData) &&
              activeObject?.tagsData?.length > 0 && (
                <div className="c-video_description-tag">
                  <ul>
                    {activeObject?.tagsData?.map((tag) => (
                      <li
                        style={{ backgroundColor: tag?.color }}
                        key={tag?.name}
                      >
                        <Link to="" style={{ color: "white" }}>
                          {tag?.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            <div className="c-video_description-text">
              <ReadMoreLess
                text={activeObject?.description}
                detailsPage={true}
              />
            </div>
          </div>
          {activeObject?.productsData && activeObject?.productsData.length > 0 && (
            <div className="col-md-3 c-video_description-side">
              <div>
                <div className="c-video_description-side-h4">
                  <h4>PRODUCTS SEEN IN VIDEO</h4>
                </div>
                <div className="c-mobile_ads">
                  {(activeObject?.productsData || []).map((product) => (
                    <div
                      key={product.title}
                      className="c-video_description-sideimg"
                      style={{ marginBottom: "10px" }}
                    >
                      <img src={product.images[0].src} alt="" />
                      <a
                        href={
                          "https://thegodfreymethod.myshopify.com/products/" +
                          product.handle
                        }
                        target="_blank"
                        style={{
                          color: "#000",
                          borderColor: "#000",
                        }}
                      >
                        BUY NOW
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeturedPlayListView;
