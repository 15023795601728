const SearchIcon = () => {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
    >
        <path
        d="M17.8516 17.5L14.2266 13.875M16.1849 9.16667C16.1849 12.8486 13.2001 15.8333 9.51823 15.8333C5.83633 15.8333 2.85156 12.8486 2.85156 9.16667C2.85156 5.48477 5.83633 2.5 9.51823 2.5C13.2001 2.5 16.1849 5.48477 16.1849 9.16667Z"
        stroke="#71717A"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </svg>
  )
}

export default SearchIcon