import React, { useEffect } from "react";
import Logo from "../../../Asset/images/logo-blaxk.png";
import { useForm, useWatch, Controller } from "react-hook-form";
import { FormFeedback, Input } from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddToPlayListMutation,
  useGetVideosListQuery,
  usePersonalPlayListByIdQuery,
  useSubmitPersonalPlayListMutation,
} from "../../../service";
import { getVideoDropDownList } from "../../../redux/videoSlice";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useState } from "react";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../../data/atoms/utilsAtoms";
import playlistApi from "../../../api/playlistApi";
import { useFetchGetDropdownVideoList } from "../../../data/swrs/playlist";

function PersonalPlayListForm({ show, setShow, editPlaylistID, handleClose, nshowModal, setnModalShow }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authToken = useSelector((state) => state.authState.userToken);
  const videoDropdownList = useSelector((state) => state.videoState.videoDropdownList);
  // const videoDropdownList = useFetchGetDropdownVideoList()
  const userInfo = useSelector((state) => state.authState.userInfo);
  const [reqPersonal, resPersonal] = useSubmitPersonalPlayListMutation();
  const resVideo = useGetVideosListQuery();
  const resGetById = usePersonalPlayListByIdQuery(editPlaylistID, {
    skip: !editPlaylistID,
  });

  const { control, handleSubmit, formState: { errors, value }, reset } = useForm();
  const [successShown, setSuccessShown] = useState(false); // Track whether success message has been shown
  const [name, setName] = useState("");
  const [videos, setVideos] = useState([])

  useEffect(() => {
    if (resVideo.isSuccess && resVideo.data) {
      const res1 = resVideo.data.map((el) => ({
        ...el,
        label: el.title,
        value: el.title,
      }));
      dispatch(getVideoDropDownList(res1));
    }
  }, [resVideo, dispatch]);

  useEffect(() => {
    if (resGetById.isSuccess && resGetById.data?.existingVideos) {
      const { existingVideos } = resGetById.data;
      reset({
        createdAt: existingVideos.createdAt,
        description: existingVideos.description,
        name: existingVideos.name,
        videos: existingVideos.videos,
        id: existingVideos._id,
        userId: userInfo.userId,
      });
    }
  }, [resGetById.isSuccess, reset, userInfo]);

  const userData = useAtomValue(userInfoAtom)
  console.log(userData)
  console.log("editPaylistID",editPlaylistID)

  const onNext = async (state) => {
    console.log("state", state);
    
    const response = await playlistApi.saveNewPlaylist({ 
      name: name, 
      // description: description, 
      videos: state.videos, 
      userId: userInfo?.userId 
    })
    console.log("response",response)
    setShow(false)
    setnModalShow(false)
    window.location.reload();
  };

  return (
    <>
      <section className="">
        <div className="container">
          <div>

            <div className="">
              <div>
                <form onSubmit={handleSubmit(onNext)}>
                  <div className="row g-2">
                    <div className="col-md-12 form-group">
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Name"
                      />
                      {errors.name && (
                        <FormFeedback>{errors?.name?.message}</FormFeedback>
                      )}
                    </div>
                    <div className="col-md-12 form-group">
                      <Controller
                        id="videos"
                        name="videos"
                        control={control}
                        rules={{ required: "Video is required" }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            isMulti
                            isClearable
                            options={videoDropdownList || []}
                            className="react-select"
                            classNamePrefix="select"
                            placeholder="Select a videos"
                            value={value ? value : null}
                            onChange={onChange}
                          />
                        )}
                      /> 
                      {errors.videos && (
                        <FormFeedback>{errors?.videos?.message}</FormFeedback>
                      )}
                    </div>

                    <div className="col-md-12 form-group action__btns text-end">
                      <button
                        className="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PersonalPlayListForm;