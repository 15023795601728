import React, { useEffect, useState } from "react";
import Playlistimg from "../../Asset/images/playlist/playlist_imge.png";
import Featureimg from "../../Asset/images/features/features-thumbnail-1.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteFeaturedPlayListMutation,
  useDeletePersonalPlayListMutation,
  useFeaturedPlayListQuery,
  useFeaturedWishlistMutation,
  useGetCombinedVideosByIdQuery,
  usePersonalWishlistMutation,
  useSaveWishlistMutation,
  useSubmitPersonalPlayListMutation,
  useUnSaveWishlistMutation,
} from "../../service";

import { usePersonalPlayListMutation } from "../../service/index.js";
import { getFeaturedPlayList } from "../../redux/featuredPlayListSlice";
import getTimeElapsedWithFormat from "../../hook/useTimeElapsed";
import dayjs from "dayjs";
import getVideoDuration from "../../hook/useVideoDuration";
import videoTime from "../../hook/useVideoTime";
import ReadMoreLess from "../common/ReadMoreLess";
import toast from "react-hot-toast";
import allVideoTime from "../../hook/useAllVideoTime";
import checkApiStatus from "../SubscripStatus";
import { async } from "@vimeo/player";
import MobileViewSearch from "../MobileViewSearch";
import { AllPlayList } from "../common/AllPlayList";
import VideoCard from "../VideoCard";
import Dropdown from "react-bootstrap/Dropdown";
import { PlaylistEditModal } from "./personal-playlist/PlaylistEditModal";
import DeleteModal from "./personal-playlist/DeleteModal";
import DeleteModalPlayList from "./personal-playlist/DeleteModalPlayList";
import { userInfoAtom } from "../../data/atoms/utilsAtoms.js";
import { useAtomValue } from "jotai";
import api from "../../data/api/index.js";
import videoApi from "../../api/videoApi.js";

function AllPlaylistsPage() {
  const userInfo = useSelector((state) => state.authState.userInfo);
  const dispatch = useDispatch();
  const userData = useAtomValue(userInfoAtom);
  const [reqPersonalPlayList, resPersonalPlayList] =
    usePersonalPlayListMutation();
  const resFeaturedPlayList = useFeaturedPlayListQuery({
    token: userData.token ?? "",
  });
  const [reqDelete, resDelete] = useDeletePersonalPlayListMutation();
  const [reqPersonalDelete, resPersonalDelete] =
    useSubmitPersonalPlayListMutation();
  const [reqSaveWishlist] = useSaveWishlistMutation();
  const [reqUnSaveWishlist] = useUnSaveWishlistMutation();
  const [reqFeaturedWishlist] = useFeaturedWishlistMutation();
  const [reqPersonalWishlist] = usePersonalWishlistMutation();

  const [showModal, setModalShow] = useState(false);
  const [nshowModal, setnModalShow] = useState(false);
  const [editPlaylistID, setEditPlaylistID] = useState("");

  const [modal, setModal] = useState(false);
  const [playlistmodal, setPlaylistmodal] = useState(false);
  const [updatePlayList, setUpdatePlayList] = useState(null);
  const [playListId, setPlayListId] = useState(null);

  const indexFromUrl = window.location.hash.substring(1);
  const [index, setIndex] = useState(0)
  console.log("object", index);

  const { data: APL, refetch: refetchCombinedVideos } =
    useGetCombinedVideosByIdQuery(userData.user?.userId);
  const combinedVideos = APL?.combinedVideos;
  const FPL = useSelector(
    (state) => state.featuredPlayListState.featuredPlayList
  );
  const data = combinedVideos || FPL;

  console.log("combinedVideos", combinedVideos);
  console.log("FPL", FPL);

  useEffect(() => {
    refetchCombinedVideos();
  }, [
    userInfo?.userId,
    resPersonalPlayList?.isSuccess,
    resFeaturedPlayList?.isSuccess,
    index,
    refetchCombinedVideos,
    showModal,
    modal,
    nshowModal,
  ]);

  useEffect(() => {
    if (
      resFeaturedPlayList.isSuccess &&
      resFeaturedPlayList.data &&
      !userInfo
    ) {
      const { existingVideos } = resFeaturedPlayList.data;
      dispatch(getFeaturedPlayList(existingVideos));
    }
  }, [
    dispatch,
    userInfo,
    resFeaturedPlayList.isSuccess,
    resFeaturedPlayList.data,
  ]);

  const onEdit = (e, dt) => {
    e.preventDefault();
    setModalShow(!showModal);
    setEditPlaylistID(dt?._id);
  };

  const onDelete = (e, dt) => {
    e.preventDefault();
    setPlayListId(dt?._id);
    setPlaylistmodal(true);
  };

  useEffect(() => {
    reqPersonalPlayList({ userId: userInfo?.userId });
  }, [resFeaturedPlayList?.isSuccess, nshowModal]);

  // useEffect(() => {
  //   if (resDelete?.isSuccess) {
  //     reqPersonalPlayList({ userId: userInfo?.userId });
  //     setPlaylistmodal(false);
  //     toast.success("Playlist deleted Successfully", {
  //       position: "top-center",
  //       duration: 3000,
  //     });
  //   }
  //   if (resDelete?.isError) {
  //     setPlaylistmodal(false);
  //     toast.error("Something went wrong", {
  //       position: "top-center",
  //       duration: 3000,
  //     });
  //   }
  // }, [resDelete]);

  // useEffect(() => {
  //   const closeMenuOnClickOutside = (e) => {
  //     // Check if the clicked element is inside the menu or its trigger
  //     if (!e.target.closest(".outer-delete")) {
  //       // If not, close all menus
  //       setVideoStates({});
  //     }
  //   };

  //   document.addEventListener("click", closeMenuOnClickOutside);

  //   return () => {
  //     // Remove the event listener when the component is unmounted
  //     document.removeEventListener("click", closeMenuOnClickOutside);
  //   };
  // }, []);

  // const shuffleArray = (array) => {
  //   const newArray = [...array];
  //   for (let i = newArray.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  //   }
  //   return newArray;
  // };

  // const handleShuffle = (e, id) => {
  //   e.preventDefault();
  //   if (
  //     resFeaturedPlayList?.data?.existingVideos &&
  //     Array.isArray(resFeaturedPlayList?.data?.existingVideos) &&
  //     resFeaturedPlayList?.data?.existingVideos?.length > 0
  //   ) {
  //     const shuffledArrayOfObjects =
  //       resFeaturedPlayList?.data?.existingVideos?.map((obj) => {
  //         return {
  //           ...obj,
  //           videos: obj?._id === id ? shuffleArray(obj?.videos) : obj?.videos,
  //         };
  //       });
  //     if (
  //       shuffledArrayOfObjects &&
  //       Array.isArray(shuffledArrayOfObjects) &&
  //       shuffledArrayOfObjects?.length > 0
  //     ) {
  //       dispatch(getFeaturedPlayList(shuffledArrayOfObjects));
  //     }
  //   }
  // };

  const [wishListArr, setWishListArr] = useState([]);
  // console.log("wishListArr From All", wishListArr);

  useEffect(() => {
    if (
      resPersonalPlayList?.isSuccess &&
      Array.isArray(resPersonalPlayList?.data?.existingVideos) &&
      resPersonalPlayList?.data?.existingVideos?.length > 0
    ) {
      // console.log("HI Personal")
      const recommendedPersonal = resPersonalPlayList.data.existingVideos
        .map((el) =>
          el?.videos?.map((el2) => ({
            object_id: el._id,
            video_id: el2._id,
            wishlist: el2?.wishlist,
          }))
        )
        .flat();

      setWishListArr((prevWishListArr) => [
        ...prevWishListArr,
        ...recommendedPersonal,
      ]);
    }

    if (
      resFeaturedPlayList?.isSuccess &&
      Array.isArray(resFeaturedPlayList?.data?.existingVideos) &&
      resFeaturedPlayList?.data?.existingVideos?.length > 0
    ) {
      // console.log("HI Featured")
      const recommendedFeatured = resFeaturedPlayList.data.existingVideos
        .map((el) =>
          el?.videos?.map((el2) => ({
            object_id: el._id,
            video_id: el2._id,
            wishlist: el2?.wishlist,
          }))
        )
        .flat();

      setWishListArr((prevWishListArr) => [
        ...prevWishListArr,
        ...recommendedFeatured,
      ]);
    }
  }, [resPersonalPlayList?.isSuccess, resFeaturedPlayList?.isSuccess]);

  const handleWishList = (e, fld, vd) => {
    e.preventDefault();
    console.log("eeeeeeee", fld, vd);
    console.log("type", fld.type);
    // Check the type of fld and execute the corresponding logic
    if (fld.type === "PPL") {
      if (
        wishListArr?.some(
          (el) =>
            el?.object_id === fld?._id &&
            el?.video_id === vd?._id &&
            !el?.wishlist
        )
      ) {
        reqSaveWishlist({ id: vd?._id });
        reqPersonalWishlist({
          id: fld?._id,
          wishlistId: vd?._id,
          wishlistFlag: true,
        });
      } else {
        reqUnSaveWishlist({ id: vd?._id });
        reqPersonalWishlist({
          id: fld?._id,
          wishlistId: vd?._id,
          wishlistFlag: false,
        });
      }
    } else {
      if (
        wishListArr?.some(
          (el) =>
            el?.object_id === fld?._id &&
            el?.video_id === vd?._id &&
            !el?.wishlist
        )
      ) {
        reqSaveWishlist({ id: vd?._id });
        reqFeaturedWishlist({
          id: fld?._id,
          wishlistId: vd?._id,
          wishlistFlag: true,
        });
      } else {
        reqUnSaveWishlist({ id: vd?._id });
        reqFeaturedWishlist({
          id: fld?._id,
          wishlistId: vd?._id,
          wishlistFlag: false,
        });
      }
    }

    if (
      wishListArr?.some(
        (el) => el?.object_id === fld?._id && el?.video_id === vd?._id
      )
    ) {
      const wishListFilter = wishListArr?.map((el) =>
        el?.object_id === fld?._id && el?.video_id === vd?._id
          ? { ...el, wishlist: el?.wishlist ? false : true }
          : { ...el }
      );
      setWishListArr(wishListFilter);
    }
  };

  const [savedVideos, setSavedVideos] = useState([]);
  const [refreshSavedVideos, setRefreshSavedVideos] = useState(null);
  useEffect(() => {
    if (userData.token) {
      videoApi.getAllSavedVideoIds(userData.user?.userId).then((res) => {
        setSavedVideos(res.data.savedVideos);
      });
    }
  }, [userData, refreshSavedVideos]);
  const handleSaveUnsave = async (id, video) => {
    //console.log(id);
    const isLiked =
      video.videoDetails?.savedvideo ||
      video.savedvideo ||
      (savedVideos?.length > 0 &&
        savedVideos?.includes(video?.videoDetails?._id || video?._id)) ||
      savedVideos?.includes(video?._id);

    if (isLiked) {
      try {
        const response = await api.unsaveWishlist(id, userData.token);
        if (response.status === 200) {
          toast.success("Unsaved", {
            position: "top-center",
            duration: 3000,
          });
          setRefreshSavedVideos(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      try {
        const response = await api.saveWishlist(id, userData.token);
        if (response.status === 200) {
          toast.success("Saved", {
            position: "top-center",
            duration: 3000,
          });
          setRefreshSavedVideos(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };
  const fld = (APL && Array.isArray(APL.combinedVideos) && index >= 0 && index < APL.combinedVideos.length)
  ? APL.combinedVideos[index]
  : (Array.isArray(FPL) && index >= 0 && index < FPL.length)
    ? FPL[index]
    : {
        type: "",
        _id: "",
        name: "",
        videos: [],
      };


  const [shuffledData, setShuffledData] = useState([]);
  const handleShuffleNew = (e, array) => {
    e.preventDefault();
    const shuffledArray = [...array]; // Create a new array instance
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    setShuffledData(shuffledArray);
  };

  // console.log("suffledata is", shuffledData);

  useEffect(() => {
    setShuffledData([]);
  }, [index]);

  const handleClose = () => {
    setModalShow(false);
    // reqPersonalPlayList({ userId: userInfo?.userId });
    reqPersonalPlayList({ userId: userInfo?.userId });
  };

  const onConfirmAction = () => {
    if (updatePlayList) {
      reqPersonalDelete({
        createdAt: updatePlayList?.createdAt,
        description: updatePlayList?.description,
        name: updatePlayList?.name,
        videos: updatePlayList?.videos,
        id: updatePlayList?._id,
        userId: userInfo?.userId,
      });
      setModal(false);
    }
  };

  const onCancelAction = () => {
    setUpdatePlayList(null);
    setModal(false);
  };

  const onConfirmActionPlaylist = () => {
    if (playListId) {
      reqDelete(playListId);
    }
    window.location.hash = "0";
    setPlaylistmodal(false);
  };

  const onCancelActionPlaylist = () => {
    setPlayListId(null);
    setPlaylistmodal(false);
  };

  const handleDeleteVideo = (e, fld, vd) => {
    const res = fld?.videos?.filter((el) => el?._id !== vd?._id);
    const payload = {
      ...fld,
      videos: res || [],
    };
    setUpdatePlayList(payload);
    setModal(true);
  };

  useEffect(() => {
    console.log(fld);
  }, [shuffledData, fld]);

  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  useEffect(() => {
    const getSubscriptionStatus = async () => {
      const subStatus = await checkApiStatus()
      setSubscriptionStatus(subStatus)
    }
    getSubscriptionStatus()
  }, [])

  return (
    <>
      <div className="row m-0 update c-mobile_honm-serch">
        <MobileViewSearch />
      </div>

      {/* /////////////////////////// */}
      <div>
        <div className="tab-content">
          <div id="menu2" className="tab-pane fade active show">
            {/* All Playlist Block */}
            <AllPlayList
              data={data}
              currentIndex={index}
              setCurrentIndex={setIndex}
              page="Playlists"
              nshowModal={nshowModal}
              setnModalShow={setnModalShow}
            />
            {/* All Playlist Bloock */}
            <PlaylistEditModal
              showModal={showModal}
              setModalShow={setModalShow}
              handleClose={handleClose}
              editPlaylistID={editPlaylistID}
              setnModalShow={setnModalShow}
              nshowModal={nshowModal}
            />

            <div
              className="c-video c-video_feature-box update updated__play__card__list"
              style={{ backgroundColor: "var(--dark)" }}
            >
              {fld ? (
                <div className="mt-2 position-relative ">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
                      <p className="playlist_name col mb-0">{fld?.name}</p>
                      <div className="c-featured_box-left-b4 m-0 d-inline-flex items-center-center">
                        <Link
                          to=""
                          onClick={(e) => handleShuffleNew(e, fld?.videos)}
                          className="d-inline-flex outline__primary__btn shuffle"
                        >
                          <span>
                            <i className="fa-solid fa-shuffle"></i>
                          </span>
                          <span className="d-none d-lg-inline-block m-0">
                            Shuffle
                          </span>
                        </Link>
                        {fld?.type === "PPL" && (
                          <Dropdown>
                            <Dropdown.Toggle
                              className="text-white p-1 drop__btn"
                              id="dropdown-basic"
                            >
                              <i class="fa-solid fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="drop__menu">
                              <button
                                className="btn w-100 border-0"
                                onClick={(e) => onEdit(e, fld)}
                              >
                                Edit
                              </button>
                              <button
                                className="btn w-100 border-0"
                                onClick={(e) => onDelete(e, fld)}
                              >
                                Delete
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Video Cards */}
                  <div className="row g-sm-2 g-1">
                    {(shuffledData.length > 0
                      ? shuffledData
                      : fld.videos &&
                        Array.isArray(fld.videos) &&
                        fld.videos.length > 0
                      ? fld.videos
                      : []
                    ).map((video, vinx) => (
                      <div key={vinx} className="col-6 col-sm-6 col-md-3">
                        <VideoCard
                          video={video}
                          handleWishList={handleWishList}
                          wishListArr={wishListArr}
                          savedVideos={savedVideos}
                          handleSaveUnsave={handleSaveUnsave}
                          fld={fld}
                          handleDeleteVideo={handleDeleteVideo}
                          featuredPlayListView={
                            fld?.type === "FPL" ? true : false
                          }
                          subscriptionStatus={subscriptionStatus}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                "No Playlist"
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        modal={modal}
        onConfirmAction={onConfirmAction}
        onCancelAction={onCancelAction}
      />
      <DeleteModalPlayList
        modal={playlistmodal}
        onConfirmAction={onConfirmActionPlaylist}
        onCancelAction={onCancelActionPlaylist}
      />
    </>
  );
}

export default AllPlaylistsPage;
