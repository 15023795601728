function getTimeElapsedWithFormat(date) {
    const now = new Date(); // Get the current date and time
    const dateObj = new Date(date);
  
    // Calculate the difference in milliseconds
    let elapsedTime = now - dateObj;
  
    // Calculate years
    const years = Math.floor(elapsedTime / (365 * 24 * 60 * 60 * 1000));
    elapsedTime -= years * (365 * 24 * 60 * 60 * 1000);
  
    // Calculate months
    const months = Math.floor(elapsedTime / (30 * 24 * 60 * 60 * 1000));
    elapsedTime -= months * (30 * 24 * 60 * 60 * 1000);
  
    // Calculate days
    const days = Math.floor(elapsedTime / (24 * 60 * 60 * 1000));
    elapsedTime -= days * (24 * 60 * 60 * 1000);
  
    // Calculate hours
    const hours = Math.floor(elapsedTime / (60 * 60 * 1000));
    elapsedTime -= hours * (60 * 60 * 1000);
  
    // Calculate minutes
    const minutes = Math.floor(elapsedTime / (60 * 1000));
    elapsedTime -= minutes * (60 * 1000);

     // Calculate the number of milliseconds in a week
    const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;

    // Calculate the number of weeks
    const weeks = Math.floor(elapsedTime / millisecondsInWeek);

    if(years > 0){
        return `${years} years ago`
    } else if(months > 0){
        return `${months} months ago`
    } else if(days > 0){
        return `${days} days ago`
    } else if(weeks > 0){
        return `${weeks} weeks ago`
    } else if(hours > 0 && minutes > 0){
        return `${hours}hr ${minutes}mins ago`
    } else if(minutes > 0){
        return `${minutes}mins ago`
    } else{
        return 'just now'
    }
  
    // return {
    //   years,
    //   months,
    //   week,
    //   days,
    //   hours,
    //   minutes,
    //   seconds
    // };
  }

  export default getTimeElapsedWithFormat;
  
//   // Example usage:
//   const startDate = '2022-01-01T00:00:00Z'; // Replace this with your start date string
//   const timeElapsed = getTimeElapsedWithFormat(startDate);
  
//   console.log("Years:", timeElapsed.years);
//   console.log("Months:", timeElapsed.months);
//   console.log("Days:", timeElapsed.days);
//   console.log("Hours:", timeElapsed.hours);
//   console.log("Minutes:", timeElapsed.minutes);
//   console.log("Seconds:", timeElapsed.seconds);
  