import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  forwardRef,
} from "react";
import axios from "axios";
import { VideoContext } from "../Video_player/VideoContext";
import NewComment from "./NewComment";
import DisplayComment from "./DisplayComment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import Slide from "@mui/material/Slide";
import "./PlayerPage.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CommentsContext = createContext();

const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#171718",
      "& .MuiDialogTitle-root": {
        fontFamily: "Arial",
        fontSize: "16px",
        color: "white",
      },
    },
  },

  button: {
    border: "none",
    outline: "none",
    borderRadius: "10px",
    color: "white",
    width: "max-content",
    padding: "6px 10px",
    fontWeight: 700,
    fontSize: "11px",
    opacity: "0.75",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
    fontFamily: "Arial",

    "&:hover": {
      opacity: "1",
    },
  },

  no: {
    backgroundColor: "#097dac !important",
  },

  yes: {
    backgroundColor: "#ac5d75 !important",
  },
};

function Comments({ ...props }) {
  const { selectedVideo } = useContext(VideoContext);
  const location = useLocation();
  const [totalComments, setTotalComments] = useState(0);
  const [cursorStamp, setCursorStamp] = useState(undefined);
  const [comments, setComments] = useState([]);
  const [replying, setReplying] = useState("");
  const [editing, setEditing] = useState("");

  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);
  const [moreIsLoading, setMoreIsLoading] = useState(false);

  const userData = useAtomValue(userInfoAtom);

  const fetchComments = useCallback(() => {
    if (selectedVideo._id) {
      const target = new URLSearchParams(location.search).get("target");

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/comments?videoID=${selectedVideo._id}${cursorStamp ? `&cursor=${cursorStamp}` : ""
          }${target ? `&target=${target}` : ""}`, {
            headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData.token}`,
        },
          }
        )
        .then(({ data }) => {
          console.log(data)
          const seenMap = new Set(comments.map((c) => c._id));
          const updatedComments = [...comments];

          data.comments?.map((comment) => {
            if (!seenMap.has(comment._id)) {
              seenMap.add(comment._id);
              updatedComments.push(comment);
            }
          });
          setComments(updatedComments);

          if (data.total) {
            setTotalComments(data.total);
          }

          if (data.comments && data.comments.length > 0) {
            setCursorStamp(data.comments[data.comments.length - 1].createdAt);
          }
        });
    }
  }, [selectedVideo, cursorStamp, comments]);

  useEffect(() => {
    fetchComments();
  }, [selectedVideo]);

  return (
    <div className="c-video_comment" {...props}>
      <div className="c-video_commet_we">
        <div className="c-video_comment-h4">
          <h4>Community Chat</h4>
          <div className={"c-video_comment-count"}>{totalComments}</div>
        </div>

        {comments.length === 0 && (
          <div className="c-video_comment-chat">
            <p className="c-community-chat">
              No comments have been made yet. Be the first to comment!
            </p>
          </div>
        )}

        <CommentsContext.Provider
          value={{
            replying,
            setReplying,
            comments,
            setComments,
            editing,
            setEditing,
            deleteIsOpen,
            setDeleteIsOpen,
            deleteComment,
            setDeleteComment,
            setTotalComments,
          }}
        >
          <>
            <NewComment videoId={selectedVideo._id} />

            <div className={"c-video_display-comments"}>
              {comments.map((comment) => (
                <DisplayComment key={comment._id} comment={comment} />
              ))}
            </div>
          </>
        </CommentsContext.Provider>

        {comments.reduce((acc, comment) => {
          return acc + comment.replies.length + 1;
        }, 0) < totalComments && (
            <>
              <div className={"c-video_comment-load-more-divider"} />

              <div
                className="c-video_comment-restor"
                onClick={() => {
                  setMoreIsLoading(true);
                  fetchComments();
                }}
              >
                <h4>
                  <span>
                    <i className="fas fa-sync"></i>
                  </span>
                  <span className={"c-video_comment-load-more"}>
                    LOAD MORE COMMENTS
                  </span>
                </h4>
              </div>
            </>
          )}
      </div>

      <Dialog
        open={deleteIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setDeleteComment(false);
          setDeleteIsOpen(false);
        }}
        sx={[styles.dialog]}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Are you sure you want to delete this comment?"}
        </DialogTitle>
        <DialogActions>
          <button
            className={"c-video_comments-button-wrapper"}
            style={{
              width: "max-content",
              height: "max-content",
            }}
            onClick={() => {
              setDeleteComment(false);
              setDeleteIsOpen(false);
            }}
          >
            <div className={"c-video_comments-button-wrapper-inner"}>NO</div>
          </button>
          <button
            className={"c-video_comments-button-wrapper"}
            style={{
              width: "max-content",
              height: "max-content",
            }}
            onClick={() => {
              setDeleteComment(true);
              setDeleteIsOpen(false);
            }}
          >
            <div className={"c-video_comments-button-wrapper-inner"}>YES</div>
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Comments;
