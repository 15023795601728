import React, { useContext, useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import ChargbeeButton from "./PlanPage/ChargbeeButton";

function PlanPage() {
  const [responsedata, setResponsedata] = useState("");
  const userData = useAtomValue(userInfoAtom);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    // Fetch the list of countries from the backend when the component mounts
    axios.get(`${process.env.REACT_APP_API_URL}/get/itemprice/list`).then((response) => {
      setResponsedata(response.data);
      console.log(response.data);
    });
  }, []);

  return (
    <div>
      {/* Mobile  */}

      <div className="row m-0 c-mobile_honm-serch">
        <div className="c-header_search">
          <form action="">
            <input type="text" id="search" placeholder="Search" />
            <button id="search-button" aria-label="search button">
              <span className="c_header-mac">
                <i class="fa-solid fa-microphone"></i>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M17.8516 17.5L14.2266 13.875M16.1849 9.16667C16.1849 12.8486 13.2001 15.8333 9.51823 15.8333C5.83633 15.8333 2.85156 12.8486 2.85156 9.16667C2.85156 5.48477 5.83633 2.5 9.51823 2.5C13.2001 2.5 16.1849 5.48477 16.1849 9.16667Z"
                  stroke="#71717A"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
      {/* /////////////////// */}
      <div className="container-c-section">
        {userData.token ? (
          <div className="c-join-section">
            {userData.token
              ? `Welcome, ${userData.user.firstname} ${userData.user.lastname}.`
              : ""}
          </div>
        ) : (
          <div className="c-join-section">
            Join The Community and Access Exclusive Content
          </div>
        )}
      </div>
      <div class="c-tab_cata-text c-plane_page-m">
        <h4>Pick your plan</h4>
      </div>
      {/* ///////////////////// */}
      <div className="c-plan_section">
        <div className="c-video_textcol">
          <h3 className="c-plane-mobile_h3">Pick your Plan</h3>
          <h4 className="c-plane-mobile-h4">Pick your Plan</h4>
        </div>
        <div className="c-plan_list">
          <div className="c-plan_new-one">
            <div>
              <h3 className="c-plan-new-offer">Save 37%</h3>
              <div className="c-plan_pric-box">
                <h4>ANNUAL PLAN</h4>
                <h2>
                  $12.49<span>/month</span>
                </h2>
                <h5>$149.99 Billed Annually</h5>
                <p>Access our full video library</p>
                <ChargbeeButton planType={'yearly'} isStudio={false}/>
              </div>
            </div>
          </div>
          <div className="c-plan_new-two">
            <div>
              <div>
                <h4>Monthly PLAN</h4>
                <h2>
                  $19.99/<span>month</span>
                </h2>
                <p>Access our full video library</p>
                <ChargbeeButton planType="monthly" isStudio={false} />
              </div>
            </div>
          </div>
        </div>
{/*
<div className="studio-tagline">Looking for a studio account <span><a href="/StudioSignup">Click Here</a></span></div>
*/}
      </div>
    </div>
  );
}

export default PlanPage;
