import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom';

function ReadMoreLess({text,detailsPage}) {
  const [showMore,setShowMore] = useState(false)

  const handleShowMore = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  return (
    <>
    {detailsPage ? 
      <p>{text && text?.length > 350 && !showMore  ? `${text?.substring(0, 350)}...` : text || '-'}</p>
    :
    <p>{text && text?.length > 150 && !showMore  ? `${text?.substring(0, 150)}...` : text || '-'}</p>
    }
    {text?.length > 150 && showMore && 
        <Link to="" className='more_less' onClick={(e) => handleShowMore(e)}>
            Read less
        </Link>
    }
    {text?.length > 150 && !showMore && 
        <Link to="" className='more_less' onClick={(e) => handleShowMore(e)}>
            Read more
        </Link>
    }
    </>
  )
}

export default ReadMoreLess
