import { useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";

function LazyImage({ src, alt, width, height, style, ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const placeholderRef = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current?.complete) {
      setIsLoading(false);
    }
  }, [imgRef]);

  return (
    <div style={{ width, height, position: "relative" }}>
      {isLoading && (
        <div
          ref={placeholderRef}
          className={"c-lazy-image"}
          style={{
            width,
            height,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backdropFilter: "drop-shadow(0px 2px 14px rgba(0,0,0,0.75))",
            zIndex: 2,
          }}
        >
          <Spinner style={{ position: "relative", zIndex: 1 }} />
        </div>
      )}
      <img
        ref={imgRef}
        src={src}
        style={{
          visiblity: isLoading ? "hidden" : "visible",
          ...style,
          filter: isLoading ? "blur(5px)" : "none",
        }}
        alt={alt}
        onLoad={() => {
          setIsLoading(false);
        }}
        decoding="async"
        {...props}
      />
    </div>
  );
}

export default LazyImage;
