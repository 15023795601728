import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  useFeaturedPlayListByIdQuery,
  useGetVideosListQuery,
  useSubmitFeaturedPlayListMutation,
} from "../../../service";
import { useForm, Controller } from "react-hook-form";
import { getVideoDropDownList } from "../../../redux/videoSlice";
import Select from "react-select";
import toast from "react-hot-toast";

function AdminFeaturedListForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [reqFeatured, resFeatured] = useSubmitFeaturedPlayListMutation();
  const authToken = useSelector((state) => state.authState.userToken);
  const videoDropdownList = useSelector(
    (state) => state.videoState.videoDropdownList
  );

  console.log("authToken", authToken);
  const [videoFiles, setVideoFiles] = useState([]);
  console.log("videoFiles", videoFiles);
  const resVideo = useGetVideosListQuery();
  const resGetById = useFeaturedPlayListByIdQuery(location?.state?.playId, {
    skip: !location?.state?.playId,
  });
  console.log("resVideo", resVideo, videoDropdownList);
  console.log("resGetById", resGetById);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();

  useEffect(() => {
    if (resGetById?.isSuccess && resGetById?.data?.existingVideos) {
      reset({
        createdAt: resGetById?.data?.existingVideos?.createdAt,
        description: resGetById?.data?.existingVideos?.description,
        name: resGetById?.data?.existingVideos?.name,
        videos: resGetById?.data?.existingVideos?.videos,
        id: resGetById?.data?.existingVideos?._id,
      });
    }
  }, [resGetById?.isSuccess]);

  useEffect(() => {
    if (resVideo?.isSuccess && resVideo?.data) {
      const res1 = resVideo?.data?.map((el) => ({
        ...el,
        label: el?.title,
        value: el?.title,
      }));
      console.log("res1", res1);
      dispatch(getVideoDropDownList(res1));
    }
  }, [resVideo]);

  const onNext = (state) => {
    console.log("state", state);
    reqFeatured({
      ...state,
      videos: state?.videos?.map((el) => ({ ...el, wishlist: false })),
    });
  };

  useEffect(() => {
    if (resFeatured?.isSuccess) {
      toast.success("Featured playlist save successfully", {
        position: "top-center",
        duration: 3000,
      });
      setTimeout(() => {
        navigate("/AdminDashboard/featured-playlist");
      }, 1000);
    }
    if (resFeatured?.isError) {
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [resFeatured]);
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">PlayList Form</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(onNext)}>
          <Row>
            <div className="col-md-12 form-group mb-2">
              <Controller
                id="name"
                name="name"
                control={control}
                rules={{ required: "Name is required" }}
                render={({ field }) => (
                  <Input
                    placeholder="Enter Name"
                    className="form-control"
                    {...field}
                    type="text"
                  />
                )}
              />
              {errors.name && (
                <FormFeedback>{errors?.name?.message}</FormFeedback>
              )}
            </div>
            <div className="col-md-12 form-group mb-2">
              <Controller
                id="description"
                name="description"
                control={control}
                render={({ field }) => (
                  <textarea
                    placeholder="Enter description"
                    className="form-control"
                    {...field}
                  />
                )}
              />

            </div>
            <div className="col-md-12 form-group mb-2">
              <Controller
                id="videos"
                name="videos"
                control={control}
                rules={{ required: "Video is required" }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti
                    isClearable
                    options={videoDropdownList || []}
                    className="react-select"
                    classNamePrefix="select"
                    placeholder="Select a videos"
                    onChange={onChange}
                    value={value ? value : null}
                  />
                )}
              />
              {errors.videos && (
                <FormFeedback>{errors?.videos?.message}</FormFeedback>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="reset"
                color="secondary"
                className="me-1"
                onClick={() => navigate("/AdminDashboard/featured-playlist")}
                outline
              >
                Back
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </div>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default AdminFeaturedListForm;
