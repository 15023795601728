import React from "react";

export default function LoginForm({
  email,
  password,
  emailError,
  passwordError,
  onChange,
  onSubmit,
  onForgotPassword,
  errorMessage,
  isPasswordVisible,
  togglePasswordVisibility,
  eyeIcon
}) {
  return (
    <>
      <div className="row">
        <div className="col-md-12 form-group">
          <input
            type="email"
            className="form-control"
            id="inputEmail4"
            placeholder="Email"
            value={email}
            name="email"
            onChange={onChange}
          />
          {emailError && <p style={{ color: "red" }}>{emailError}</p>}
        </div>
        <div className="col-md-12 form-group" style={{ position: "relative" }}>
          <input
            type={isPasswordVisible ? "text" : "password"}
            className="form-control"
            id="inputPassword4"
            placeholder="Password"
            value={password}
            name="password"
            onChange={onChange}
          />
           <span
            className="c-password_visibility--icon"
            onClick={togglePasswordVisibility}
          >
            <img
              src={eyeIcon}
            />
          </span>
        </div>
          {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
        <div className="col-md-12 form-group">
          <button type="submit" className="btn btn-primary" onClick={onSubmit}>
            Log In
          </button>
          <p onClick={onForgotPassword} style={{ cursor: "pointer" }}>
            Forgot Password?
          </p>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
      </div>
    </>
  );
}
