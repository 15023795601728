import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoaderComponet from "../LoderComponent";

function ForgotPassword() {
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  axios.defaults.withCredentials = true;
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/forgot-password`, { email })
      .then((res) => {
        console.log(res.data);
        if (res.data.Status === "Success") {
          setResponseMessage(
            "Password Reset Email Sent! Please check your inbox for a link to reset your password. If you can't find the email, check your spam folder or contact our support team for assistance."
          );
          setLoading(false);
        } else {
          navigate("/forgot");
          setLoading(false);
          setResponseMessage(res.data.Status);
        }
      })
      .catch((err) => {
        setLoading(false);
        navigate("/forgot");
        setResponseMessage("Somethings went wrong");
        console.log(err);
      });
  };

  return (
    <>
      {loading ? <LoaderComponet loading /> : " "}
      <section className="c-sing_form c-forget_page">
        <div className="container">
          <div>
            <div class="c-sing_heading c-sing_heading_forget">
              <h2>Forgot Password</h2>
            </div>
            <div className="c-sing_form-box">
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 col-md-12 form-group">
                    <label htmlFor="email">
                      {/* <strong>Email</strong> */}
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      autoComplete="off"
                      name="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <span>{responseMessage}</span>
                  <div className="col-md-12 form-group">
                    <button
                      type="submit"
                      className="btn w-100 col-md-12 form-group button"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
