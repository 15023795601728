import React, { useState, useEffect } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Logo from "../../Asset/images/logo-blaxk.webp";
import { useNavigate } from "react-router-dom";
import { List, MenuItem } from "@mui/material";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuthService } from "../../hook/useAuthService";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";

function Header({ HideSideBar }) {
  const [searchKey, setSearchKey] = useState([]);
  const userData = useAtomValue(userInfoAtom);

  const navigate = useNavigate();
  const p = () => {
    navigate("/SignUp");
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleUsericon = () => {
    navigate("/login"); // Toggle the menu open/close
  };

  const handleUserClick = () => {
    setIsOpen(!isOpen); // Toggle the menu open/close
  };

  const handleMenuItemAccount = (menuItem) => {
    navigate("/account");
    console.log(`Clicked on ${menuItem}`);
  };

  const {loggedOutUser} = useAuthService();


  const handleLogout = () => {
    loggedOutUser()
  };
  const handleSearchVideo = async (e) => {
    if (searchKey.length >= 3) {
      navigate(`/result?search_query=${searchKey}`);
    }
  };

  return (
    <div>
      <header className="c-header">
        <div className="row c-header_row">
          {/* Moblie Responsive class */}
          <div className="col-md-4 c-mobile_hed-one">
            <div className="c-header_toggbox">
              <div
                className="c-header_toggle"
                onClick={(event) => HideSideBar(event)}
              >
                <i className="fa-solid fa-bars"></i>
              </div>
              <div className="c-header_tog-logo">
                <a href="/" aria-label="home">
                  <img src={Logo} alt="Logo image" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-5 c-mobile_hed-two">
            <div>
              <div className="c-header_search">
                <input
                  onChange={(e) => {
                    // Handle onChange logic here if needed
                    setSearchKey(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === "Tab") {
                      handleSearchVideo(e.target.value);
                    }
                  }}
                  type="text"
                  id="search"
                  placeholder="Search"
                />
                <button
                  onClick={handleSearchVideo}
                  id="search-button"
                  aria-label="search button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M17.8516 17.5L14.2266 13.875M16.1849 9.16667C16.1849 12.8486 13.2001 15.8333 9.51823 15.8333C5.83633 15.8333 2.85156 12.8486 2.85156 9.16667C2.85156 5.48477 5.83633 2.5 9.51823 2.5C13.2001 2.5 16.1849 5.48477 16.1849 9.16667Z"
                      stroke="#71717A"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3 c-mobile_hed-three">
            <div className="c-header_user">
              <div>
                {!userData?.user?.token ? (
                  // User is logged in, hide the user icon
                  <i className="fa-solid fa-user" onClick={handleUsericon}></i>
                ) : // User is not logged in, show the user icon
                null}
              </div>
              <div>
                {userData?.user?.token ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <button className="btn profile__btn rounded-circle p-0 d-flex justify-content-center align-items-center "> 
                      <FaUser
                        onClick={handleUserClick}
                        style={{
                          fill: "#ffff",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        fontSize="large"
                      />
                    </button>

                    {/* <p className="text-white">User</p> */}

                    {isOpen && (
                      <List
                        className="d-flex flex-column c-header-profile"
                        style={{
                          fill: "#ffff",
                          cursor: "pointer",
                          background: "#fff",
                          position: "absolute",
                          top: "70px",
                        }}
                      >
                        <button 
                          className="c-menu_item--button"
                          onClick={() => handleMenuItemAccount("My account")}
                        >
                          My account
                        </button>
                        <button 
                          className="c-menu_item--button"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </List>
                    )}
                  </div>
                ) : (
                  <Link to="/SignUp" onClick={p}>
                    SIGN UP
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
