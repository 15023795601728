import React from "react";
import "../App.css";
import Divesf from "../Asset/images/new-footer.png";
import Fimage from "../Asset/images/footer-logo.png";
import PlayStoreButton from '../Asset/images/App-Storeapple.png'
import { SocialMediaLinks } from "../constants/SocialMediaLinks";

function Futter() {
  return (
    <div className="c-footer-wrapper">
      {/* min-height to avoid CLS ^ */}
      <div className="c-device">
        <div className="row m-0">
          <div className="col-md-6 c-device_loptop">
            <div className="c-mobile_text">
              <h3>On Demand Access On Any Device</h3>
              <div className='c-apple_button--container'>
                <a href='https://play.google.com/store/apps/details?id=com.thegodfreymethod.ondemands&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img alt='Get it on Google Play' src={PlayStoreButton}/>
                </a>
                <a href="https://apps.apple.com/us/app/the-godfrey-method-on-demand/id6479438248">
                  <img
                    src="//thegodfreymethod.com/cdn/shop/files/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg?v=1715790375"
                    // src={AppStoreButton}
                  />
                </a>
              </div>
            </div>
            <img src={Divesf} alt="" />
          </div>
          <div className="col-md-6 c-device_apbox">
            <div>
              <div className="c-device_h3">
                <h3>On Demand Access On Any Device</h3>
                <div className='c-apple_button--container'>
                  <a href="https://apps.apple.com/us/app/the-godfrey-method-on-demand/id6479438248">
                    <img
                      src="//thegodfreymethod.com/cdn/shop/files/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg?v=1715790375"
                      // src={AppStoreButton}
                    />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.thegodfreymethod.ondemands&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='Get it on Google Play' src={PlayStoreButton}/>
                  </a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* !!!!!!! Footer !!!!!!!!   */}
      <footer className="c-footer">
        <div className="c-footer_section-item">
          <div className="row m-0">
            <div className="col-md-3 c-footer_section-one">
              <div>
                <a href="/" aria-label="home">
                  <div className="c-footer_section-1logo">
                    <img src={Fimage} alt="" />
                  </div>
                </a>
                <div className="c-footer_section-1social">
                  <ul>
                    <li>
                      <a
                        href={SocialMediaLinks.tiktok}
                        target="_blank"
                        aria-label="tiktok"
                      >
                        <i className="fa-brands fa-tiktok"></i>
                      </a>
                    </li>
                    <li>
                      <a href={SocialMediaLinks.facebook} target="_blank" aria-label="facebook">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={SocialMediaLinks.instagram}
                        target="_blank"
                        aria-label="instagram"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={SocialMediaLinks.youtube}
                        target="_blank"
                        aria-label="youtube"
                      >
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /////////////////////////////////////Mobile class///////////////// */}
            <div className="col-md-3 c-mobile_footer-two">
              <div className="c-footer_manulist">
                <h4>Company</h4>
                <ul>
                  <li>
                    <a
                      href="https://thegodfreymethod.myshopify.com/pages/about-us"
                      target="_blank"
                    >
                      About
                    </a>
                  </li>
                  {/* <li>
                    <a href="">Features</a>
                  </li> */}
                  <li>
                    <a
                      href="https://thegodfreymethod.myshopify.com/collections/frontpage"
                      target="_blank"
                    >
                      Shop
                    </a>
                  </li>
                  <li>
                    <a href="">On Demand</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 c-mobile_footer-three">
              <div className="c-footer_manulist">
                <h4>Help</h4>
                <ul>
                  <li>
                    <a
                      href="https://thegodfreymethod.myshopify.com/pages/customer-support"
                      target="_blank"
                    >
                      Customer Support
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://thegodfreymethod.myshopify.com/pages/delivery-details"
                      target="_blank"
                    >
                      Shipping Policies
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://thegodfreymethod.myshopify.com/pages/terms-conditions"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://thegodfreymethod.myshopify.com/pages/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="c-footer_copy-r">
          <p>© Copyright 2023, All Rights Reserved. Made by Toru Digital</p>
        </div>
      </footer>
    </div>
  );
}

export default Futter;
