import React, { createContext } from "react";
import AdminSideBar from "./components/SideBar";
import AdminHeader from "./components/Header";
import AdminFooter from "./components/Footer";
import PageContent from "./components/PageContent";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../@core/assets/fonts/feather/iconfont.css";
import "../../@core/scss/core.scss";
import "../../Adminassets/white.css";
import { Outlet } from "react-router-dom";
import useAuthentication from "../../hook/useAuthentication";
import { userRoleTypes } from "../../utils/userRoleTypes";

export const ModerationContext = createContext();

function AdminDashboard() {
  useAuthentication(userRoleTypes.ADMIN);

  return (
    <React.Fragment>
      <div className="wrapper white vertical-layout navbar-floating footer-static vertical-menu-modern menu-expanded">
        <AdminHeader />
        <AdminSideBar />
        <PageContent>
          <Outlet />
        </PageContent>
        <AdminFooter />
      </div>
    </React.Fragment>
  );
}

export default AdminDashboard;
