import { configureStore, combineReducers } from "@reduxjs/toolkit";
import featuredPlayListSlice from './featuredPlayListSlice'
import personalPlayListSlice from './personalPlayListSlice'
import { featuredPlaylistApi, personalPlaylistApi, videoListApi, wishlistApi, combinePlaylistApi } from "../service";
import authSlice from "./authSlice";
import videoSlice from "./videoSlice";
import combinePlayListSlice from "./combinePlayListSlice";
import combinePlayListReducer from './combinePlayListSlice';

const appReducer = combineReducers({
    authState: authSlice,
    videoState: videoSlice,
    featuredPlayListState: featuredPlayListSlice,
    personalPlayListState: personalPlayListSlice,
    combinePlayListState: combinePlayListSlice,
    combinePlayList: combinePlayListReducer,
    [videoListApi.reducerPath]: videoListApi.reducer,
    [featuredPlaylistApi.reducerPath]: featuredPlaylistApi.reducer,
    [personalPlaylistApi.reducerPath]: personalPlaylistApi.reducer,
    [combinePlaylistApi.reducerPath]: combinePlaylistApi.reducer,
    [wishlistApi.reducerPath]: wishlistApi.reducer,
    [combinePlaylistApi.reducerPath]: combinePlaylistApi.reducer,
});

export const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaltMiddleware) =>
        getDefaltMiddleware({ serializableCheck: false }).concat([
            videoListApi.middleware,
            featuredPlaylistApi.middleware,
            personalPlaylistApi.middleware,
            wishlistApi.middleware,
            combinePlaylistApi.middleware
        ]),
});