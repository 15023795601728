import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import VideoPlayer from "./VideoPlayer";
import Videodesc from "./Videodesc";
import Comments from "./Comments";
import { useLocation } from "react-router-dom";
import "./PlayerPage.css";
import { VideoContext } from "./VideoContext";
import api from "../../data/api";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";

function PlayerPage() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const { setSelectedVideo, selectedVideo } = useContext(VideoContext);
  const userData = useAtomValue(userInfoAtom);

  useEffect(() => {
    const token = userData.token;
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData.token]);

  useEffect(() => {
    const searchquery = new URLSearchParams(location.search).get("v");
    if (searchquery && userData.token) {
      fetchSearchResults(searchquery, userData.token);
    }
  }, [location.search, userData.token]);

  useEffect(() => {
    // console.log("response data of player page", responseData[0]?._id);
    const _id = responseData[0]?._id;
    const title = responseData[0]?.title;
    const description = responseData[0]?.description;
    const duration = responseData[0]?.duration;
    const thumnailLink = responseData[0]?.thumnailLink;
    const tagsData = responseData[0]?.tagsDetails;
    const productsData = responseData[0]?.productsData;
    const wishlist = responseData[0]?.savedvideo;
    const videolink = responseData[0]?.videolink;
    setSelectedVideo({
      _id,
      title,
      description,
      duration,
      videolink,
      thumnailLink,
      tagsData,
      productsData,
      wishlist,
    });
  }, [responseData]);

  const fetchSearchResults = async (searchquery, token) => {
    try {
      const response = await api.videoPlayerPage(searchquery , token);
      // console.log("singlr player page", response.data);
      if (response.status === 200) {
        setResponseData(response.data);
      }
      // localStorage.setItem('selectedVideo', JSON.stringify(response.data));

      //setSelectedVideo({ url, title, desc, tags, products,_id,savedvideo });
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <>
          <VideoPlayer />
          <Videodesc />
          <Comments key={selectedVideo._id} />
        </>
      ) : (
        <p>Please log in to view the content.</p>
      )}
    </div>
  );
}

export default PlayerPage;
