import { toast } from "react-toastify";

// Function to handle API response and return a boolean indicating whether an error occurred
export const handleErrorResponse = (response) => {
  if (response && response.error) {
    toast.error(response.error);
    return true; // Return true if an error occurred
  }
  return false; // Return false if no error occurred
};
