import api from "../../data/api";
import { ProduceBanners } from "./components/ProduceBanners";

const AddBanners = () => {

  const handleSubmit = async(title, image, releaseDate, isActive, token) => {
    return await api.createBannerApi(title, releaseDate, isActive, image , token)
  }

 return <ProduceBanners onSubmit={handleSubmit}/>
};

export default AddBanners;







