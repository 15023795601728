import { apiWrapper } from "../utils/apiWrapper"
import axiosConfig from "../utils/axiosConfigInstance"

export const loginApi = async(email, password) => {
    return await axiosConfig.post(`${process.env.REACT_APP_API_URL}/login`, { email, password })
}

export const StudioSignupApi = async formData => {
    return await axiosConfig.post(`${process.env.REACT_APP_API_URL}/studio/signup`, formData)   
}

export const deleteUserChangeApi = async(id) => {
    return await axiosConfig.delete(`${process.env.REACT_APP_API_URL}/user/change/delete/${id}`)
}


export const authApis = {
    loginApi
}