import React, { useState, useEffect } from "react";
import axios from "axios";
import { SketchPicker } from "react-color";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Card,
  CardContent,
  TextField,
  IconButton,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import tagApi from '../api/tagApi'

export default function TagManagement() {
  axios.defaults.withCredentials = true;
  const redirect = useNavigate();
  const [tags, setTags] = useState([]);
  const [findtags, setFindags] = useState([]);
  const [currentColor] = useState(" ");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [response, setResponse] = useState("");
  const [addTag, setAddTag] = useState("");
  const [deleteTag, setDeleteTag] = useState(null); // Tag to be deleted
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeComplete = async (color) => {
    const coular = color.hex;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/color/tags`,
        { coular, findtags }
      );
      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
      }
    } catch (error) {
      console.error("Color update failed:", error);
    }
  };

  const showColorOpen = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    tagApi.getAllTags().then((response) => {
      console.log(response.data);
      setTags(response.data);
    })
  }, []);

  const handleAddTag = async (e) => {
    e.preventDefault();
    try {
      const response = tagApi.addTag(addTag)

      if (response.status === 200) {
        setTags((prevTags) => [...prevTags, response.data.data]);
        setAddTag("");
        setResponse("Tag added successfully");
        //console.log("Tag added successfully:", response.data.data);
      } else if (response.status === 201) {
        toast.error('Please Login Again', {
          position: "top-center",
          duration: 3000,
        });
        redirect('/login');
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.error('Tag already exists', {
          position: 'top-center',
          duration: 3000,
        })
      }
      else {
        toast.error('Somethng went wrong', {
          position: "top-center",
          duration: 3000,
        });
      }
      //console.error("Error adding tag:", error);
    }
  };

  const handleDeleteTag = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/delete/tags`,
        {
          deleteTag,
        }
      );

      if (response.status === 200) {
        setTags(tags.filter((tag) => tag.name !== deleteTag));
        setDeleteTag(null);
        setOpenDialog(false);
        //console.log("Tag deleted successfully:", response.data);
      } else if (response.status === 201) {
        toast.error('Please Login Again', {
          position: "top-center",
          duration: 3000,
        });
        redirect('/login');
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.error('Tag not found', {
          position: 'top-center',
          duration: 3000,
        })
      }
      else {
        toast.error('Somethng went wrong', {
          position: "top-center",
          duration: 3000,
        });
      }
      //console.error("Error deleting tag:", error);
    }
  };

  const handlePriorityChange = async (tag, newPriority) => {
    if (tag.priority !== newPriority) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/update/tag/priority`,
          {
            tagName: tag.name,
            newPriority,
          }
        );

        if (response.status === 200) {
          setTags((prevTags) =>
            prevTags.map((t) =>
              t.name === tag.name ? { ...t, priority: newPriority } : t
            )
          );
          console.log(`Tag ${tag.name} priority changed to ${newPriority}`);
        } else if (response.status === 201) {
          toast.error('Please Login Again', {
            position: "top-center",
            duration: 3000,
          });
          redirect('/login');
        }
      } catch (error) {
        if (error.response.status === 404) {
          toast.error('Tag not found', {
            position: 'top-center',
            duration: 3000,
          })
          // console.error("Error updating tag priority:", error);
        }
        else {
          toast.error('Somethng went wrong', {
            position: "top-center",
            duration: 3000,
          });
        }
      }
    }
  };

  const handleOutsideClick = (e) => {
    const gridItem = document.querySelector(".grid-item");
    const tagsList = document.querySelector(".tags-list");

    if (
      gridItem &&
      !gridItem.contains(e.target) &&
      tagsList &&
      !tagsList.contains(e.target)
    ) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const priorityList = Array.from(
    { length: tags?.length },
    (_, index) => index + 1
  );

  return (
    <>
      <h2>Tag Management</h2>

      <form onSubmit={handleAddTag}>
        <div>
          <label>Enter Tags:</label>
          <TextField
            className="form-control"
            type="text"
            name="tag"
            value={addTag}
            onChange={(e) => setAddTag(e.target.value)}
          />
        </div>
        <Button className="mt-2" variant="primary" type="submit">
          ADD
        </Button>
      </form>
      {response && <div className="alert-success">{response}</div>}

      <Card className="mt-3">
        <CardContent>
          <Grid
            className="grid-item"
            style={{ display: "flex" }}
            container
            spacing={2}
          >
            {tags
              ?.slice()
              .sort((a, b) => a.priority - b.priority)
              .map((tag) => (
                <Grid
                  className="tags-list"
                  key={tag.name}
                  style={{ display: "flex" }}
                  item
                  xs={3}
                >
                  <div className="tag-container">
                    <div
                      className="tag"
                      style={{
                        backgroundColor: tag.color,
                        cursor: "pointer",
                        border: "none",
                      }}
                      onClick={() => {
                        showColorOpen();
                        setFindags(tag.name);
                      }}
                    >
                      {tag.name}
                    </div>
                    <IconButton
                      onClick={() => {
                        setDeleteTag(tag.name);
                        setOpenDialog(true);
                      }}
                      className="delete-icon"
                      size="small"
                      style={{ marginLeft: "auto" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>

                  {/* Priority Selector Dropdown */}
                  <Select
                    value={tag.priority || ""} // Make sure to use an empty string as the default value
                    onChange={(e) => {
                      handlePriorityChange(tag, e.target.value);
                      // Set the selected priority in the state
                      setTags((prevTags) =>
                        prevTags.map((t) =>
                          t.name === tag.name
                            ? { ...t, priority: e.target.value }
                            : t
                        )
                      );
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="" disabled>
                      Priority
                    </MenuItem>
                    {priorityList.map((priority) => (
                      <MenuItem key={priority} value={priority}>
                        {priority}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>

      <Grid className="grid-item" item xs={9}>
        {showColorPicker && (
          <SketchPicker
            color={currentColor}
            onChangeComplete={handleChangeComplete}
          />
        )}
      </Grid>

      <Dialog open={openDialog}>
        <DialogContent>
          <p>Are you sure you want to delete the tag?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>No</Button>
          <Button onClick={handleDeleteTag}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
