import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./Asset/Font-family/GoodTimesRg-Regular.ttf";
import "./Asset/Font-family/GoodTimesRg-Regular.woff";
import "./Asset/Font-family/GoodTimesRg-Regular.eot";
import RootApp from "./RootApp";
import { VideoProvider } from "./component/Video_player/VideoContext";
import { UserProvider } from "./component/LoginContext/UserContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <UserProvider>
      <VideoProvider>
        <ToastContainer/>
        <RootApp />
      </VideoProvider>
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();
