import { useSelector } from "react-redux";
import { useCallback, useEffect, useState, useRef, forwardRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/authSlice";
import Cookies from "universal-cookie";
import Switch from "@mui/material/Switch";
import ModerationView from "../ModerationView";
import { ModerationContext } from "../../Layouts/AdminLayout/AdminDashboard";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Slide from "@mui/material/Slide";
import axiosConfig from "../../data/utils/axiosConfigInstance";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#171718",
      "& .MuiDialogTitle-root": {
        fontFamily: "Arial",
        fontSize: "16px",
        color: "white",
      },
    },
  },

  button: {
    border: "none",
    outline: "none",
    borderRadius: "10px",
    color: "white",
    width: "max-content",
    padding: "6px 10px",
    fontWeight: 700,
    fontSize: "11px",
    opacity: "0.75",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
    fontFamily: "Arial",

    "&:hover": {
      opacity: "1",
    },
  },

  no: {
    backgroundColor: "#097dac !important",
  },

  yes: {
    backgroundColor: "#ac5d75 !important",
  },
};

function AdminModeration() {
  const itemsPerPage = 10;
  const [dialogMode, setDialogMode] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.authState.userInfo);

  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [commentValidation, setCommentValidation] = useState(
    userInfo.app.commentValidation
  );
  const [imageValidation, setImageValidation] = useState(
    userInfo.app.imageValidation
  );

  const [commentsCurrentPage, setCommentsCurrentPage] = useState(1);
  const [comments, setComments] = useState([]);
  const [totalComments, setTotalComments] = useState([]);

  const [imagesCurrentPage, setImagesCurrentPage] = useState(1);
  const [images, setImages] = useState([]);
  const [totalImages, setTotalImages] = useState([]);

  const [commentFilterType, setCommentFilterType] = useState("all");
  const [imageFilterType, setImageFilterType] = useState("all");

  const switchValidation = useCallback(
    async (validationType) => {
      console.log("switching");
      let value = false;
      if (validationType === "comment") {
        value = !commentValidation;
      } else {
        value = !imageValidation;
      }

      axios
        .put(`${process.env.REACT_APP_API_URL}/admin/content-validation`, {
          [validationType]: value,
        })
        .then(({ data }) => {
          if (data.success) {
            const updatedUser = {
              ...userInfo,
              app: { ...userInfo.app },
            };

            if (validationType === "comment") {
              updatedUser.app.commentValidation = value;
              setCommentValidation((prev) => !prev);
            } else {
              updatedUser.app.imageValidation = value;
              setImageValidation((prev) => !prev);
            }

            cookies.set("user", updatedUser);
            localStorage.setItem("userData", JSON.stringify(updatedUser));
            dispatch(setUserInfo(updatedUser));

            toast.success("Successfully updated validation settings!", {
              position: "top-center",
            });
          }
        });
    },
    [commentValidation, imageValidation, userInfo]
  );

  const fetchLockComments = useRef(false);
  const fetchLockImages = useRef(false);

  const fetchContent = useCallback(
    async (contentType) => {
      if (contentType === "comments") {
        fetchLockComments.current = true;
      } else {
        fetchLockImages.current = true;
      }

      setIsLoading(true);

      let pageNumber;
      if (contentType === "comments") {
        pageNumber = commentsCurrentPage;
      } else {
        pageNumber = imagesCurrentPage;
      }

      const startIndex = (pageNumber - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      axiosConfig
        .get(
          `${process.env.REACT_APP_API_URL}/admin/content-validation?contentType=${contentType}${`&start=${startIndex}&end=${endIndex}`}`
        )
        .then(({ data }) => {
          if (data.images && data.totalImages && data.images.length > 0) {
            setImages(data.images);
            setTotalImages(data.totalImages);
          }

          if (data.comments && data.totalComments && data.comments.length > 0) {
            setComments(data.comments);
            setTotalComments(data.totalComments);
          }

          if (contentType === "comments") {
            fetchLockComments.current = false;
          } else {
            fetchLockImages.current = false;
          }
          setIsLoading(false);
        });
    },
    [
      images,
      comments,
      imagesCurrentPage,
      commentsCurrentPage,
      fetchLockComments,
      fetchLockImages
    ]
  );

  useEffect(() => {
    if (!fetchLockImages.current) {
      fetchContent("images");
    }
  }, [imagesCurrentPage, fetchLockImages]);

  useEffect(() => {
    if (!fetchLockImages.current) {
      fetchContent("comments");
    }
  }, [commentsCurrentPage, fetchLockComments]);

  useEffect(() => {
    if (!fetchLockComments.current) {
      fetchContent("comments");
    }
    if (!fetchLockImages.current) {
      fetchContent("images");
    }
  }, [commentValidation, imageValidation, fetchLockComments, fetchLockImages]);

  return (
    <div className={"c-admin-moderation-wrapper"}>
      <div className={"c-admin-moderation-settings"}>
        <div className={"c-admin-moderation-settings-wrapper"}>
          <div style={{ fontWeight: 700 }}>Comment validation</div>
          <div className={"c-admin-moderation-settings-switch"}>
            <Switch
              checked={commentValidation}
              inputProps={{ "aria-label": "controlled" }}
              onChange={() => switchValidation("comment")}
            />
          </div>
        </div>
        <div className={"c-admin-moderation-settings-wrapper"}>
          <div style={{ fontWeight: 700 }}>Image validation</div>
          <div className={"c-admin-moderation-settings-switch"}>
            <Switch
              checked={imageValidation}
              inputProps={{ "aria-label": "controlled" }}
              onChange={() => switchValidation("image")}
            />
          </div>
        </div>
      </div>

      <ModerationContext.Provider
        value={{
          comments,
          setComments,
          totalComments,
          setTotalComments,
          commentsCurrentPage,
          setCommentsCurrentPage,
          images,
          setImages,
          totalImages,
          setTotalImages,
          imagesCurrentPage,
          setImagesCurrentPage,
          isLoading,
          dialogMode,
          setDialogIsOpen,
          setDialogMode,
          commentFilterType,
          setCommentFilterType,
          imageFilterType,
          setImageFilterType,
          fetchContent
        }}
      >
        <>
          {commentValidation && <ModerationView />}
          {imageValidation && <ModerationView isImages />}
        </>
      </ModerationContext.Provider>

      <Dialog
        open={dialogIsOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setDialogIsOpen(false);
        }}
        sx={[styles.dialog]}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {typeof dialogMode === "string" &&
            dialogMode.startsWith("decline") && (
              <div>{`Are you sure you want to decline this ${dialogMode.endsWith("comment") ? "comment" : "image"
                }?`}</div>
            )}

          {typeof dialogMode === "string" &&
            dialogMode.startsWith("approve") && (
              <div>{`Are you sure you want to approve this ${dialogMode.endsWith("comment") ? "comment" : "image"
                }?`}</div>
            )}

          {dialogMode?.isApproved && (
            <div>
              The comment has now been approved.{" "}
              <a
                href={`/PlayerPage?v=${dialogMode?.videoID}&target=${dialogMode?.commentId}`}
                target={"_blank"}
              >
                You can visit it here
              </a>
              .
            </div>
          )}
        </DialogTitle>
        {typeof dialogMode === "string" && (
          <DialogActions>
            <Button
              sx={[styles.button, styles.no]}
              onClick={() => {
                setDialogIsOpen(false);
                // setDialogMode(null);
              }}
            >
              NO
            </Button>
            <Button
              sx={[styles.button, styles.yes]}
              onClick={() => {
                setDialogIsOpen(false);
                setDialogMode({
                  approveType: dialogMode.endsWith("comment")
                    ? "comment"
                    : "image",
                  approve: dialogMode.startsWith("approve") ? true : false,
                });
              }}
            >
              YES
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}

export default AdminModeration;
