import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Slide from "@mui/material/Slide";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { ToastContainer, toast } from 'react-toastify'; // Import toast component and container
import 'react-toastify/dist/ReactToastify.css'; // Import toast CSS
import axios from 'axios';
import { notificationApi } from '../../api/notificationApi';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AdminNotification() {
  const [open, setOpen] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    image: "",
  });
  const [schedule, setSchedule] = useState({
    days: [],
    title: "",
    time: "",
    body: "",
  });
  const [groupNotification, setGroupNotification] = useState({
    status: "",
    title: "",
    body: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotification((prevNotification) => ({
      ...prevNotification,
      [name]: value,
    }));
  };

  const handleScheduleInputChange = (e) => {
    const { name, value } = e.target;
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [name]: value,
    }));
  };

  const handleGroupInputChange = (e) => {
    const { name, value } = e.target;
    setGroupNotification((prevGroupNotification) => ({
      ...prevGroupNotification,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Your Axios logic for sending notification
      const response = await axios.post( `${process.env.REACT_APP_API_URL}/notification/send`, notification);
      if (response.data.success) {
        toast.success("Notification sent successfully!");
        setOpen(false);
      } else {
        toast.error("Failed to send notification.");
      }
      setOpen(false);
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("Failed to send notification. Please try again later.");
    }
  };

  const handleScheduleSubmit = async (e) => {
    e.preventDefault();
    try {
      const daysMap = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      };
      const daysNumbers = schedule.days.map(day => daysMap[day]);
      const formattedTime = schedule.time.substring(0, 5); // Extract HH:mm from the time string
      const scheduleData = {
        days: daysNumbers,
        time: formattedTime,
        title: schedule.title,
        body: schedule.body,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/schedule/notification`, scheduleData);
      if (response.data.success) {
        toast.success("Notification scheduled successfully!");
        setScheduleOpen(false);
      } else {
        toast.error("Failed to schedule notification.");
      }
    } catch (error) {
      console.error("Error scheduling notification:", error);
      toast.error("Failed to schedule notification. Please try again later.");
    }
  };
  

  const handleGroupSubmit = async (e) => {
    e.preventDefault();
    try {
      // Your Axios logic for sending group notification
      const response = await axios.post( `${process.env.REACT_APP_API_URL}/group/notification`, groupNotification);
      if (response.data.success) {
        toast.success("Group notification sent successfully!");
        setOpen(false);
      } else {
        toast.error("Failed to Send notification.");
      }

      setGroupOpen(false);
    } catch (error) {
      console.error("Error sending group notification:", error);
      toast.error("Failed to send group notification. Please try again later.");
    }
  };

  const handleGroupNotification = () => {
    setGroupOpen(true);
  };

  const [notificationList, setNotificationList] = useState([]);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await notificationApi.getNotification()
        setNotificationList(response.data.list)
        // console.log(response)
      } catch (err) {
        console.log(err)
      }
    }
    fetchNotifications()
  }, [])

  return (
    <div className="container">
      <Button color="primary" variant="contained" className="mx-1" onClick={() => setOpen(true)}><i className="fas fa-bell me-1"></i> Send Notification</Button>
      <Button color="primary" variant="contained" className="mx-1" onClick={() => setScheduleOpen(true)}><i className="fas fa-calendar me-1"></i> Schedule Notification</Button>
      <Button color="primary" variant="contained" className="mx-1" onClick={handleGroupNotification}><i className="fas fa-users me-1"></i> Group Notification</Button>
      <ToastContainer /> {/* Include the ToastContainer */}
      <div className="c-notification_container">
        {
          notificationList.length === 0 ? 
          <p>No notification found</p> 
          :
          <>
            <div className="c-notification_header">
              <p>Title</p>
              <p>Body</p>
              <p>Image</p>
            </div>
            {
              notificationList.map((notification) => (
                <div className='c-notification_item'>
                  <p>{notification.title}</p>
                  <p>{notification.body}</p>
                  <img src={notification.data.image} alt="notification image"/>
                </div>
              ))
            }
          </>
          
        }
      </div>    
      
      {/* Dialog for sending notification */}
      <Dialog open={open} TransitionComponent={Transition} onClose={() => setOpen(false)}>
        <DialogTitle style={{ fontFamily: "Arial", fontSize: "16px", color: "white" }}>Send Notification</DialogTitle>
        <form onSubmit={handleSubmit} className="container">
          <TextField
            name="title"
            label="Title"
            value={notification.title}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="body"
            label="Body"
            value={notification.body}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            name="image"
            label="Image URL"
            value={notification.image}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Send</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog for scheduling notification */}
      <Dialog open={scheduleOpen} TransitionComponent={Transition} onClose={() => setScheduleOpen(false)}>
        <DialogTitle style={{ fontFamily: "Arial", fontSize: "16px", color: "white" }}>Schedule Notification</DialogTitle>
        <form onSubmit={handleScheduleSubmit} className="container">
          <FormControl fullWidth margin="normal">
            <InputLabel id="days-label">Select Days</InputLabel>
            <Select
              labelId="days-label"
              id="days"
              multiple
              value={schedule.days}
              onChange={(e) => setSchedule({ ...schedule, days: e.target.value })}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="Monday">Monday</MenuItem>
              <MenuItem value="Tuesday">Tuesday</MenuItem>
              <MenuItem value="Wednesday">Wednesday</MenuItem>
              <MenuItem value="Thursday">Thursday</MenuItem>
              <MenuItem value="Friday">Friday</MenuItem>
              <MenuItem value="Saturday">Saturday</MenuItem>
              <MenuItem value="Sunday">Sunday</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="title"
            label="Title"
            value={schedule.title}
            onChange={handleScheduleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="time"
            label="Time"
            type="time"
            value={schedule.time}
            onChange={handleScheduleInputChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <TextField
            name="body"
            label="Body"
            value={schedule.body}
            onChange={handleScheduleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <DialogActions>
            <Button onClick={() => setScheduleOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Schedule</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog for group notification */}
      <Dialog open={groupOpen} TransitionComponent={Transition} onClose={() => setGroupOpen(false)}>
        <DialogTitle style={{ fontFamily: "Arial", fontSize: "16px", color: "white" }}>Group Notification</DialogTitle>
        <form onSubmit={handleGroupSubmit} className="container">
          <FormControl fullWidth margin="normal">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              name="status"
              value={groupNotification.status}
              onChange={handleGroupInputChange}
            >
              <MenuItem value="future">Future</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="in_trial">In Trial</MenuItem>
              <MenuItem value="non_renewing">Non Renewing</MenuItem>
              <MenuItem value="paused">Paused</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="transfered">Transfered</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="title"
            label="Title"
            value={groupNotification.title}
            onChange={handleGroupInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="body"
            label="Body"
            value={groupNotification.body}
            onChange={handleGroupInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <DialogActions>
            <Button onClick={() => setGroupOpen(false)}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Send Group Notification</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default AdminNotification;
