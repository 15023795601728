import axios from "axios";
import { apiWrapper } from "../utils/apiWrapper";
import { getConfig } from "../utils/getConfig";

export const createBannerApi = async (title, releaseDate, isActive, imageLink, token) => {
  console.log({ title, releaseDate, isActive, imageLink });
  const config = getConfig(token);
  return await apiWrapper(
    axios.post(
      `${process.env.REACT_APP_API_URL}/banner`,
      { title, releaseDate, isActive, imageLink },
      config
    )
  );
};

export const deleteBannerApi = async (id, token) => {
  const config = getConfig(token);
  return await apiWrapper(axios.delete(`${process.env.REACT_APP_API_URL}/banner/${id}`, config));
};

export const markAsActiveBannerApi = async (id,status, token) => {
  const config = getConfig(token);
  return await apiWrapper(axios.put(`${process.env.REACT_APP_API_URL}/banner/toggleStatus/${id}`, {status}, config));
};

export const editBannerApi = async (id, title, releaseDate, isActive, imageLink, token) => {
  const config = getConfig(token);
  return await apiWrapper(
    axios.put(
      `${process.env.REACT_APP_API_URL}/banner/${id}`,
      { title, releaseDate, isActive, imageLink },
      config
    )
  );
};

export const bannerApis = {
  createBannerApi,
  deleteBannerApi,
  markAsActiveBannerApi,
  editBannerApi
};
