import { createSlice } from "@reduxjs/toolkit";

const initState = {
  videoDropdownList: [],
};

export const videoSlice = createSlice({
  name: "video",
  initialState: initState,
  reducers: {
    getVideoDropDownList: (state, { payload }) => {
      state.videoDropdownList = payload;
    },
  },
});

export const {
  getVideoDropDownList,
} = videoSlice.actions;

export default videoSlice.reducer;
