import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import Facebook from "../Facebook";
import { userPlans, studioPlans } from "../../data/utils/plans";

const ChargebeeButton = ({ planType, seats, newlySignedUp, isStudio }) => {
    const userData = useAtomValue(userInfoAtom);
    const navigate = useNavigate();

    const plans = isStudio ? studioPlans : userPlans;

    const selectedPlan = isStudio 
    ? plans.find(plan => plan.seats === seats)  
    : plans.find(plan => plan.param === planType);

    console.log(selectedPlan?.cbItem);

    const handleChargebeeClick = (type) => {
        if (userData.token || newlySignedUp) {
            window.Chargebee.registerAgain();
            window?.fbq('trackCustom', selectedPlan.id, { CustomEvent: selectedPlan.id });
        } else {
            navigate(`/signup?plan=${selectedPlan.param}`);
        }
    };

    return (
        <>
            <Facebook />
            <button
                id={selectedPlan.id}
                style={{ width: '100%' }}
                data-cb-type="checkout"
                data-cb-item-0={selectedPlan.cbItem}
                onClick={() => handleChargebeeClick(planType)}
            >
                {!isStudio ? (
                    <div> FREE {selectedPlan.days} DAY TRIAL</div>
                ):(
                    <div> Get your plan </div>
                )}

            </button>
        </>
    );
};

export default ChargebeeButton;
