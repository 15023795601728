import { apiWrapper } from "../utils/apiWrapper"
import axiosMuiltipartConfig from "../utils/axiosMultiPartConfigInstance";

export const fileUploadApi = async(file) => {
    const formData = new FormData();
    formData.append("file", file);
    console.log(formData)
    return await apiWrapper( axiosMuiltipartConfig.post(`${process.env.REACT_APP_API_URL}/common/file-upload`, formData))
}


export const fileUploadApis = {
    fileUploadApi
}