import React, { useState, useContext, useCallback, useRef } from "react";
import axios from "axios";
import "./PlayerPage.css";
import toast from "react-hot-toast";
import { CommentsContext } from "./Comments";
import { useSelector } from "react-redux";
import UserImage from "../UserImage/UserImage";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";

function NewComment({ videoId, replyTo }) {
  const textRef = useRef(null);
  const userInfo = useSelector((state) => state.authState.userInfo);
  const userData = useAtomValue(userInfoAtom);
  const [newCommentContent, setNewCommentContent] = useState("");
  const [shiftPressed, setShiftPressed] = useState(false);
  const { comments, setComments, setReplying, setTotalComments } =
    useContext(CommentsContext);
  const [value, setValue] = useState("");
  const [newLines, setNewLines] = useState(0);

  const [latestSubmitTime, setLatestSubmitTime] = useState(0);

  const submitLock = useRef(false);

  const submitComment = useCallback(() => {
    if (submitLock.current || newCommentContent.length === 0) {
      return null;
    }
    submitLock.current = true;

    const loadingToastId = toast.loading("Posting comment...", {
      position: "bottom-center",
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/comments`, {
        videoID: videoId,
        content: newCommentContent,
        replyTo,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then(({ data }) => {
        toast.dismiss(loadingToastId);

        if (data.error) {
          toast.error(data.error, {
            position: "bottom-center",
          });
          return null
        }

        if (data.requiresValidation) {
          toast.success(
            "Comment is now pending approval from administration.",
            {
              position: "bottom-center",
              duration: 7000
            }
          );
        } else if (data.comment) {
          toast.success("Successfully posted!", {
            position: "bottom-center",
          });

          setNewLines(0)

          const newComment = {
            ...data?.comment,
            replies: [],
            userDetails: [
              {
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
              },
            ],
          };

          let updatedComments = replyTo
            ? [...comments].map((comment) => {
              if (comment._id === replyTo) {
                comment.replies.push(newComment);
              }
              return comment;
            })
            : [...comments, newComment];

          updatedComments = updatedComments?.map((comment) => {
              if (comment.replies && comment.replies.length > 0) {
                comment.replies = comment.replies.sort((a, b) => {
                  const aDate = new Date(a.createdAt);
                  const bDate = new Date(b.createdAt);
                  return bDate.getTime() - aDate.getTime();
                });
              }
              return comment;
            })
            .sort((a, b) => {
              const aDate = new Date(a.createdAt);
              const bDate = new Date(b.createdAt);
              return bDate.getTime() - aDate.getTime();
            });

          setComments(updatedComments);
          setTotalComments((prev) => prev + 1);
        }

        setReplying("");

        if (textRef.current) {
          textRef.current.value = "";
          textRef.current.blur();
        }

        return data.comment

      }).then((data) => {
        submitLock.current = false
        setLatestSubmitTime(() => new Date().getTime())
      })
     
      setValue("")
  },
  [
    comments,
    setComments,
    newCommentContent,
    setReplying,
    userInfo,
    replyTo,
    submitLock,
    latestSubmitTime
  ]
);  

  const handleInputChange = (e) => {
    setValue(e.target.value)
    setNewLines(e.target.value.split('\n').length)
  }

  return (
    <div
      className="c-video_new-comment-innerwrapper"
      style={{
        marginTop: replyTo ? "-12px" : "16px",
      }}
    >
      {!replyTo && <UserImage imageURL={userInfo?.image}/>}

      <div
        className="c-video_new-comment-wrapper"
        style={{
          // flexDirection: replyTo ? "row" : "column",
          // alignItems: replyTo ? "center" : "flex-end",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <textarea
            ref={textRef}
            value={value}
            className={`c-video_new-comment-area`}
            rows={newLines}
            onChange={(e) => {
              handleInputChange(e)
              setNewCommentContent(e.target.value);
            }}
            style={
              replyTo
                ? { padding: "8px 14px" }
                : {
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #4D4D4D",
                  borderRadius: "0",
                  paddingBottom: "4px",
                  paddingTop: "4px"
                }
            }
            placeholder={`${replyTo ? "Reply with" : "Add"} a comment...`}
            onKeyDown={(e) => {
              if (e.key === "Shift") {
                setShiftPressed(true);
              }

              if (e.key === "Enter" && !shiftPressed) {
                e.preventDefault();
                submitComment();
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Shift") {
                setShiftPressed(false);
              }
            }}
          />
        </div>

        <div
          className={`c-video_new-comment-post-button ${newCommentContent.length !== 0
              ? "c-video_new-comment-post-button-hover c-video_new-comment-post-button-active"
              : ""
            }`}
          style={
            replyTo
              ? {
                alignSelf: "center",
              }
              : {}
          }
          disabled={newCommentContent.length === 0}
          onClick={submitComment}
        >
          <button className={"c-video_comments-button-wrapper"}>
            <div className={"c-video_comments-button-wrapper-inner"}>
              {replyTo ? "REPLY" : "POST"}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewComment;
