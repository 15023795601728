import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
  Button,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import {
  useDeleteFeaturedPlayListMutation,
  useFeaturedPlayListQuery,
} from "../../../service";
import { getFeaturedPlayList } from "../../../redux/featuredPlayListSlice";
import toast from "react-hot-toast";
import { Edit, MoreVertical, Trash } from "react-feather";
import DeleteModalPlayList from "../../playlist/personal-playlist/DeleteModalPlayList";

function AdminFeaturedList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resFeaturedPlayList = useFeaturedPlayListQuery();
  const [reqDelete, resDelete] = useDeleteFeaturedPlayListMutation();
  const [playListId, setPlayListId] = useState(null);
  const [playlistmodal, setPlaylistmodal] = useState(false);

  console.log("resFeaturedPlayList", resFeaturedPlayList);
  const FPL = useSelector(
    (state) => state.featuredPlayListState.featuredPlayList
  );

  useEffect(() => {
    if (
      resFeaturedPlayList?.isSuccess &&
      resFeaturedPlayList?.data?.existingVideos
    ) {
      dispatch(getFeaturedPlayList(resFeaturedPlayList?.data?.existingVideos));
    } else {
      dispatch(getFeaturedPlayList([]));
    }
  }, [resFeaturedPlayList]);

  const onEdit = (e, dt) => {
    e.preventDefault();
    navigate("/AdminDashboard/featured-playlist-form", {
      state: {
        playId: dt?._id,
        isEdit: true,
      },
    });
  };

  const onDelete = (e, dt) => {
    e.preventDefault();
    setPlayListId(dt?._id)
    setPlaylistmodal(true);
    //reqDelete(dt?._id);
  };
  const onConfirmActionPlaylist = () => {
    if (playListId) {
      reqDelete(playListId);
    }
  };
  const onCancelActionPlaylist = () => {
    setPlayListId(null);
    setPlaylistmodal(false);
  };

  useEffect(() => {
    if (resDelete?.isSuccess) {
      setPlaylistmodal(false);
      toast.success("Playlist deleted Successfully", {
        position: "top-center",
        duration: 3000,
      });
    }
    if (resDelete?.isError) {
      setPlaylistmodal(false);
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [resDelete]);
  return (
    <>
    <Card className="overflow-hidden">
      <CardHeader>
        <CardTitle tag="h4">PlayList</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <div className="w-30">
              <Button
                color="primary"
                onClick={(e) =>
                  navigate("/AdminDashboard/featured-playlist-form")
                }
                className="mt-2"
              >
                Add Playlist
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md="12">
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {FPL && Array.isArray(FPL) && FPL?.length > 0 ? (
                  FPL?.map((el, i) => {
                    return (
                      <tr key={i}>
                        <td>{el?.name}</td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="icon-btn hide-arrow"
                              color="transparent"
                              size="sm"
                              caret
                            >
                              <MoreVertical size={15} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                href="#!"
                                onClick={(e) => onEdit(e, el)}
                              >
                                <Edit className="me-50" size={15} />{" "}
                                <span className="align-middle">Edit</span>
                              </DropdownItem>
                              <DropdownItem
                                href="#!"
                                onClick={(e) => onDelete(e, el)}
                              >
                                <Trash className="me-50" size={15} />{" "}
                                <span className="align-middle">Delete</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <DeleteModalPlayList
        modal={playlistmodal}
        onConfirmAction={onConfirmActionPlaylist}
        onCancelAction={onCancelActionPlaylist}
      />
    </>
  );
}

export default AdminFeaturedList;
