import React from "react";
import Logo from "../../../Asset/images/logo-blaxk.webp";

export default function Header({ onClick }) {
  return (
    <header className="c-sing-header">
      <div className="row m-0">
        <div className="col-md-12">
          <div className="c-sing_logo--container">
            <div className="c-sing_logo">
              <img src={Logo} alt="Logo" onClick={onClick} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
