import React from "react";
import Featureimg from "../Asset/images/features/features-thumbnail-1.png";

function History() {
  const historyItems = [
    {
      title: "Tips and tricks for turns",
      link1: "Across the Floor",
      link2: "Strength",
      timeAgo: "2 months ago",
    },
    {
      title: "Tips and tricks for turns",
      link1: "Across the Floor",
      link2: "Strength",
      timeAgo: "2 months ago",
    },
    {
      title: "Tips and tricks for turns",
      link1: "Across the Floor",
      link2: "Strength",
      timeAgo: "2 months ago",
    },
    {
      title: "Tips and tricks for turns",
      link1: "Across the Floor",
      link2: "Strength",
      timeAgo: "2 months ago",
    },
  ];

  return (
    <div>
      <div>
        <div className="tab-content">
          <div id="menu2" className="tab-pane fade active show">
            {/* ///////////////////////Mobile class///////////////// */}
            <div className="row m-0 c-tab_cata-text c-mobile_top-padding">
              <div className="col-md-5 m-auto">
                <div>
                  <div className="c-header_search">
                    <form action="">
                      <input type="text" id="search" placeholder="Search" />
                      <button id="search-button" aria-label="search button">
                        <span className="c_header-mac">
                          <i class="fa-solid fa-microphone"></i>
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M17.8516 17.5L14.2266 13.875M16.1849 9.16667C16.1849 12.8486 13.2001 15.8333 9.51823 15.8333C5.83633 15.8333 2.85156 12.8486 2.85156 9.16667C2.85156 5.48477 5.83633 2.5 9.51823 2.5C13.2001 2.5 16.1849 5.48477 16.1849 9.16667Z"
                            stroke="#71717A"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="">
                  <h4>History</h4>
                </div>
              </div>
            </div>
            <div className="c-history">
              {/* /////////////////////////////////Mobiule class */}
              <div className="row m-0 c-history_textrow c-mobile-his-titl">
                <div className="col-md-8 c-history_textcol p-0">
                  <h4>HISTORY</h4>
                </div>
                <div className="col-md-4 c-history_clean p-0">
                  <a href="http://">Clear all history</a>
                </div>
              </div>
              {/* ============= History ============ */}
              {/* ///////////////////////////////Mobile class//////////////// */}
              <div className="row m-0 mt-2">
                <div className="col-md-12 p-0">
                  <div className="c-history-right">
                    {historyItems.map((item, index) => (
                      <div
                        key={index}
                        className="row m-0 c-history-rightd1 c-history-rightd1nth"
                      >
                        <div className="col-md-3 p-0 c-mobile_col-hi1">
                          <div className="c-history-rightd2">
                            <div className="c-history-rightd21">
                              <a href="http://">
                                <i className="fa-regular fa-trash-can"></i>
                              </a>
                            </div>
                            <div className="c-history-rightd22">
                              <img src={Featureimg} alt="" srcSet="" />
                              <span>2:03</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9 c-mobile_col-hi2">
                          <div className="row m-0 p-0">
                            <div className="col-md-11 c-mobile_col-hi3">
                              <div className="c-history-right-b1">
                                <h4>{item.title}</h4>
                                <div>
                                  <ul>
                                    <li>
                                      <a href="">{item.link1}</a>
                                    </li>
                                    <li>
                                      <a href="">{item.link2}</a>
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  <span>{item.timeAgo}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1 c-mobile_col-hi4">
                              <div className="c-history-right-d1">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i className="fa-solid fa-plus"></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a className="c-history-right-ha" href="">
                                      <i className="fa-solid fa-heart"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
