import React, { useContext, useEffect, useState } from "react";
import { VideoContext } from "../Video_player/VideoContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Card from ".././Card";
import { FcLike } from "react-icons/fc";
import { IoMdHeartEmpty } from "react-icons/io";
import { MdPlaylistAdd } from "react-icons/md";
import LoaderComponet from "../LoderComponent";

import AddToPlayListModal from "../playlist/personal-playlist/AddToPlayListModal";
import api from "../../data/api";
import { goToSearchPage } from "../searchPage/SearchContext";
import SearchButton from "../searchPage/SearchButton";
function VideoPlayer() {
  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);
  const [localStorageData, setLocalStorageData] = useState(null);
  const [videos, setVideos] = useState("");
  const [modal, setModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const redirect = useNavigate();

  function showVideo(url, title, desc, tags, thumbnail, products, _id) {
    setSelectedVideo({ url, title, desc, tags, thumbnail, products, _id });
    redirect(`/PlayerPage?v=${_id}`);
  }

  useEffect(() => {
    const data = window.localStorage.getItem("selectedVideo");

    if (data) {
      setLocalStorageData(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      setLocalStorageData(selectedVideo);
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (localStorageData && localStorageData._id) {
      window.localStorage.setItem(
        "selectedVideo",
        JSON.stringify(localStorageData)
      );
      
      axios
        .post(`${process.env.REACT_APP_API_URL}/fetch/videos/data`, {
          videoId: localStorageData._id,
        })
        .then((response) => {
          setVideos(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [localStorageData]);
  

  const handleSubmit = async (id, wishlist) => {
    try {
      if (wishlist) {
        const response = await api.unsaveWishlist(id);
        if (response.status === 200) {
          setLocalStorageData({
            ...localStorageData,
            wishlist: !localStorageData.wishlist,
          });
        }
      } else {
        const response = await api.saveWishlist(id);
        if (response.status === 200) {
          setLocalStorageData({
            ...localStorageData,
            wishlist: !localStorageData.wishlist,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleSearchVideo = async (e) => {
    // console.log('calling login function');
    const trimmedSearchKey = searchKey.trim();

    if (trimmedSearchKey.length >= 3) {
      redirect(`/result?search_query=${encodeURIComponent(trimmedSearchKey)}`);
    }
  }

  return (
    <div>
      <div className="row m-0 c-mobile_honm-serch">
        <div className="c-header_search">
          <form action="">
            <input
              onChange={(e) => {
                // Handle onChange logic here if needed
                setSearchKey(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  handleSearchVideo(e.target.value);
                }
              }}
              value={searchKey}
              type="text"
              id="search"
              placeholder="Search"
            />
          </form>
          <SearchButton searchKey={searchKey}/>
        </div>
      </div>
      {/*/////// laptop css */}
      <div className="c-video-section">
        <div className="row">
          <div className="col-md-10">
            <div className="c-video-player_frame">
              {localStorageData && localStorageData.videolink ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={localStorageData.videolink}
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
              ) : (
                <LoaderComponet loading />
              )}
            </div>
          </div>
          <div className="col-md-2">
            <div className="c-video-set-list">
              <div className="c-video-set-list-h3">
                <h3>RECOMMENDED</h3>
              </div>
              <div className="c-video-set-listbox">
                {Array.isArray(videos) &&
                  videos.map((v, index) => (
                    <div
                      key={v.v_id || index}
                      onClick={() =>
                        showVideo(
                          v.videolink,
                          v.title,
                          v.description,
                          v.tagsData,
                          v.thumnailLink,
                          v.productsData,
                          v._id
                        )
                      }
                    >
                      <Card
                        thumb_url={v.thumnailLink}
                        video_url={v.videolink}
                        thumbnail_pic={v.thumnailLink}
                        video_duration={v.videoDetails?.duration || v.duration}
                      // playerRef={playerRef}
                      />
                    </div>
                  ))}
              </div>
              <div className="c-video-se-listicon">
                <div>
                  <ul>
                    <li style={{ cursor: "pointer" }}>
                      {/* <a href="http://"> */}
                      <p className="">
                        {/* <i className="fa-regular fa-heart"></i> */}
                        <span
                          id={localStorageData?._id}
                          onClick={(e) => {
                            const id = e.currentTarget.id; // Accessing the id from the clicked element
                            handleSubmit(id, localStorageData?.wishlist);
                          }}
                        >
                          {localStorageData?.wishlist ? (
                            <FcLike />
                          ) : (
                            <IoMdHeartEmpty />
                          )}
                        </span>
                      </p>
                      <p>Save</p>
                      {/* </a> */}
                    </li>
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => setModal(true)}
                    >
                      <p>
                        <span className="svg-2">
                          <MdPlaylistAdd />
                        </span>
                      </p>
                      <p>Add to playlist</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddToPlayListModal
        modal={modal}
        setModal={setModal}
        activeObject={localStorageData}
        viewData={videos}
      />
    </div>
  );
}

export default VideoPlayer;
