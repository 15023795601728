export const userPlans = [
    {
        id: "Subscribe-Yearly",
        title: "Yearly Plan",
        cbItem: "on-demand-individual-yearly",
        days: 7,
        param: "yearly"
    },
    {
        id: "Subscribe-Monthly",
        title: "Monthly Plan",
        cbItem: "individual-monthly",
        days: 3,
        param: "monthly"
    }
];

export const studioPlans = [
    {
        id: "Studio-Account-5",
        title: "Studio Plan - 5",
        seats:5,
        cbItem: "Studio-Account-5-USD-Yearly",
        days: 0,
        param: "yearly"
    },
    {
        id: "Studio-Account-10",
        title: "Studio Plan - 10",
        seats:10,
        cbItem: "Studio-Account-10-Seats-USD",
        days: 0,
        param: "yearly"
    }
];
