function videoTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  if(formattedHours > 0){
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else if(formattedMinutes > 0){
    return `${formattedMinutes}:${formattedSeconds}`;
  } else{
    return `00:${formattedSeconds}`;
  }
  }

  export default videoTime;