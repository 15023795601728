import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userRoleTypes } from "../utils/userRoleTypes";

const useAuthentication = (role = userRoleTypes.ADMIN) => {
  const userInfo = useSelector((state) => state.authState.userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(userInfo);
    if (userInfo == null) {
      toast.error("Please Login Again");
      navigate("/login");
    }

    if(userInfo?.role !== role) {
      toast.error("user role does not match");
      navigate("/login");
    }

  }, [navigate, role, userInfo]);
};

export default useAuthentication;
