import { useAtomValue, useSetAtom } from "jotai";
import { loaderState, userInfoAtom } from "../data/atoms/utilsAtoms";
import { toast } from "react-toastify";
import { useCallback } from "react";

export const useFormSubmit = (onSubmitFunction) => {
  const userData = useAtomValue(userInfoAtom);
  const setLoader = useSetAtom(loaderState);

  const onSubmitWithLoading = useCallback(
    async (formData) => {
      if (!userData?.token) {
        toast.error("Please login first");
        return;
      }
      try {
        setLoader(true);
        const token = userData.token;
        await onSubmitFunction(formData, token);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error during submission. Please try again.");
      } finally {
        setLoader(false);
      }
    },
    [onSubmitFunction, setLoader, userData.token]
  );

  return onSubmitWithLoading;
};
