import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    // Add any common headers here
  },
});

export const getAvailableSeats = async (token) => {
  return await instance.get("/studio/availableSeats", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSubUsers = async (token) => {
  return await instance.get("/studio/getSubUsers", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addSubUser = async (token, subUser) =>
 {
  return await instance.post("/studio/addSubUser", subUser, {
    headers: { Authorization: `Bearer ${token}` },
  }); 
 }

 export const updateSubUser = async (token, subUserData) => {
  return await instance.patch("/studio/updateSubUser", 
    subUserData,  
    {
      headers: { Authorization: `Bearer ${token}` },  // Headers should be a separate option
    }
  );
}

 export const deleteSubUser = async (token, subuserId) => {
  return await instance.delete("/studio/deleteSubUser", {
    headers: { Authorization: `Bearer ${token}` },
    data: { subuserId },
  });
};

