import React from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LoaderComponet from "../LoderComponent";

function RestPassword() {
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const { id, token } = useParams();
  console.log(id);
  console.log("token",token)
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  axios.defaults.withCredentials = true;
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/reset-password/${id}/${token}`, { password })
      .then((res) => {
        console.log(res.data.Status);
        if (res.data.Status === "Success") {
          alert("Password Reset Successful");
          setLoading(false);
          navigate("/Login");
        } else {
          navigate("/reset_password");
          setLoading(false);
          setResponseMessage(res.data.Status);
        }
      })
      .catch((err) => {
        setLoading(false);
        navigate("/reset_password");
        setResponseMessage("Somethings went wrong");
        console.log(err);
      });
  };

  return (
    <>
      {loading ? <LoaderComponet loading /> : " "}
      <section className="c-sing_form c-forget_page">
        <div className="container">
          <div>
            <div class="c-sing_heading c-sing_heading_forget">
              <h2>Reset Password</h2>
            </div>
            <div className="c-sing_form-box">
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 col-md-12 form-group">
                    <label htmlFor="email">
                      {/* <strong>New Password</strong> */}
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Password"
                      autoComplete="off"
                      name="password"
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <span>{responseMessage}</span>
                  <div className="col-md-12 form-group">
                    <button type="submit" className="btn ">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RestPassword;
