import React from "react";
import { FaHeart } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import LazyImage from "../LazyImage";

function formatDuration(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedTime = `${minutes}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;
  return formattedTime;
}

function Card({
  card,
  thumbnail_pic,
  thumb_url,
  video_url,
  isLoggedIn,
  video_duration,
  onClick,
}) {
  const formattedDuration = formatDuration(video_duration);
  const location = useLocation();
  const isPlayerPage = location.pathname === "/PlayerPage";

  return (
    <div className="c-video_thumbnail">
      {isLoggedIn ? (
        <iframe
          src={video_url}
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>
      ) : (
        <LazyImage
          src={thumbnail_pic}
          width={"100%"}
          height={"100%"}
          alt="Video Thumbnail"
          loading="lazy"
          onClick={onClick}
        />
      )}

      {isPlayerPage ? null : (
        <div className="c-video_time">
          <span>{formattedDuration}</span>
        </div>
      )}
    </div>
  );
}

export default Card;
