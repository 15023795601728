import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
console.log(pathname, "useLocation")
  useEffect(() => {
    const scrollToTop = () => {
      window.requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });
    };

    scrollToTop();
  }, [pathname]);

  return children || null;
};

export default ScrollToTop;
