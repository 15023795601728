import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';
import JsCookies from 'js-cookie';

const cookies = new Cookies();

//console.log("cookies data",cookies?.get('user'));

const initState = {
    userInfo: cookies?.get('user'), // for user object
    userToken: JsCookies?.get('token'), // for storing the JWT,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    setUserToken: (state, { payload }) => {
      state.userToken = payload;
    },
    setUserInfo: (state, { payload }) => {
        state.userInfo = payload;
    },
  },
});

export const {
  setUserToken,
  setUserInfo,
} = authSlice.actions;

export default authSlice.reducer;
