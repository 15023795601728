import React, { useState, useEffect, useContext } from "react";
import { VideoContext } from "../Video_player/VideoContext";
import axios from "axios";
import Card from ".././Card";
import { useNavigate } from "react-router-dom";
import { FcLike } from "react-icons/fc";
import { IoMdHeartEmpty } from "react-icons/io";
import { MdPlaylistAdd } from "react-icons/md";
import AddToPlayListModal from "../playlist/personal-playlist/AddToPlayListModal";
import api from "../../data/api";

function Videodesc() {
  const [videos, setVideos] = useState("");
  const [modal, setModal] = useState(false);
  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);
  const [videoData, setVideoData] = useState({});
  const redirect = useNavigate();

  function showVideo(url, title, desc, tags, thumbnail, products, _id) {
    setSelectedVideo({ url, title, desc, tags, thumbnail, products, _id });
    redirect(`/PlayerPage?v=${_id}`);
  }

  useEffect(() => {
    const data = window.localStorage.getItem("selectedVideo");

    if (data) {
      setVideoData(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      setVideoData(selectedVideo);
    }
  }, [selectedVideo]);

  const handleSubmit = async (id, wishlist) => {
    try {
      if (wishlist) {
        const response = await api.unsaveWishlist(id);
        if (response.status === 200) {
          setVideoData({
            ...videoData,
            wishlist: !videoData.wishlist,
          });
        }
      } else {
        const response = await api.saveWishlist(id);
        if (response.status === 200) {
          setVideoData({
            ...videoData,
            wishlist: !videoData.wishlist,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  const [localStorageData, setLocalStorageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const data = window.localStorage.getItem("selectedVideo");

    if (data) {
      setLocalStorageData(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      setLocalStorageData(selectedVideo);
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (localStorageData) {
      window.localStorage.setItem(
        "selectedVideo",
        JSON.stringify(localStorageData)
      );
    }
    console.log("TAGS ID SELECTED IN EXERCISE", localStorageData);
    if (
      localStorageData &&
      localStorageData.tagsData &&
      localStorageData.tagsData.length !== 0
    ) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/fetch/videos/data`, {
          videoId: localStorageData?._id,
        })
        .then((response) => {
          setVideos((prevVideos) => {
            return response.data;
          })
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError("Error fetching data. Please try again.");
          setLoading(false);
        });
    }
  }, [localStorageData]);

  return (
    <div className="c-video_description">
      <div className="row m-0">
        <div className="col-md-9">
          <div className="c-video_description-h4">
            <h4>{videoData.title}</h4>
            <div className="c-mobile_vide-icon">
              <div>
                <ul>
                  <li>
                    <a>
                      <p>
                        <span
                          id={videoData?._id}
                          onClick={(e) => {
                            const id = e.currentTarget.id; // Accessing the id from the clicked element
                            handleSubmit(id, videoData?.wishlist);
                          }}
                        >
                          {videoData?.wishlist ? (
                            <FcLike />
                          ) : (
                            <IoMdHeartEmpty />
                          )}
                        </span>
                      </p>
                      <p className="c-mobile-text">Save</p>
                    </a>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => setModal(true)}
                  >
                    <a>
                      <p>
                        <span className="svg-2">
                          <MdPlaylistAdd />
                        </span>
                      </p>
                      <p className="c-mobile-text">Add to playlist</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="c-video_description-tag">
            <ul>
              {(videoData.tagsData || [])
                .map((tag) => ({
                  name: tag.name,
                  color: tag.color,
                  priority: tag.priority || 0, // Assign priority or default to 0
                }))
                .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                .map((tag) => (
                  <li key={tag.name} style={{ background: tag.color }}>
                    <a href="/">{tag.name}</a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="c-video_description-text">
            <p dangerouslySetInnerHTML={{ __html: videoData.description }}></p>
          </div>
        </div>
        <div className="col-md-3 c-video_description-side c-mobile_slider">
          <div className="c-video-set-list c-mobile-video_slider">
            <div className="c-video-set-list-h3">
              <h3>RECOMMENDED</h3>
            </div>
            <div className="c-video-set-listbox">
              {Array.isArray(videos) &&
                videos.map((v, index) => (
                  <div
                    key={v.v_id || index}
                    onClick={() =>
                      showVideo(
                        v.videolink,
                        v.title,
                        v.description,
                        v.tagsData,
                        v.thumnailLink,
                        v.productsData,
                        v._id
                      )
                    }
                  >
                    <Card
                      thumb_url={v.thumnailLink}
                      video_url={v.videolink}
                      thumbnail_pic={v.thumnailLink}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {videoData.productsData && videoData.productsData.length > 0 && (
          <div className="col-md-3 c-video_description-side">
            <div>
              <div className="c-video_description-side-h4">
                <h4>PRODUCTS SEEN IN VIDEO</h4>
              </div>
              <div className="c-mobile_ads">
                {(videoData.productsData || []).map((product) => (
                  <div
                    key={product.title}
                    className="c-video_description-sideimg"
                    style={{}}
                  >
                    <img src={product?.images[0]?.src} alt="image not find" />
                    <a
                      href={
                        "https://thegodfreymethod.myshopify.com/products/" +
                        product.handle
                      }
                      target="_blank"
                      style={{
                        color: "#000",
                        borderColor: "#000",
                      }}
                    >
                      BUY NOW
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        
      </div>
      <AddToPlayListModal
        modal={modal}
        setModal={setModal}
        activeObject={videoData}
        viewData={videos}
      />
    </div>
  );
}

export default Videodesc;
