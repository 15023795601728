import ReactDatePicker from "react-datepicker";
import CalendarInput from "../../component/CalendarInput";
import "react-datepicker/dist/react-datepicker.css";

export const DatePicker = ({ value, handleDateChange }) => {
    return (
      <ReactDatePicker
        selected={value}
        dateFormat="MMMM d, yyyy"
        customInput={<CalendarInput />}
        onChange={handleDateChange}
      />
    );
  }