/**
 * @param {*} api Response from the api call rather be it GET or POST or PUT or DELETE
 * @returns A structured json data with {data, error} mandatory fields
 */
export const apiWrapper = async(api) => {
  try {
    const { data } = await api;
    return { data: data.data, error: null, message: data.message };
  } catch (error) {
    return {
      data: null,
      message: error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
      error:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    };
  }
};
