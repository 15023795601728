import { createSlice } from "@reduxjs/toolkit";

const initState = {
    combinePlayList: [],
};

export const combinePlayListSlice = createSlice({
    name: "combinePlayList",
    initialState: initState,
    reducers: {
        updateCombinePlayList: (state, { payload }) => {
            if (payload?.length > 0 && payload[0]?.videos?.length > 0) {
                const tmpStr = JSON.stringify(payload);
                const tmp = JSON.parse(tmpStr);

                tmp[0].videos = tmp[0].videos.map((item) => {
                    if (item?.tagsData && item.tagsData.length > 0) {
                        item.tagsData.sort((a, b) => {
                            return a.priority - b.priority;
                        });
                    }
                    return item;
                });

                state.combinePlayList = tmp;
            } else {
                state.combinePlayList = payload;
            }
        },
    },
});

export const { getCombinePlayList } = combinePlayListSlice.actions;

export default combinePlayListSlice.reducer;