import React from "react";
import XButton from "../XButton/XButton";

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="delete-modal-content">
        <div className="cross-btn"><XButton onClick={onClose} /></div>
        
        <div className="delete-modal-body">
          <p>Are you sure you want to delete this member?</p>
          <div className="modal-actions">
        
            <button className="confirm-btn" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
