export const handleDateChange = (date, setFormData) => {
  const newDate = new Date(date);
  let year = newDate.getFullYear();
  let month = newDate.getMonth();
  let day = newDate.getDate();

  let nowDate = new Date(Date.UTC(year, month, day, 4, 0, 0) - newDate.getTimezoneOffset() * 60 * 1000);

  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();

  newDate.setHours(hours - offset);

  console.log(nowDate.toISOString())

  setFormData((prevData) => ({
    ...prevData,
    releaseDateTime: nowDate
  }));
};
