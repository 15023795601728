import React from 'react'
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import LoaderComponet from '../LoderComponent';

const SetSubUserPassword = () => {
    const [password, setPassword] = useState();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
  
    axios.defaults.withCredentials = true;
    const handleSubmit = (e) => {
      e.preventDefault();
      axios
        .post(`${process.env.REACT_APP_API_URL}/studio/set-password/${id}`, { password })
        .then((res) => {
          console.log(res);
            toast.success("Password set Successful", {
                position: "top-center",
                duration: 3000,
            });
            setLoading(false);
            navigate("/Login");
       
        })
        .catch((err) => {
          setLoading(false);
          navigate("/set-password");
          setResponseMessage("Somethings went wrong");
          console.log(err);
        });
    };
  
    return (
      <>
        {loading ? <LoaderComponet loading /> : " "}
        <section className="c-sing_form c-forget_page">
          <div className="container">
            <div>
              <div class="c-sing_heading c-sing_heading_forget">
                <h2>Set Your Password</h2>
              </div>
              <div className="c-sing_form-box">
                <div>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 col-md-12 form-group">
                      <label htmlFor="email">
                        {/* <strong>New Password</strong> */}
                      </label>
                      <input
                        type="password"
                        placeholder="Enter Password"
                        autoComplete="off"
                        name="password"
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <span>{responseMessage}</span>
                    <div className="col-md-12 form-group">
                      <button type="submit" className="btn ">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default SetSubUserPassword