import { Button, Container, TextField } from "@mui/material";
import React, { useEffect } from "react";
import {
  CheckBoxField,
  DatePicker,
  ImageUploader,
  LineBreaker,
} from "../../../atomicComponent/AtomicComponents";
import { useFormSubmit } from "../../../hook/useFormSubmit";
import api from "../../../data/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleErrorResponse } from "../../../utils/handleErrorResponse";

export const ProduceBanners = ({ initialValues, onSubmit }) => {
  const navigate = useNavigate();
  const [bannerDetails, setBannerDetails] = React.useState({
    title: "",
    image: null,
    isActive: false,
    releaseDate: new Date(),
  });

  useEffect(() => {
    if (initialValues) {
      setBannerDetails(initialValues);
    }
  }, [initialValues]);

  const handleChange = (prop) => (event) => {
    const value = event.target ? event.target.value : event;
    setBannerDetails({ ...bannerDetails, [prop]: value });
  };

  const handleSubmit = useFormSubmit(async (event, token) => {
    event.preventDefault();

    // Validate form fields
    if (!validateForm()) return;
    let submitImage = bannerDetails.image;
    // Upload image if it's a File object
    if (bannerDetails.image instanceof File) {
      const imageUrl = await api.fileUploadApi(bannerDetails.image);
      setBannerDetails((prev) => ({ ...prev, image: imageUrl?.data?.cdnLink }));
      submitImage = imageUrl?.data?.cdnLink;
      if (handleErrorResponse(imageUrl)) return; // Prevent execution if error occurred
    }

    // Submit form data
    const response = await onSubmit(
      bannerDetails.title,
      submitImage,
      bannerDetails.releaseDate,
      bannerDetails.isActive,
      token
    );

    if (handleErrorResponse(response)) return; // Prevent execution if error occurred
    toast.success(response.message);
    resetForm();
  });

  // Function to validate form fields
  const validateForm = () => {
    if (!bannerDetails.image) {
      toast.error("Please select an image");
      return false;
    }

    if (!bannerDetails.title) {
      toast.error("Please enter a title");
      return false;
    }

    return true;
  };

  // Function to reset form fields after submission
  const resetForm = () => {
    setBannerDetails({
      title: "",
      image: null,
      isActive: false,
      releaseDate: new Date(),
    });
    navigate("/AdminDashboard/banners");
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="Title"
            fullWidth
            value={bannerDetails.title}
            onChange={handleChange("title")}
          />
          <LineBreaker />
          <ImageUploader
            label="Upload Banner"
            value={bannerDetails.image}
            handleImageChange={handleChange("image")}
          />
          <LineBreaker />
          <DatePicker
            value={bannerDetails.releaseDate}
            handleDateChange={handleChange("releaseDate")}
          />
          <LineBreaker />
          <CheckBoxField
            label={"Immediately show banner (This will ignore the release date)"}
            value={bannerDetails.isActive}
            handleChange={handleChange("isActive")}
          />
          <LineBreaker />
          <Button
            sx={{ backgroundColor: "#7367f0" }}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
      </Container>
    </React.Fragment>
  );
};
