import React, { useEffect, useState } from "react";
import Playlistimg from "../../../Asset/images/playlist/playlist_imge.png";
import Featureimg from "../../../Asset/images/features/features-thumbnail-1.png";
import { Link, useNavigate } from "react-router-dom";
import getTimeElapsedWithFormat from "../../../hook/useTimeElapsed";
import dayjs from "dayjs";
import getVideoDuration from "../../../hook/useVideoDuration";
import videoTime from "../../../hook/useVideoTime";
import {
  useDeletePersonalPlayListMutation,
  usePersonalPlayListMutation,
  usePersonalWishlistMutation,
  useSaveWishlistMutation,
  useSubmitPersonalPlayListMutation,
  useUnSaveWishlistMutation,
} from "../../../service";
import { getPersonalPlayList } from "../../../redux/personalPlayListSlice";
import { useDispatch, useSelector } from "react-redux";
import ReadMoreLess from "../../common/ReadMoreLess";
import toast from "react-hot-toast";
import allVideoTime from "../../../hook/useAllVideoTime";
import checkApiStatus from "../../SubscripStatus";
import { List, MenuItem } from "@mui/material";
import DeleteModal from "./DeleteModal";
import MobileViewSearch from "../../MobileViewSearch";
import DeleteModalPlayList from "./DeleteModalPlayList";
import { AllPlayList } from "../../common/AllPlayList";
import Dropdown from 'react-bootstrap/Dropdown';
import { PlaylistEditModal } from "./PlaylistEditModal";
import api from "../../../data/api";
import { userInfoAtom } from "../../../data/atoms/utilsAtoms";
import { useAtomValue } from "jotai";
import videoApi from "../../../api/videoApi";
import { FcLike } from "react-icons/fc";
import { IoMdHeartEmpty } from "react-icons/io";


function PersonalPlayList() {
  const dispatch = useDispatch();
  const [videoStates, setVideoStates] = useState({});
  const [subscribtionStatus, setSubscribtionStatus] = useState(false);
  const [reqPersonalPlayList, resPersonalPlayList] =
    usePersonalPlayListMutation();
  const [reqPersonalDelete, resPersonalDelete] =
    useSubmitPersonalPlayListMutation();
  const [reqDelete, resDelete] = useDeletePersonalPlayListMutation();

  const [reqSaveWishlist] = useSaveWishlistMutation();
  const [reqUnSaveWishlist] = useUnSaveWishlistMutation();
  const [reqPersonalWishlist] = usePersonalWishlistMutation();


  const [showModal, setModalShow] = useState(false);
  const [nshowModal, setnModalShow] = useState(false);
  const [editPlaylistID, setEditPlaylistID] = useState('');
  const handleClose = () => {
    setModalShow(false);
    reqPersonalPlayList({ userId: userInfo?.userId });
  };

  const indexFromUrl = window.location.hash.substring(1);
  const [index, setIndex] = useState(0);

  const PPL = useSelector(
    (state) => state.personalPlayListState.personalPlayList
  );
  const data = PPL;
  const userInfo = useSelector((state) => state.authState.userInfo);

  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [modal, setModal] = useState(false);
  const [playlistmodal, setPlaylistmodal] = useState(false);
  const [updatePlayList, setUpdatePlayList] = useState(null);
  const [playListId, setPlayListId] = useState(null);


  const userData = useAtomValue(userInfoAtom)
  useEffect(() => {
    reqPersonalPlayList({ token: userData?.token });
  }, []);

  useEffect(() => {
    const getSubscription = async () => {
      const subscribtionStatus = await checkApiStatus(userData.token);
      setSubscribtionStatus(subscribtionStatus);
      //console.log('subscribtionStatus',subscribtionStatus)
    };
    getSubscription();
  }, []);

  useEffect(() => {
    if (
      resPersonalPlayList?.isSuccess &&
      resPersonalPlayList?.data?.existingVideos
    ) {
      dispatch(getPersonalPlayList(resPersonalPlayList?.data?.existingVideos));
      if (
        resPersonalPlayList?.data?.existingVideos &&
        Array.isArray(resPersonalPlayList?.data?.existingVideos) &&
        resPersonalPlayList?.data?.existingVideos?.length > 0
      ) {
        const recommended = resPersonalPlayList?.data?.existingVideos
          ?.map((el) => el?.videos)
          ?.flat();
        if (
          recommended &&
          Array.isArray(recommended) &&
          recommended?.length > 0
        ) {
          const filteredRecommended = recommended.filter(
            (obj) => obj !== undefined && obj !== null
          );
          // console.log("filteredRecommended", filteredRecommended);
          setRecommendedVideos(filteredRecommended);
        }
      }
    } else {
      dispatch(getPersonalPlayList([]));
      setRecommendedVideos([]);
    }
  }, [resPersonalPlayList]);


  const onEdit = (e, dt) => {
    e.preventDefault();
    setModalShow(!showModal);
    setEditPlaylistID(dt?._id);
  };

  const onDelete = (e, dt) => {
    e.preventDefault();
    setPlayListId(dt?._id);
    setPlaylistmodal(true);
  };

  const onConfirmActionPlaylist = () => {
    if (playListId) {
      reqDelete(playListId);
    }
    // window.location.hash = "0";
  };
  const onCancelActionPlaylist = () => {
    setPlayListId(null);
    setPlaylistmodal(false);
  };


  useEffect(() => {
    if (resDelete?.isSuccess) {
      reqPersonalPlayList({ userId: userInfo?.userId });
      setPlaylistmodal(false);
      toast.success("Playlist deleted Successfully", {
        position: "top-center",
        duration: 3000,
      });
    }
    if (resDelete?.isError) {
      setPlaylistmodal(false);
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [resDelete]);
  useEffect(() => {
    const closeMenuOnClickOutside = (e) => {
      // Check if the clicked element is inside the menu or its trigger
      if (!e.target.closest(".outer-delete")) {
        // If not, close all menus
        setVideoStates({});
      }
    };

    document.addEventListener("click", closeMenuOnClickOutside);

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener("click", closeMenuOnClickOutside);
    };
  }, []);

  const shuffleArray = (array) => {
    const newArray = [...array]; // Create a new array to avoid modifying the original
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const handleShuffle = (e, id) => {
    e.preventDefault();
    if (
      resPersonalPlayList?.data?.existingVideos &&
      Array.isArray(resPersonalPlayList?.data?.existingVideos) &&
      resPersonalPlayList?.data?.existingVideos?.length > 0
    ) {
      const shuffledArrayOfObjects =
        resPersonalPlayList?.data?.existingVideos?.map((obj) => {
          return {
            ...obj,
            videos: obj?._id === id ? shuffleArray(obj?.videos) : obj?.videos,
          };
        });
      if (
        shuffledArrayOfObjects &&
        Array.isArray(shuffledArrayOfObjects) &&
        shuffledArrayOfObjects?.length > 0
      ) {
        dispatch(getPersonalPlayList(shuffledArrayOfObjects));
      }
    }
  };

  const handleDeleteVideo = (e, fld, vd) => {
    // console.log("eeeeeeee", fld, vd);
    const res = fld?.videos?.filter((el) => el?._id !== vd?._id);
    // console.log("resresresresres", res);
    const payload = {
      ...fld,
      videos: res || [],
    };
    setUpdatePlayList(payload);
    setModal(true);
  };

  const onConfirmAction = () => {
    if (updatePlayList) {
      reqPersonalDelete({
        createdAt: updatePlayList?.createdAt,
        description: updatePlayList?.description,
        name: updatePlayList?.name,
        videos: updatePlayList?.videos,
        id: updatePlayList?._id,
        userId: userInfo?.userId,
      });
    }
  };

  const onCancelAction = () => {
    setUpdatePlayList(null);
    setModal(false);
  };

  const [savedVideos, setSavedVideos] = useState([])
  const [refreshSavedVideos, setRefreshSavedVideos] = useState(null)
  useEffect(() => {
    if (userInfo) {
      videoApi.getAllSavedVideoIds(userInfo.userId)
      .then((res) => {
        setSavedVideos(res.data.savedVideos)
      })
    }
  }, [userInfo, refreshSavedVideos])

  const handleSaveUnsave = async (id, video) => {
    const isLiked =
      video.videoDetails?.savedvideo ||
      video.savedvideo || (savedVideos?.length > 0 && savedVideos?.includes(video?.videoDetails?._id || video?._id)) || savedVideos?.includes(video?._id);

    if (isLiked) {
      try {
        const response = await api.unsaveWishlist(id, userData.token);
        if (response.status === 200) {
          toast.success("Unsaved", {
            position: "top-center",
            duration: 3000,
          });
          setRefreshSavedVideos(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      try {
        const response = await api.saveWishlist(id, userData.token);
        if (response.status === 200) {
          toast.success("Saved", {
            position: "top-center",
            duration: 3000,
          });
          setRefreshSavedVideos(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };


  useEffect(() => {
    if (resPersonalDelete?.isSuccess) {
      reqPersonalPlayList({ userId: userInfo?.userId });
      setModal(false);
      toast.success("Deleted successfully", {
        position: "top-center",
        duration: 3000,
      });
    }
    if (resPersonalDelete?.isError) {
      setModal(false);
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [resPersonalDelete]);

  const [wishListArr, setWishListArr] = useState([]);

  useEffect(() => {
    if (
      resPersonalPlayList?.isSuccess &&
      resPersonalPlayList?.data?.existingVideos &&
      Array.isArray(resPersonalPlayList?.data?.existingVideos) &&
      resPersonalPlayList?.data?.existingVideos?.length > 0
    ) {
      const recommended = resPersonalPlayList?.data?.existingVideos?.map((el) =>
        el?.videos?.map((el2) => ({
          object_id: el?._id,
          video_id: el2?._id,
          wishlist: el2?.wishlist,
        }))
      );
      if (
        recommended?.flat()?.filter((el) => el !== undefined) &&
        Array.isArray(recommended?.flat()?.filter((el) => el !== undefined)) &&
        recommended?.flat()?.filter((el) => el !== undefined)?.length > 0
      ) {
        setWishListArr(recommended?.flat()?.filter((el) => el !== undefined));
      }
    }
  }, [resPersonalPlayList?.isSuccess]);

  const fld = PPL[index];

  useEffect(() => {
    if (!showModal) {
      reqPersonalPlayList({ userId: userInfo?.userId });
    }
  }, [showModal, nshowModal]);


  return (
    <>
      {/* ///////////////////////////////////////////////Mobile */}
      <div className="row m-0 c-mobile_honm-serch update">
        <MobileViewSearch />
      </div>

      {/* /////////////////////////// */}
      <div>
        <div className="tab-content">
          <div id="menu2" className="tab-pane fade active show">
            {/* <div className="c-tab_cata-text update justify-content-end ">
              <h4 className="fw-bold">Personal</h4>
            </div> */}

            <AllPlayList
              title="PERSONAL PLAYLISTS"
              data={data}
              nshowModal={nshowModal}
              setnModalShow={setnModalShow}
              page="Personal"
              currentIndex={index}
              setCurrentIndex={setIndex}
            />
            <PlaylistEditModal showModal={showModal} setModalShow={setModalShow} handleClose={handleClose} editPlaylistID={editPlaylistID} setnModalShow={setnModalShow} nshowModal={nshowModal} />

            <div className="c-video c-video_feature-box update updated__play__card__list " style={{ backgroundColor: 'var(--dark)' }}>
              {fld ?
                <div className="row m-0 mt-2 list_id_pt_top position-relative " key={index}>
                  <div className=" position-absolute start-0 w-100 " style={{ top: '-100px' }} id={index}></div>
                  <div className="col-md-12 p-0">
                    <div className="c-featured_box-right">
                      <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
                        <p className="playlist_name col mb-0">{fld.name}</p>
                        <div className="c-featured_box-left-b4 m-0 d-inline-flex align-items-center">
                          <Link
                            to=""
                            onClick={(e) => handleShuffle(e, fld?._id)}
                            className="d-inline-flex outline__primary__btn shuffle"
                          >
                            <span>
                              <i className="fa-solid fa-shuffle"></i>
                            </span>
                            <span className="d-none d-lg-inline-block m-0">Shuffle</span>
                          </Link>
                          <Dropdown>
                            <Dropdown.Toggle className="text-white p-1 drop__btn" id="dropdown-basic">
                              <i class="fa-solid fa-ellipsis-vertical"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="drop__menu">
                              <button className="btn w-100 border-0" onClick={(e) => onEdit(e, fld)}>Edit</button>
                              <button className="btn w-100 border-0" onClick={(e) => onDelete(e, fld)}>Delete</button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      {/* ====== 1 ======== */}
                      <div className="row g-sm-2 g-1">
                        {fld?.videos &&
                          Array.isArray(fld?.videos) &&
                          fld?.videos?.length > 0 &&
                          fld?.videos?.map((vd, vinx) => {
                            return (
                              <div
                                className="col-6 col-sm-6 col-md-4 col-lg-3 "
                                key={vinx}
                              >
                                <div className="c-featured_box-rightd1 c-featured_box-rightd1nth">
                                  <div className="thumbnail__box position-relative ">
                                    <Link to={`/personal-playlist-view/${fld?._id}/${vd?._id}`}>
                                      {" "}
                                      {/* Add Link to Player Page */}
                                      <div className="c-featured_box-rightd2">
                                        <div className="c-featured_box-rightd22">
                                          <img
                                            src={vd?.thumnailLink}
                                            alt=""
                                          />
                                          <span>
                                            {vd?.duration
                                              ? videoTime(vd?.duration)
                                              : ""}
                                          </span>
                                        </div>
                                      </div>
                                    </Link>

                                    <div className="c-featured_box-right-d1">
                                      <ul className="m-0 d-flex flex-column has__trash" style={{ gap: '0.35rem' }}>
                                        <li className="mb-0">
                                          <button
                                            type="button"
                                            onClick={(e) =>
                                              handleDeleteVideo(
                                                e,
                                                fld,
                                                vd
                                              )
                                            }
                                            className="trash__btn btn p-0"
                                          >
                                            <i className="fa-regular fa-trash-can"></i>
                                          </button>
                                        </li>
                                        <li className="mb-0">
                                        <span
                                              id={vd?._id}
                                              className="c-featured_box-right-ha c-video_time"
                                              onClick={(e) => {
                                                // handleWishList(e, fld, vd)
                                                const id = e.currentTarget.id
                                                handleSaveUnsave(id, vd)
                                              }}
                                            >
                                            {
                                                savedVideos?.length > 0 && savedVideos.includes(vd?._id) ? (
                                                  <FcLike />
                                                ) : (
                                                  <IoMdHeartEmpty />
                                                )
                                              }
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                {/* c-featured */}

                                <div className="caption c-mobile_box-fea-la position-relative">


                                  <div className="c-featured_box-right-b1">
                                    <Link
                                      to={`/PlayerPage?v=${vd?._id}`}
                                    >
                                      <h4>{vd?.title}</h4>
                                    </Link>
                                    <div className="c-video_tag">
                                      {vd?.tagsData &&
                                        Array.isArray(vd?.tagsData) &&
                                        vd?.tagsData?.length > 0 && (
                                          <ul>
                                            {vd?.tagsData?.map(
                                              (tg, tinx) => {
                                                return (
                                                  <li
                                                    style={{
                                                      background:
                                                        tg?.color,
                                                    }}
                                                    key={tinx}
                                                  >
                                                    {tg?.name}
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        )}
                                    </div>
                                    {/* <div className="c-video_textsp">
                                      <span>
                                        {vd?.releaseDateTime
                                          ? getTimeElapsedWithFormat(
                                            dayjs(
                                              vd?.releaseDateTime
                                            ).toDate()
                                          )
                                          : ""}
                                      </span>
                                    </div> */}
                                  </div>

                                </div>

                              </div>
                            );
                          })}

                      </div>

                      {/* ====== 1 ======== */}
                    </div>
                  </div>
                </div>
                : "Create your own Personal Playlists now!"}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        modal={modal}
        onConfirmAction={onConfirmAction}
        onCancelAction={onCancelAction}
      />
      <DeleteModalPlayList
        modal={playlistmodal}
        onConfirmAction={onConfirmActionPlaylist}
        onCancelAction={onCancelActionPlaylist}
      />
    </>
  );
}

export default PersonalPlayList;
