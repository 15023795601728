import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      // Add any common headers here
    },
})

const playlistApi = {
    saveNewPlaylist: async (data) => {
        try {
            const response = await instance.post('/personalvideo/save', data)
            return response.data
        } catch (error) {
            console.error(error)
        }
    }

}

export default playlistApi