import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import XButton from "../XButton/XButton";

const EditSubUserModal = ({ open, handleClose, userData, onSave }) => {
  const [editData, setEditData] = useState({
    firstname: userData.firstname || "",
    lastname: userData.lastname || "",
    email: userData.email || "",
    subuserId: userData._id || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    const updatedUser = { ...userData, ...editData }; // Add subuser._id automatically
    onSave(updatedUser); // Pass updated object with _id
  };

  return (
    
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            p: 6,
            backgroundColor: "#27272a",
            margin: "auto",
            mt: "20vh",
            width: 600,
            borderRadius: "10px",
            position: "relative",
            color: "white",
          }}
        >
          {/* Close (X) Button */}
          <div style={{ position: "absolute", top: 8, right: 0, zIndex: 10 }}>
            <XButton onClick={handleClose} />
          </div>

          {/* Firstname and Lastname on the same line */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="First Name"
              name="firstname"
              value={editData.firstname}
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{ style: { color: 'white' } }}
              sx={{
                "& .MuiInputBase-input": { color: "white" }, // Text color
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "gray" },
                  "&:hover fieldset": { borderColor: "white" },
                  "&.Mui-focused fieldset": { borderColor: "white" },
                },
              }}
            />
            <TextField
              label="Last Name"
              name="lastname"
              value={editData.lastname}
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{ style: { color: 'white' } }}
              sx={{
                "& .MuiInputBase-input": { color: "white" }, // Text color
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "gray" },
                  "&:hover fieldset": { borderColor: "white" },
                  "&.Mui-focused fieldset": { borderColor: "white" },
                },
              }}
            />
          </Box>

          {/* Email Field on the next line */}
          <TextField
            label="Email"
            name="email"
            value={editData.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{
              "& .MuiInputBase-input": { color: "white" }, // Text color
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "gray" },
                "&:hover fieldset": { borderColor: "white" },
                "&.Mui-focused fieldset": { borderColor: "white" },
              },
            }}
          />

          {/* Confirm Button */}
          <button
            className="c-form_my-btn"
            onClick={handleSaveClick}
           
          >
            Confirm
          </button>
        </Box>
      </Modal>
   
  );
};

export default EditSubUserModal;
