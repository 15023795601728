import React from "react";
import { useLocation } from "react-router-dom";
import "../../Asset/Video_section.css";
import { TimeGap } from "../../utils/formatDateTime";

function Video_title({
  title,
  selectedTags,
  tags,
  releaseDate,
  onClick,
  selectTagCallback,
}) {
  const { pathname } = useLocation();

  let inLibraryView = true;
  if (
    pathname.indexOf("Lessons") === -1 &&
    pathname.indexOf("Exercises") === -1 &&
    pathname.indexOf("AcrossTheFloor") === -1 &&
    pathname.indexOf("AllVideos") === -1
  ) {
    inLibraryView = false;
  }

  const dateTime = releaseDate ? new Date(releaseDate) : new Date();
  const formattedReleaseDate = TimeGap(dateTime);

  return (
    <div className="c-video_texth">
      <h4 onClick={onClick}>{title}</h4>
      <div className="c-video_tag">
        <ul>
          {tags.map((tag, index) => (
            (tag.name || tag.color) &&
            <li
              key={index}
              className="c-video_tag-entry"
              style={{
                background: tag.color,
                borderColor: selectedTags?.includes(tag._id)
                  ? "white"
                  : "transparent",
              }}
              onClick={() => {
                if (selectTagCallback) {
                  selectTagCallback(tag);
                }
              }}
            >
              {!inLibraryView ? (
                <a href={`/AllVideos?tag=${tag._id}`}>{tag.name}</a>
              ) : (
                tag.name
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* <div className="c-video_textsp">
        <span>{formattedReleaseDate}</span>
      </div> */}
    </div>
  );
}

export default Video_title;
