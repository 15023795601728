import React, { useEffect, useState } from "react";
import "../styles/Playlist.scss";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FeaturedPlayListForm from "../playlist/featured-playlist/FeaturedPlayListForm";
import PersonalPlayListForm from "../playlist/personal-playlist/PersonalPlayListForm";
import allVideoTime from "../../hook/useAllVideoTime";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";

export function AllPlayList({
  title = "",
  data,
  nshowModal,
  setnModalShow,
  page = "",
  currentIndex, 
  setCurrentIndex
}) {
  const [show, setShow] = useState(false);
  const [playlist_type, setPlayListType] = useState("");
  const indexFromUrl = window.location.hash.substring(1);
  // const Currentindex = parseInt(indexFromUrl) || 0;
  // const [currentindex, setCurrentIndex] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const path = location.pathname;
  console.log("data", data);
  useEffect(() => {
    if (path === "/personal-playlist") {
      setPlayListType("personal-playlist");
    } else if (path === "/featured-playlist") {
      setPlayListType("featured-playlist");
    } else {
      setPlayListType("all");
    }
  }, []);

  const userInfo = useSelector((state) => state.authState.userInfo);
  const navigate = useNavigate()

  const playAll = (item) => {
    if (item.type === "FPL" || path === "/featured-playlist") {
      navigate(`/featured-playlist-view/${item._id}/${item.videos[0]?._id}`);
    } else if (item.type === "PPL" || path === "/personal-playlist") {
      navigate(`/personal-playlist-view/${item._id}/${item.videos[0]?._id}`);
    }
  }

  return (
    <>
      <div
        className={`c-tab_cata-text update ${
          playlist_type === "featured-playlist" || userInfo?.role === "admin"
            ? "remove__mt"
            : ""
        }`}
      >
        <div className="c-featured_box-left-b4">
          {playlist_type === "featured-playlist" &&
          userInfo?.role === "admin" ? (
            <Link
              onClick={handleShow}
              href="#"
              className="outline__primary__btn"
            >
              + <span className="d-none d-sm-inline">add new</span>{" "}
            </Link>
          ) : (
            ""
          )}
          {playlist_type === "personal-playlist" && (
            <Link
              onClick={handleShow}
              href="#"
              className="outline__primary__btn"
            >
              + <span className="d-none d-sm-inline">add new</span>{" "}
            </Link>
          )}
          {userInfo && playlist_type === "all" && (
            <Link
              onClick={handleShow}
              href="#"
              className="outline__primary__btn"
            >
              + <span className="d-none d-sm-inline">add new</span>{" "}
            </Link>
          )}
        </div>
        {/* <h4>PLAYLISTS</h4> */}
        <h4>{page}</h4>
      </div>

      <div className="c-video_feature-box update scroller">
        <div className=" c-video_textcol p-0 title__box">
          <h4 className="d-none d-lg-block text-blue">
            {path === "/all-playlist" ? (
              <span className={""}>ALL Playlists</span>
            ) : (
              <Link
                to="/all-playlist"
                className={title !== "" ? "text-71717A" : ""}
              >
                ALL Playlists
              </Link>
            )}
            {/* <Link href="/all-playlist" className={title != '' ? 'text-71717A' : ''}>ALL Playlists</Link> */}
            {title !== "" && <span className="text-71717A"> &gt; </span>}
            {title !== "" && <span>{title}</span>}
          </h4>
          <h4 className="d-sm-none">Playlists</h4>
        </div>

        <div
          className="overflow-x-auto pb-1"
          style={{ scrollbarWidth: "thin" }}
        >
          <div className="d-flex gap-1 gap-sm-2 grid__row">
            {data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className="d-flex playlist__item"
                  onClick={() => setCurrentIndex(index)}
                >
                  <div
                    className={`playlist__card w-100 ${
                      index === currentIndex ? "active" : null
                    }`}
                  >
                    <div className="d-flex d-sm-block mb-1 mb-sm-0 res__info__box">
                      <div className="thumb__box position-relative">
                        <img
                          src="https://i.vimeocdn.com/video/1741403504-f2f5df20c07178f421da06e163239ea1a5465bb2b077ed021ab231a91af82050-d"
                          alt="Video Thumbnail"
                          className="img-fluid thumbnail"
                        />
                        <img
                          src="https://i.vimeocdn.com/video/1741403504-f2f5df20c07178f421da06e163239ea1a5465bb2b077ed021ab231a91af82050-d"
                          alt="Video Thumbnail"
                          className="img-fluid thumbnail"
                        />
                        <img
                          src="https://i.vimeocdn.com/video/1741403504-f2f5df20c07178f421da06e163239ea1a5465bb2b077ed021ab231a91af82050-d"
                          alt="Video Thumbnail"
                          className="img-fluid thumbnail"
                        />
                        <img
                          src="https://i.vimeocdn.com/video/1741403504-f2f5df20c07178f421da06e163239ea1a5465bb2b077ed021ab231a91af82050-d"
                          alt="Video Thumbnail"
                          className="img-fluid thumbnail"
                        />
                      </div>
                      {/* <span className="duration">{item.duration}</span> */}
                      <div className="info__height">
                        <span className="duration d-none d-sm-block">
                          {item?.videos &&
                          Array.isArray(item?.videos) &&
                          item?.videos?.length > 0
                            ? allVideoTime(
                                item?.videos
                                  .map((video) => parseInt(video?.duration))
                                  .filter((duration) => !isNaN(duration))
                                  .reduce((acc, duration) => acc + duration, 0)
                              )
                            : ""}
                        </span>
                        <h2 className="title">{item.name}</h2>
                        {item.type === "PPL" && (
                          <p className="list_name mb-0">Personal Playlist</p>
                        )}
                        {item.type === "FPL" && (
                          <p className="list_name mb-0">Featured Playlist</p>
                        )}
                        {!item.type && (
                          <p className="list_name mb-0">{item.description}</p>
                        )}
                        <p className="count">{item.videos?.length} Videos</p>
                      </div>
                    </div>
                    <div className="text-center d-flex d-sm-block justify-content-between align-items-center ">
                      <span className="duration d-sm-none m-0">
                        {item?.videos &&
                        Array.isArray(item?.videos) &&
                        item?.videos?.length > 0
                          ? allVideoTime(
                              item?.videos
                                .map((video) => parseInt(video?.duration))
                                .filter((duration) => !isNaN(duration))
                                .reduce((acc, duration) => acc + duration, 0)
                            )
                          : ""}
                      </span>
                      <button
                        className="outline__primary__btn text-uppercase d-inline-flex align-items-center rounded-3"
                        onClick={() => playAll(item)}
                      >
                        <span>Play All</span>
                        <i className="fa fa-play"></i>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
            {playlist_type === "featured-playlist" &&
            userInfo?.role === "admin" ? (
              <div className="d-flex playlist__item">
                <div
                  onClick={handleShow}
                  className="add__card w-100 d-flex justify-content-center align-items-center"
                >
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            ) : (
              ""
            )}
            {playlist_type === "personal-playlist" && (
              <div className="d-flex playlist__item">
                <div
                  onClick={handleShow}
                  className="add__card w-100 d-flex justify-content-center align-items-center"
                >
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            )}
            {userInfo && playlist_type === "all" && (
              <div className="d-flex playlist__item">
                <div
                  onClick={handleShow}
                  className="add__card w-100 d-flex justify-content-center align-items-center"
                >
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            )}
            {/* <div className="d-flex" style={{ width: "223px", minWidth: '223px' }}>
                            <div onClick={handleShow} className="add__card w-100 d-flex justify-content-center align-items-center">
                                <i className="fa fa-plus"></i>
                            </div>
                        </div> */}
          </div>
          {/* row */}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="add__modal">
        <Modal.Header closeButton>
          <Modal.Title>Add New Playlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {playlist_type === "personal-playlist" && (
            <PersonalPlayListForm
              show={show}
              setShow={setShow}
              nshowModal={nshowModal}
              setnModalShow={setnModalShow}
            />
          )}
          {playlist_type === "featured-playlist" && (
            <FeaturedPlayListForm show={show} setShow={setShow} />
          )}
          {
            // playlist_type === 'all' && (

            //     <form className="d-flex flex-column gap-2">
            //         <div>
            //             <input type="text"
            //                 className="form-control"
            //                 placeholder="Playlist Name"
            //                 required
            //             />
            //         </div>
            //         <div>
            //             <textarea name="" id="" cols="30" rows="4"
            //                 className="form-control h-auto"
            //                 placeholder="Description"
            //             ></textarea>
            //         </div>

            //         <div className="d-flex action__btns mt-4 justify-content-end gap-1">
            //             <button className="btn px-4">reset</button>
            //             <button className="btn btn-primary px-4">Add</button>
            //         </div>
            //     </form>
            // )
            playlist_type === "all" && (
              <PersonalPlayListForm
                show={show}
                setShow={setShow}
                nshowModal={nshowModal}
                setnModalShow={setnModalShow}
              />
            )
          }
        </Modal.Body>
      </Modal>
    </>
  );
}
