import React, { useState } from "react";
import { Navbar } from "reactstrap";
import Logo from "../../../Asset/images/logo-blaxk2.png";
import { List, MenuItem } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Cookiesj from "universal-cookie";
import { setUserInfo } from "../../../redux/authSlice";
import { useDispatch } from "react-redux";
export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookiesj();
  const dispatch = useDispatch();

  const t = () => {
    setIsOpen(true);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    cookies.remove("user");
    localStorage.removeItem("userData");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(setUserInfo(null));
    navigate("/login");
  };

  return (
    <Navbar
      expand="lg"
      container={false}
      className="header-navbar navbar align-items-center floating-nav container-xxl navbar-shadow navbar navbar-expand-lg navbar-light"
    >
      <div className="navbar-container d-flex content">
        <div className="bookmark-wrapper d-flex align-items-center">
          <div className="bookmark-wrapper d-flex align-items-center"></div>
        </div>
        <ul className="nav navbar-nav align-items-center ms-auto">
          <li className="dropdown-user nav-item dropdown">
            <a
              aria-haspopup="true"
              class="nav-link dropdown-user-link"
              aria-expanded="false"
            >
              <div className="user-nav d-sm-flex d-none">
                <span className="user-name fw-bold text-capitalize">
                  The Godfrey Method
                </span>
                <span className="user-status">Admin</span>
              </div>
              <div classNames="avatar">
                <img
                  className=""
                  src={Logo}
                  alt="avatarImg"
                  height="40"
                  width="40"
                  onClick={t}
                />
                {isOpen && (
                  <List
                    className="d-flex flex-column"
                    style={{
                      fill: "#ffff",
                      cursor: "pointer",
                      background: "#fff",
                      position: "absolute",
                      top: "70px",
                    }}
                  >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </List>
                )}
                <span className="avatar-status-online"></span>
              </div>
            </a>
            <div
              tabindex="-1"
              role="menu"
              aria-hidden="true"
              className="dropdown-menu dropdown-menu-end"
            >
              <button
                type="button"
                tabindex="0"
                role="menuitem"
                className="dropdown-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="me-75"
                >
                  <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                  <line x1="12" y1="2" x2="12" y2="12"></line>
                </svg>
                <span className="align-middle">Logout</span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </Navbar>
  );
}
