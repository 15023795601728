import React from 'react'
import { Modal } from 'react-bootstrap'
import FeaturedPlayListForm from './FeaturedPlayListForm'

export function FeaturedPlaylistEditModal({ setModalShow, showModal, handleClose, editPlaylistID }) {



    return (
        <>
            <Modal show={showModal} onHide={handleClose} className="add__modal">
                <Modal.Header closeButton>
                    {editPlaylistID ? <Modal.Title>Edit Playlist</Modal.Title> : <Modal.Title>Add New Playlist</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <FeaturedPlayListForm show={showModal} setShow={setModalShow} editPlaylistID={editPlaylistID} />
                </Modal.Body>
            </Modal>
        </>
    )
}
