import { Button } from "@mui/material";
import React, { useRef } from "react";

export const ImageUploader = ({ label = "Upload Image", value, handleImageChange }) => {
  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file);
    
    // Reset file input value after handling image change
    fileInputRef.current.value = '';
  };

  return (
    <React.Fragment>
      {/* Display the image if value is a URL */}
      {typeof value === "string" && value.startsWith("http") && (
        <img src={value} alt="banner" width="90%" height="90%" />
      )}

      {/* Display the uploaded file as an image */}
      {value && typeof value === "object" && value instanceof File && (
        <img src={URL.createObjectURL(value)} alt="banner" width="90%" height="90%" />
      )}

      <div style={{ display: "flex", margin: "1rem" }}>
        <br />
        <strong style={{ margin: "0.5rem 0.5rem 0 0" }}>{label} :</strong>
        <Button
          sx={{ backgroundColor: "#7367f0" }}
          component="label"
          role="button"
          variant="contained"
          tabIndex={-1}
        >
          {label}
          <input ref={fileInputRef} type="file" hidden onChange={handleChange} />
        </Button>
      </div>
    </React.Fragment>
  );
};
