import React from 'react'

export default function Footer() {
  return (
    <footer style={{marginLeft:'260px'}} class="white footer footer-light footer-static">
      <p class="clearfix mb-0">
        <span class="float-md-start d-block d-md-inline-block mt-25">
          COPYRIGHT © 2023{" "}
          <a
            href="https://1.envato.market/pixinvent_portfolio"
            target="_blank"
            rel="noopener noreferrer"
          >
            THE GOD FREY
          </a>
          <span class="d-none d-sm-inline-block">, All rights Reserved</span>
        </span>
      </p>
    </footer>
  );
}
