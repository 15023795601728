import React, { useEffect, useState } from "react";
import Playlistimg from "../../../Asset/images/playlist/playlist_imge.png";
import Featureimg from "../../../Asset/images/features/features-thumbnail-1.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteFeaturedPlayListMutation,
  useFeaturedPlayListQuery,
  useFeaturedWishlistMutation,
  usePersonalWishlistMutation,
  useSaveWishlistMutation,
  useUnSaveWishlistMutation,
} from "../../../service";
import { getFeaturedPlayList } from "../../../redux/featuredPlayListSlice";
import getTimeElapsedWithFormat from "../../../hook/useTimeElapsed";
import dayjs from "dayjs";
import getVideoDuration from "../../../hook/useVideoDuration";
import videoTime from "../../../hook/useVideoTime";
import ReadMoreLess from "../../common/ReadMoreLess";
import toast from "react-hot-toast";
import allVideoTime from "../../../hook/useAllVideoTime";
import checkApiStatus from "../../SubscripStatus";
import { async } from "@vimeo/player";
import MobileViewSearch from "../../MobileViewSearch";
import { AllPlayList } from "../../common/AllPlayList";
import Dropdown from "react-bootstrap/Dropdown";
import { PlaylistEditModal } from "../personal-playlist/PlaylistEditModal";
import { FeaturedPlaylistEditModal } from "./FeatureEditModal";
import videoApi from "../../../api/videoApi";
import { FcLike } from "react-icons/fc";
import { IoMdHeartEmpty } from "react-icons/io";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../../data/atoms/utilsAtoms";
import api from "../../../data/api";

function FeaturedPlayList() {
  const userInfo = useSelector((state) => state.authState.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useAtomValue(userInfoAtom)
  const resFeaturedPlayList = useFeaturedPlayListQuery({token: userData.token});
  const [subscribtionStatus, setSubscribtionStatus] = useState(false);
  const [reqDelete, resDelete] = useDeleteFeaturedPlayListMutation();
  console.log("resFeaturedPlayList", resFeaturedPlayList);

  const [reqSaveWishlist] = useSaveWishlistMutation();
  const [reqUnSaveWishlist] = useUnSaveWishlistMutation();
  const [reqFeaturedWishlist] = useFeaturedWishlistMutation();
  const [editPlaylistID, setEditPlaylistID] = useState('');
  const [showModal, setModalShow] = useState(false);

  const indexFromUrl = window.location.hash.substring(1);
  const [index, setIndex] = useState(0);
  console.log("object", index)

  const FPL = useSelector(
    (state) => state.featuredPlayListState.featuredPlayList
  );
  console.log("FPL", FPL);
  const data = FPL;
  const handleClose = () => {
    setModalShow(false);
    resFeaturedPlayList.refetch({ userId: userInfo?.userId });
  };
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  console.log("recommendedVideos", recommendedVideos);
  
  useEffect(() => {
    const getSubscription = async () => {
      const subscribtionStatus = await checkApiStatus(userData.token);
      setSubscribtionStatus(subscribtionStatus);
      //console.log('subscribtionStatus',subscribtionStatus)
    };
    getSubscription();
  }, []);

  
  const [savedVideos, setSavedVideos] = useState([])
  const [refreshSavedVideos, setRefreshSavedVideos] = useState(null)
  useEffect(() => {
    if (userInfo) {
      videoApi.getAllSavedVideoIds(userInfo.userId)
      .then((res) => {
        setSavedVideos(res.data.savedVideos)
      })
    }
  }, [userInfo, refreshSavedVideos])
  
  const handleSaveUnsave = async (id, video) => {
    const isLiked =
      video.videoDetails?.savedvideo ||
      video.savedvideo || (savedVideos?.length > 0 && savedVideos?.includes(video?.videoDetails?._id || video?._id)) || savedVideos?.includes(video?._id);

    if (isLiked) {
      try {
        const response = await api.unsaveWishlist(id, userData.token);
        if (response.status === 200) {
          toast.success("Unsaved", {
            position: "top-center",
            duration: 3000,
          });
          setRefreshSavedVideos(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      try {
        const response = await api.saveWishlist(id, userData.token);
        if (response.status === 200) {
          toast.success("Saved", {
            position: "top-center",
            duration: 3000,
          });
          setRefreshSavedVideos(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  useEffect(() => {
    if (
      resFeaturedPlayList?.isSuccess &&
      resFeaturedPlayList?.data?.existingVideos
    ) {
      console.log("---------------\nasdasdasdasdasd-\nnnnnnnnnnnn");
      dispatch(getFeaturedPlayList(resFeaturedPlayList?.data?.existingVideos));
      if (
        resFeaturedPlayList?.data?.existingVideos &&
        Array.isArray(resFeaturedPlayList?.data?.existingVideos) &&
        resFeaturedPlayList?.data?.existingVideos?.length > 0
      ) {
        const recommended = resFeaturedPlayList?.data?.existingVideos
          ?.map((el) => el?.videos)
          ?.flat();
        if (
          recommended &&
          Array.isArray(recommended) &&
          recommended?.length > 0
        ) {
          const filteredRecommended = recommended.filter(
            (obj) => obj !== undefined && obj !== null
          );
          console.log("filteredRecommended", filteredRecommended);
          setRecommendedVideos(filteredRecommended);
        }
      }
    } else {
      dispatch(getFeaturedPlayList([]));
      setRecommendedVideos([]);
    }
  }, [resFeaturedPlayList]);

  const onEdit = (e, dt) => {
    e.preventDefault();
    // navigate("/featured-playlist-form", {
    //   state: {
    //     playId: dt?._id,
    //     isEdit: true,
    //   },
    // });
    setModalShow(!showModal);
    setEditPlaylistID(dt?._id);
  };

  const onDelete = (e, dt) => {
    e.preventDefault();
    reqDelete(dt?._id);
  };

  useEffect(() => {
    if (resDelete?.isSuccess) {
      toast.success("Playlist deleted Successfully", {
        position: "top-center",
        duration: 3000,
      });
      // dispatch(getFeaturedPlayList(resFeaturedPlayList?.data?.existingVideos))
    }
  }, [resDelete]);

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const handleShuffle = (e, id) => {
    e.preventDefault();
    if (
      resFeaturedPlayList?.data?.existingVideos &&
      Array.isArray(resFeaturedPlayList?.data?.existingVideos) &&
      resFeaturedPlayList?.data?.existingVideos?.length > 0
    ) {
      const shuffledArrayOfObjects =
        resFeaturedPlayList?.data?.existingVideos?.map((obj) => {
          return {
            ...obj,
            videos: obj?._id === id ? shuffleArray(obj?.videos) : obj?.videos,
          };
        });
      if (
        shuffledArrayOfObjects &&
        Array.isArray(shuffledArrayOfObjects) &&
        shuffledArrayOfObjects?.length > 0
      ) {
        dispatch(getFeaturedPlayList(shuffledArrayOfObjects));
      }
    }
  };

  const [wishListArr, setWishListArr] = useState([]);
  console.log("wishListArr", wishListArr);

  useEffect(() => {
    if (
      resFeaturedPlayList?.isSuccess &&
      resFeaturedPlayList?.data?.existingVideos &&
      Array.isArray(resFeaturedPlayList?.data?.existingVideos) &&
      resFeaturedPlayList?.data?.existingVideos?.length > 0
    ) {
      const recommended = resFeaturedPlayList?.data?.existingVideos?.map((el) =>
        el?.videos?.map((el2) => ({
          object_id: el?._id,
          video_id: el2?._id,
          wishlist: el2?.wishlist,
        }))
      );
      if (
        recommended?.flat()?.filter((el) => el !== undefined) &&
        Array.isArray(recommended?.flat()?.filter((el) => el !== undefined)) &&
        recommended?.flat()?.filter((el) => el !== undefined)?.length > 0
      ) {
        setWishListArr(recommended?.flat()?.filter((el) => el !== undefined));
      }
    }
  }, [resFeaturedPlayList?.isSuccess]);

  const handleWishList = (e, fld, vd) => {
    e.preventDefault();
    console.log("eeeeeeee", fld, vd);
    if (
      wishListArr?.some(
        (el) =>
          el?.object_id === fld?._id &&
          el?.video_id === vd?._id &&
          !el?.wishlist
      )
    ) {
      reqSaveWishlist({ id: vd?._id });
      reqFeaturedWishlist({
        id: fld?._id,
        wishlistId: vd?._id,
        wishlistFlag: true,
      });
    } else {
      reqUnSaveWishlist({ id: vd?._id });
      reqFeaturedWishlist({
        id: fld?._id,
        wishlistId: vd?._id,
        wishlistFlag: false,
      });
    }
    if (
      wishListArr?.some(
        (el) => el?.object_id === fld?._id && el?.video_id === vd?._id
      )
    ) {
      const wishListFilter = wishListArr?.map((el) =>
        el?.object_id === fld?._id && el?.video_id === vd?._id
          ? { ...el, wishlist: el?.wishlist ? false : true }
          : { ...el }
      );
      setWishListArr(wishListFilter);
    }
  };

  // console.log({ FPL });

  const fld = FPL && FPL[index];

  const linkBuilder = (fld, vd) => {
    if (subscribtionStatus) {
      return `/featured-playlist-view/${fld?._id}/${vd?._id}`;
    } 
    return '/plans'
  }

  return (
    <>
      {/* ///////////////////////////////////////////////Mobile */}
      <div className="row m-0 update c-mobile_honm-serch">
        <MobileViewSearch />
      </div>

      {/* /////////////////////////// */}
      <div>
        <div className="tab-content">
          <div id="menu2" className="tab-pane fade active show">
            {/* <div className="c-tab_cata-text update">
              <div className="c-featured_box-left-b4">
                <Link to="#" className="outline__primary__btn">+ add new </Link>
              </div>
              <h4>PLAYLISTS</h4>
            </div> */}

            {/* All Playlist Block */}
            <AllPlayList
              title="FEATURED PLAYLISTS"
              data={data}
              page="Featured"
              currentIndex={index}
              setCurrentIndex={setIndex}
            />
            {/* All Playlist Bloock */}
            <FeaturedPlaylistEditModal showModal={showModal} setModalShow={setModalShow} handleClose={handleClose} editPlaylistID={editPlaylistID} />

            <div className="c-video c-video_feature-box update updated__play__card__list" style={{ backgroundColor: 'var(--dark)' }}>

              {/* ////////////////////////////////// */}
              {fld ?
                <div className="row m-0 mt-2 position-relative " key={index}>
                  <div className=" position-absolute start-0 w-100 " style={{ top: '-100px' }} id={index}></div>
                  <div className="col-md-5 p-0 d-none ">
                    <div className="c-featured_box-left">
                      <p className="playlist_name">{fld.name}</p>
                      <div className="c-loptop_no-box">
                        <div className="row m-0 c-video_textrow c-mobile-card-he">
                          <div className="col-md-8 c-video_textcol p-0">
                            <h4>FEATURED PLAYLIST</h4>
                          </div>
                        </div>
                        <div className="c-featured_box-left-b3">
                          <ul>
                            <li>
                              Created{" "}
                              {getTimeElapsedWithFormat(
                                dayjs(fld?.createdAt).toDate()
                              )}
                            </li>
                            <li>
                              <span>
                                <i className="fa-solid fa-circle"></i>
                              </span>{" "}
                              1 week ago
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="c-featured_box-left-b1 c-mobile-box-lef-b1">
                        {/* <a href="#!">5hr 40mins</a> */}
                        <Link>
                          {fld?.videos &&
                            Array.isArray(fld?.videos) &&
                            fld?.videos?.length > 1
                            ? allVideoTime(
                              fld?.videos
                                .map((video) =>
                                  parseInt(video?.duration)
                                )
                                .filter((duration) => !isNaN(duration))
                                .reduce(
                                  (acc, duration) => acc + duration,
                                  0
                                )
                            )
                            : ""}

                          {fld?.videos &&
                            Array.isArray(fld?.videos) &&
                            fld?.videos?.length === 1
                            ? allVideoTime(
                              fld?.videos[0]?.duration
                                ? fld?.videos[0]?.duration
                                : ""
                            )
                            : ""}
                        </Link>
                      </div>
                      <div className="c-featured_box-left-b2">
                        <img src={fld?.videos[0]?.thumnailLink} alt="" />
                      </div>
                      <div className="c-featured_box-left-b3 c-mobile-box-lef-b3">
                        <ul>
                          <li>
                            Created{" "}
                            {getTimeElapsedWithFormat(
                              dayjs(fld?.createdAt).toDate()
                            )}
                          </li>
                          {fld?.updatedAt && (
                            <li>
                              <span>
                                <i className="fa-solid fa-circle"></i>
                              </span>
                              Updated{" "}
                              {getTimeElapsedWithFormat(
                                dayjs(fld?.updatedAt).toDate()
                              )}
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="c-featured_box-left-b4">
                        {(userData.token &&
                          subscribtionStatus) ||
                          userInfo?.role === "admin" ? (
                          <Link
                            to={`/featured-playlist-view/${fld?._id}`}
                          >
                            <span>
                              <i className="fa-solid fa-play"></i>
                            </span>
                            PLAY ALL
                          </Link>
                        ) : (
                          <Link to={`/plans`}>
                            <span>
                              <i className="fa-solid fa-play"></i>
                            </span>
                            PLAY ALL
                          </Link>
                        )}
                        <Link
                          to=""
                          onClick={(e) => handleShuffle(e, fld?._id)}
                        >
                          <span>
                            <i className="fa-solid fa-shuffle"></i>
                          </span>
                          Shuffle
                        </Link>
                      </div>
                      <div className="c-featured_box-left-b5">
                        <h5>Description</h5>
                        <ReadMoreLess text={fld?.description || ""} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="c-featured_box-right">
                      <div className="d-flex justify-content-between align-items-center gap-2 mb-2">
                        <p className="playlist_name col mb-0">{fld.name}</p>
                        <div className="c-featured_box-left-b4 m-0 d-inline-flex items-center-center">
                          <Link
                            to=""
                            onClick={(e) => handleShuffle(e, fld?._id)}
                            className="d-inline-flex outline__primary__btn shuffle"
                          >
                            <span>
                              <i className="fa-solid fa-shuffle"></i>
                            </span>
                            <span className="d-none d-lg-inline-block m-0">Shuffle</span>
                          </Link>
                          {userInfo?.role === "admin" &&
                            <Dropdown>
                              <Dropdown.Toggle className="text-white p-1 drop__btn" id="dropdown-basic">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="drop__menu">
                                <button className="btn w-100 border-0" onClick={(e) => onEdit(e, fld)}>Edit</button>
                                <button className="btn w-100 border-0" onClick={(e) => onDelete(e, fld)}>Delete</button>
                              </Dropdown.Menu>
                            </Dropdown>
                          }
                        </div>
                      </div>
                      {/* ====== 1 ======== */}
                      <div className="row g-sm-2 g-1">
                        {fld?.videos &&
                          Array.isArray(fld?.videos) &&
                          fld?.videos?.length > 0 &&
                          fld?.videos?.map((vd, vinx) => {
                            return (
                              <div
                                className="col-6 col-sm-6 col-md-4 col-lg-3"
                                key={vinx}
                              >
                                <div className=" c-featured_box-rightd1 c-featured_box-rightd1nth">
                                  <div className="thumbnail__box position-relative ">
                                    <Link to={`${linkBuilder(fld, vd)}`}>
                                      {" "}
                                      {/* Add Link to Player Page */}
                                      <div className="c-featured_box-rightd2">
                                        <div className="c-featured_box-rightd22">
                                          <img
                                            src={vd?.thumnailLink}
                                            alt=""
                                          />
                                          <span>
                                            {vd?.duration
                                              ? videoTime(vd?.duration)
                                              : ""}
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                    <div className="c-featured_box-right-d1">
                                      <ul className="m-0 d-flex flex-column" style={{ gap: '0.35rem' }}>
                                        {/* <li className="mb-0">
                                              <button
                                                type="button"
                                                className="trash__btn btn p-0" 
                                              >
                                                <i className="fa-regular fa-trash-can"></i>
                                              </button>
                                            </li> */}
                                        {userInfo &&
                                          <li className="mb-0">
                                            <span
                                              id={vd?._id}
                                              className="c-featured_box-right-ha c-video_time"
                                              onClick={(e) => {
                                                // handleWishList(e, fld, vd)
                                                const id = e.currentTarget.id
                                                handleSaveUnsave(id, vd)
                                              }}
                                            >
                                              {/* {wishListArr?.some(
                                                (ws) =>
                                                  ws?.object_id ===
                                                  fld?._id &&
                                                  ws?.video_id ===
                                                  vd?._id &&
                                                  ws?.wishlist
                                              ) ? (
                                                <i className="fa-solid fa-heart"></i>
                                              ) : (
                                                <i className="fa-regular fa-heart"></i>
                                              )} */}
                                              {
                                                savedVideos?.length > 0 && savedVideos.includes(vd?._id) ? (
                                                  <FcLike />
                                                ) : (
                                                  <IoMdHeartEmpty />
                                                )
                                              }
                                            </span>
                                          </li>
                                        }
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="caption c-mobile_box-fea-la position-relative">
                                    <div className="c-featured_box-right-b1">
                                      <Link
                                        to={`/PlayerPage?v=${vd?._id}`}
                                      >
                                        <h4>{vd?.title}</h4>
                                      </Link>
                                      <div className="c-video_tag">
                                        {vd?.tagsData &&
                                          Array.isArray(vd?.tagsData) &&
                                          vd?.tagsData?.length > 0 && (
                                            <ul>
                                              {vd?.tagsData?.map(
                                                (tg, tinx) => {
                                                  return (
                                                    <li
                                                      style={{
                                                        backgroundColor:
                                                          tg?.color,
                                                      }}
                                                      key={tinx}
                                                    >
                                                      <a
                                                        href={`/AllVideos?tag=${tg?._id}`}
                                                      >
                                                        {tg?.name}
                                                      </a>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          )}
                                      </div>
                                      {/* <div className="c-video_textsp">
                                        <span>
                                          {vd?.releaseDateTime
                                            ? getTimeElapsedWithFormat(
                                              dayjs(
                                                vd?.releaseDateTime
                                              ).toDate()
                                            )
                                            : ""}
                                        </span>
                                      </div> */}
                                    </div>

                                  </div>
                                  {/* caption */}
                                </div>
                              </div>
                            );
                          })}

                      </div>
                      {/* row */}

                      {/* ====== 1 ======== */}
                    </div>


                    {/* 
                            ---- Backup Code Start --------
                            <div className="c-featured_box-right">
                          
                            {fld?.videos &&
                              Array.isArray(fld?.videos) &&
                              fld?.videos?.length > 0 &&
                              fld?.videos?.map((vd, vinx) => {
                                return (
                                  <div
                                    className="row m-0 c-featured_box-rightd1 c-featured_box-rightd1nth"
                                    key={vinx}
                                  >
                                    <div className="col-md-4 p-0">
                                      <Link to={`/PlayerPage?v=${vd?._id}`}>
                                        {" "} 
                                        <div className="c-featured_box-rightd2">
                                          <div className="c-featured_box-rightd22">
                                            <img
                                              src={vd?.thumnailLink}
                                              alt=""
                                            />
                                            <span>
                                              {vd?.duration
                                                ? videoTime(vd?.duration)
                                                : ""}
                                            </span>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="row m-0 p-0 c-mobile_box-fea-la">
                                        <div className="col-md-10">
                                          <div className="c-featured_box-right-b1">
                                            <Link
                                              to={`/PlayerPage?v=${vd?._id}`}
                                            >
                                              <h4>{vd?.title}</h4>
                                            </Link>
                                            <div className="c-video_tag">
                                              {vd?.tagsData &&
                                                Array.isArray(vd?.tagsData) &&
                                                vd?.tagsData?.length > 0 && (
                                                  <ul>
                                                    {vd?.tagsData?.map(
                                                      (tg, tinx) => {
                                                        return (
                                                          <li
                                                            style={{
                                                              backgroundColor:
                                                                tg?.color,
                                                            }}
                                                            key={tinx}
                                                          >
                                                            <a
                                                              href={`/AllVideos?tag=${tg?._id}`}
                                                            >
                                                              {tg?.name}
                                                            </a>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                )}
                                            </div>
                                            <div className="c-video_textsp">
                                              <span>
                                                {vd?.created_time
                                                  ? getTimeElapsedWithFormat(
                                                      dayjs(
                                                        vd?.created_time
                                                      ).toDate()
                                                    )
                                                  : ""}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-2">
                                          <div className="c-featured_box-right-d1">
                                            <ul>
                                              <li>
                                                <Link
                                                  className="c-featured_box-right-ha"
                                                  to=""
                                                  onClick={(e) =>
                                                    handleWishList(e, fld, vd)
                                                  }
                                                >
                                                  {wishListArr?.some(
                                                    (ws) =>
                                                      ws?.object_id ===
                                                        fld?._id &&
                                                      ws?.video_id ===
                                                        vd?._id &&
                                                      ws?.wishlist
                                                  ) ? (
                                                    <i className="fa-solid fa-heart"></i>
                                                  ) : (
                                                    <i className="fa-regular fa-heart"></i>
                                                  )}
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
 
                          </div>
                              
                          
                          */}



                  </div>
                </div>
                : "No Featured playlist exist"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturedPlayList;
