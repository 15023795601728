import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import api from "../../../data/api";
import Loader from "../../../component/LoderComponent";
import Header from "../components/Header";
import XButton from "../../../component/XButton/XButton";
import { useAuthService } from "../../../hook/useAuthService";

import ShowIcon from '../../../Asset/images/show-regular-24.png'
import HideIcon from '../../../Asset/images/hide-regular-24.png'

export default function NewLogin() {
  const {loggedInUser} = useAuthService();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    errorMessage: "",
    isLoading: false,
  });

  const handleLogoClick = () => {
    navigate("/");
  };

  const redirectToSignUp = () => {
    navigate("/SignUp");
  };

  const redirectToForgotPassword = () => {
    navigate("/forgot");
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: "",
      errorMessage: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    setFormData({ ...formData, isLoading: true });

    if (!email || !password) {
      setFormData({
        ...formData,
        emailError: email ? "" : "Please fill in your email",
        passwordError: password ? "" : "Please Enter your password",
        isLoading: false,
      });
      return;
    }
    try {
      const response = await api.loginApi(email, password);
      console.log("login data",response.data)
      setFormData({ ...formData, isLoading: false });
      loggedInUser(response?.data);
    } catch (error) {
      if (error) {
        setFormData({
          ...formData,
          errorMessage: error.response.data.message,
        });
      }
    }
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };


  const {
    email,
    password,
    emailError,
    passwordError,
    errorMessage,
    isLoading,
  } = formData;

  return (
    <>
      <Header onClick={handleLogoClick} />
      <section className="c-sing_form">
        <div className="container">
          <div>
            <div className="c-sing_heading">
              <h2>LogIn</h2>
            </div>
            <div className="c-sing_form-box">
              <div>
                <form className="inner-container">
                  <XButton onClick={() => navigate("/")} />
                  <div style={{ height: "40px" }} />
                  <LoginForm
                    email={email}
                    password={password}
                    emailError={emailError}
                    passwordError={passwordError}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    onForgotPassword={redirectToForgotPassword}
                    errorMessage={errorMessage}
                    isPasswordVisible={isPasswordVisible}
                    togglePasswordVisibility={togglePasswordVisibility}
                    eyeIcon={isPasswordVisible ? ShowIcon : HideIcon}
                  />
                  <div className="col-md-12 form-group">
                    <p>
                      Don't have an account?{" "}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={redirectToSignUp}
                      >
                        Sign Up
                      </div>{" "}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLoading && <Loader loading />}
    </>
  );
}
