import {Button, Container, Grid,Typography} from "@mui/material";
import React from "react";
import { LineBreaker } from "../../atomicComponent/AtomicComponents";
import { useFetchAllBanners } from "../../data/swrs/banners";
import LandscapeCard from "../../atomicComponent/LandscapeCard/LandscapeCard";
import { useNavigate } from "react-router-dom";
import { deleteBannerApi, markAsActiveBannerApi } from "../../data/api/banner";
import { handleErrorResponse } from "../../utils/handleErrorResponse";
import { useFormSubmit } from "../../hook/useFormSubmit";

const Banners = () => {
  const { data: banners, error, mutate } = useFetchAllBanners();
  const navigate = useNavigate();

  const handleEditBanner = (id) => {
    navigate(`/AdminDashboard/edit-banner/${id}`);
  };

  const handleAddBanner = () => {
    navigate("/AdminDashboard/add-banner");
  };

  const handleDeleteBanner = useFormSubmit(async(id, token) => {
    const response = await deleteBannerApi(id, token)
    if(handleErrorResponse(response)) return
    mutate();
  });

  const handleMarkAsActiveBanner = useFormSubmit(async({id, status}, token) => {
    const response = await markAsActiveBannerApi(id, !status, token)
    if(handleErrorResponse(response)) return
    mutate();
  });

  const renderButtons = (banner) => {
    return [
      {
        title: "Edit",
        onClick: () => handleEditBanner(banner._id),
        backgroundColor: "#9b92f4",
      },
      {
        title: "Delete",
        onClick: () => handleDeleteBanner(banner._id),
        backgroundColor: "#ff0000",
      },
      {
        title: banner?.isActive ? "Unpublish Banner" : "Publish Banner",
        onClick: () => handleMarkAsActiveBanner({id:banner._id, status:banner.isActive}),
        backgroundColor: "#5ebaff",
      },
    ];
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h4">Banners List</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="outlined" onClick={() => handleAddBanner()}>
            Add Banner
          </Button>
        </Grid>
      </Grid>
      <LineBreaker />
      {error && <p>Error fetching banners: {error.message}</p>}
      {banners &&
        banners.data.map((banner) => (
          <React.Fragment key={banner.id}>
            <LandscapeCard
              image={banner.image}
              buttons={renderButtons(banner)}
              infoElement={renderInfoElement(banner)}
            />
            <LineBreaker />
          </React.Fragment>
        ))}
    </Container>
  );
};

const renderInfoElement = (banner) => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      <h2>{banner.title}</h2>
      <p>Release Date: {formatDate(banner.releaseDate)}</p>
      {banner.isActive && <p style={{ color: "green" }}>Active Banner</p>}
    </>
  );
};

export default Banners;
