import { Button, Card } from '@mui/material';
import React from 'react'

const LandscapeCard = ({ image, buttons= [], infoElement }) => {
    return (
        <Card style={{ width: "100%" }}>
          <div className="video-item list-Video">
            {image && (
              <div className="video-thumbnail">
                <img
                  className="w-10 h-10"
                  style={{ width: "100%", height: "100%" }}
                  src={image}
                  alt="banner"
                />
              </div>
            )}
            <div className="video-details">
              {infoElement}
              <div className="video-actions">
                {buttons.map((button, index) => (
                  <Button
                    key={index}
                    size="small"
                    style={{
                      margin: "3px",
                      textTransform: "uppercase",
                      backgroundColor: button.backgroundColor,
                      fontSize: "12px",
                      padding: "8px 20px",
                    }}
                    onClick={button.onClick}
                  >
                    {button.title}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </Card>
      );
}

export default LandscapeCard