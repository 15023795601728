import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      // Add any common headers here
    },
});

const videoApi = {
    // --------------Saved video api----------------
    getAllSavedVideoIds: (userId) => instance.post(`/saved/videos`, {userId}),

}

export default videoApi