import React, { useEffect } from "react";
import "./index.css";
import App from "./App";
import "./Asset/Font-family/GoodTimesRg-Regular.ttf";
import "./Asset/Font-family/GoodTimesRg-Regular.woff";
import "./Asset/Font-family/GoodTimesRg-Regular.eot";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Savedvideos from "./component/SavedVideo/Savedvideos";
import ForgotPassword from "./component/ForgetPassword/ForgotPassword";
import RestPassword from "./component/ForgetPassword/RestPassword";
import Across from "./component/Librarry/Across";
import Exercise from "./component/Librarry/Exercise";
import Lesson from "./component/Librarry/Lesson";
import PlanPage from "./component/PlanPage";
import StudioPlanPage from "./component/StudioPlanPage";
import VideoSection from "./component/VideoSection";
import NewSignUp from "./component/NewSignUp";
import StudioSignUp from "./component/StudioSignUp";
import History from "./component/History";
import Library from "./component/Librarry/LibraryMain";
import PlayerPage from "../src/component/Video_player/PlayerPage";
import AllVideos from "./AdminComponent/AllVideos";
import AdminDashboard from "./Layouts/AdminLayout/AdminDashboard";
import AddCategory from "./AdminComponent/AddCategory";
import AddVideos from "./AdminComponent/AddVideos";
import TagManagement from "./AdminComponent/TagManagement";
import AllUsers from "./AdminComponent/AllUsers";
import "./App.css";
import FeaturedPlayList from "./component/playlist/featured-playlist/FeaturedPlayList";
import PersonalPlayList from "./component/playlist/personal-playlist/PersonalPlayList";
import FeaturedPlayListForm from "./component/playlist/featured-playlist/FeaturedPlayListForm";
import PersonalPlayListForm from "./component/playlist/personal-playlist/PersonalPlayListForm";
import FeturedPlayListView from "./component/playlist/featured-playlist/FeturedPlayListView";
import PersonalPlayListView from "./component/playlist/personal-playlist/PersonalPlayListView";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import AdminFeaturedListForm from "./component/admin/playlist/AdminFeaturedListForm";
import AdminFeaturedList from "./component/admin/playlist/AdminFeaturedList";
import SearchPageList from "./component/searchPage/SearchPageList";
import MyAccount from "./component/MyAccount";
import AdminModeration from "./component/AdminModeration/AdminModeration";
import AdminNotification from "./component/AdminNotification/AdminNotification";
import AllUserVideos from "./component/AllUserVideos";
import AllPlaylistsPage from "./component/playlist/AllPlaylistsPage";
import SetSubUserPassword from "./component/SetSubUserPassword";
import ScrollToTop from "./ScrollToTop";
import NewLogin from "./Pages/auth/Login/NewLogin";
import { Banners, EditBanners, AddBanners } from "./Pages/Banners";
import Loader from "./component/LoderComponent";
import { useAtomValue } from "jotai";
import { loaderState, userInfoAtom } from "./data/atoms/utilsAtoms";

function RootApp() {
  const userData = useAtomValue(userInfoAtom);
  const isApplicationInLoadingState = useAtomValue(loaderState);

  useEffect(() => {
    if (userData.user) {
      console.log(userData.user);
      const { firstname, lastname, email } = userData.user;
      console.log(firstname, lastname, email, window);
      window.Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
        publishableKey: process.env.REACT_APP_CHARGEBEE_API_KEY,
        enableRefersionTracking: true,
      });

      window.Chargebee.registerAgain();
      const testInstance = window.Chargebee.getInstance();
      const cart = testInstance.getCart();
      console.log(cart);

      const customerDetails = {
        email: email ? email.toLowerCase() : "",
        first_name: firstname,
        last_name: lastname,
      };

      const fpTid = window.getFPTid();
      if (fpTid) {
        customerDetails.cf_tid = fpTid;
      }

      cart.setCustomer(customerDetails);
    }
  }, [userData.user, userData.token]);

  return (
    <Provider store={store}>
      {isApplicationInLoadingState && <Loader />}
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/AdminDashboard/*" element={<AdminDashboard />}>
              <Route path="AddCategory" element={<AddCategory />} />
              <Route path="AllVideos" element={<AllVideos />} />
              <Route path="AddVideos" element={<AddVideos />} />
              <Route path="AllUsers" element={<AllUsers />} />
              <Route path="TagManagement" element={<TagManagement />} />
              <Route path="featured-playlist" element={<AdminFeaturedList />} />
              <Route
                path="featured-playlist-form"
                element={<AdminFeaturedListForm />}
              />
              <Route path="moderation" element={<AdminModeration />} />
              <Route path="notification" element={<AdminNotification />} />

              {/* Banners */}
              <Route path="banners" element={<Banners />} />
              <Route path="add-banner" element={<AddBanners />} />
              <Route path="edit-banner/:id" element={<EditBanners />} />
            </Route>

            <Route path="/Login" element={<NewLogin />} />
            <Route path="/SignUp" element={<NewSignUp />} />
            <Route path="/StudioSignUp" element={<StudioSignUp />} />

            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/*" element={<App />}>
              <Route path="home" element={<VideoSection />} />
              <Route path="Library" element={<Library />} />

              <Route path="studioPlans" element={<StudioPlanPage />} />

              <Route path="plans" element={<PlanPage />} />
              <Route path="plan" element={<PlanPage />} />

              <Route path="AcrossTheFloor" element={<Across />} />
              <Route path="Exercises" element={<Exercise />} />
              <Route path="Lessons" element={<Lesson />} />
              <Route path="AllVideos" element={<AllUserVideos />} />
              <Route path="saved" element={<Savedvideos />} />
              <Route path="History" element={<History />} />
              <Route path="account" element={<MyAccount />} />
              <Route path="PlayerPage" element={<PlayerPage />} />
              <Route path="forgot" element={<ForgotPassword />} />
              <Route
                path="reset_password/:id/:token"
                element={<RestPassword />}
              />
              <Route 
                  path ="set-password/:id"
                  element = {<SetSubUserPassword />}
              />
              <Route path="result" element={<SearchPageList />} />
              <Route path="featured-playlist" element={<FeaturedPlayList />} />
              <Route path="all-playlist" element={<AllPlaylistsPage />} />
              <Route path="personal-playlist" element={<PersonalPlayList />} />
              <Route
                path="featured-playlist-form"
                element={<FeaturedPlayListForm />}
              />
              <Route
                path="personal-playlist-form"
                element={<PersonalPlayListForm />}
              />
              <Route
                path="featured-playlist-view/:playlist/:id"
                element={<FeturedPlayListView />}
              />
              <Route
                path="personal-playlist-view/:playlist/:id"
                element={<PersonalPlayListView />}
              />
            </Route>
          </Routes>
        </ScrollToTop>
      </Router>
    </Provider>
  );
}

export default RootApp;
