import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
} from "@mui/material";
import Button1 from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Spinner from "../component/Spinner";
import toast from "react-hot-toast";

export default function AllUsers() {
  axios.defaults.withCredentials = true;

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [totusers, setTotUsers] = useState("");

  const [users, setUsers] = useState([]);
  const redirect = useNavigate();
  const [editingUser, setEditingUser] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedUserData, setEditedUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [timeoutId, setTimeoutId] = useState(0);
  const [goFetchData, setGoFetchData] = useState(true);
  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  const fetchLock = useRef(false);
  useEffect(() => {
    if (goFetchData && fetchLock.current === false) {
      fetchLock.current = true;
      setGoFetchData(false);

      showLoader();
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      // Fetch user details from the API when the component mounts
      axios
        .post(`${process.env.REACT_APP_API_URL}/user/fetch`, {
          startIndex,
          endIndex,
          searchQuery,
        })
        .then((response) => {
          if (response.status === 200) {
            const timer = setTimeout(() => {
              hideLoader();
              setUsers(response.data.records);
              setTotUsers(Math.ceil(response.data.total / itemsPerPage));
              fetchLock.current = false;
            }, 500);
            setTimeoutId(timer);
          } else if (response.status === 201) {
            toast.error("Please Login Again", {
              position: "top-center",
              duration: 3000,
            });
            redirect("/login");
          }
        })
        .catch((error) => {
          toast.error("Somethng went wrong", {
            position: "top-center",
            duration: 3000,
          });
          console.error("Error deleting user:", error);
          fetchLock.current = false;
        });
    }
  }, [currentPage, searchQuery, goFetchData, fetchLock]);

  //Y Function to handle page change in pagination
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setGoFetchData(true);
  };

  const formatDateString = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTimeString = (dateString) => {
    const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
    return new Date(dateString).toLocaleTimeString("en-GB", options);
  };

  const handleDeleteClick = (userId) => {
    setDeleteUserId(userId);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = () => {
    if (deleteUserId) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/user/delete/${deleteUserId}`)
        .then((response) => {
          if (response.status === 200) {
            // console.log("response.result._id",response.data.deletedData)
            setUsers(
              users.filter((user) => user._id !== response.data.deletedData._id)
            );
            console.log("User deleted successfully.");
            setDeleteUserId(null);
            setOpenDialog(false);
          } else if (response.status === 201) {
            toast.error("Please Login Again", {
              position: "top-center",
              duration: 3000,
            });
            redirect("/login");
          }
        })
        .catch((error) => {
          toast.error("Somethng went wrong", {
            position: "top-center",
            duration: 3000,
          });
          console.error("Error deleting user:", error);
        });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteUserId(null);
    setOpenDialog(false);
  };

  const handleEditClick = (user) => {
    setEditingUser(user);
    setEditedUserData({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
    });
    setOpenEditDialog(true);
  };

  const handleEditCancel = () => {
    setEditingUser(null);
    setOpenEditDialog(false);
  };

  // Function to handle user updates
  const handleUpdateUser = (userId) => {
    // Make sure editedUserData is not empty
    if (
      !editedUserData ||
      !editedUserData.firstname ||
      !editedUserData.lastname ||
      !editedUserData.email
    ) {
      console.error("Incomplete user data for update.");
      return;
    }
    axios
      .put(`${process.env.REACT_APP_API_URL}/user/update/${userId}`, editedUserData)
      .then((response) => {
        if (response.status === 200) {
          console.log("User updated successfully.");

          console.log("res data->", response.data);
          // Fetch the updated user list after a successful update
          axios
            .get(`${process.env.REACT_APP_API_URL}/user/fetch`)
            .then((response) => {
              const slicedData = response.data.records.slice(
                (currentPage - 1) * itemsPerPage,
                (currentPage - 1) * itemsPerPage + itemsPerPage
              );
              setUsers(slicedData);
              console.log(response.data.records);
              setTotUsers(
                Math.ceil(response.data.records.length / itemsPerPage)
              );
            })
            .catch((error) => {
              console.error("Error fetching user details:", error);
            });
        } else if (response.status === 201) {
          toast.error("Please Login Again", {
            position: "top-center",
            duration: 3000,
          });
          redirect("/login");
        }
      })
      .catch((error) => {
        toast.error("Somethng went wrong", {
          position: "top-center",
          duration: 3000,
        });
        console.error("Error updating user:", error);
      });
  };

  const handleEditSave = () => {
    console.log("User data to be saved:", editedUserData);
    if (editingUser) {
      handleUpdateUser(editingUser.userId);
    }
    setEditingUser(null);
    setOpenEditDialog(false);
  };

  return (
    <div className="white container">
      <h2 className="mb-3">All Users</h2>
      <div className="search-box-container">
        <div className="col-md-8">
          <div className="search-box-container">
            <input
              type="text"
              id="search"
              placeholder="Search"
              className="search-input"
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setCurrentPage(1);
                  setGoFetchData(true);
                }
              }}
            />
            <Button1
              variant="primary"
              onClick={() => {
                setCurrentPage(1);
                setGoFetchData(true);
              }}
            >
              Search
            </Button1>
          </div>
        </div>
      </div>
      <div>{isLoading ? <Spinner /> : ""}</div>
      {!isLoading && Array.isArray(users) && users.length > 0 ? (
        <Card className="mb-3">
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Created Time</TableCell>
                  {/* Add more headers based on your data */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.userId}</TableCell>
                    <TableCell>{user.firstname}</TableCell>
                    <TableCell>{user.lastname}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{formatDateString(user.createdAt)}</TableCell>
                    <TableCell>{formatTimeString(user.createdAt)}</TableCell>
                    {/* Add more cells based on your data */}
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => handleEditClick(user)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteClick(user._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      ) : (
        ""
      )}
      {/* Material-UI Pagination */}
      {!isLoading && (
        <div
          style={{
            margin: "20px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totusers}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      )}
      <Dialog open={openDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button1 onClick={handleDeleteCancel}>Cancel</Button1>
          <Button1 onClick={handleDeleteConfirm} autoFocus>
            Confirm
          </Button1>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleEditCancel}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Add instructions or information about editing */}
          </DialogContentText>
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            value={editedUserData.firstname}
            onChange={(e) =>
              setEditedUserData({
                ...editedUserData,
                firstname: e.target.value,
              })
            }
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            value={editedUserData.lastname}
            onChange={(e) =>
              setEditedUserData({ ...editedUserData, lastname: e.target.value })
            }
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={editedUserData.email}
            onChange={(e) =>
              setEditedUserData({ ...editedUserData, email: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button1 onClick={handleEditCancel}>Cancel</Button1>
          <Button1 onClick={handleEditSave} autoFocus>
            Save
          </Button1>
        </DialogActions>
      </Dialog>
    </div>
  );
}
