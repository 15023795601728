import { useNavigate } from "react-router-dom"
import SearchIcon from "../../Asset/svg-icons/SearchIcon"

const SearchButton = ({searchKey}) => {
    const redirect = useNavigate()
    const goToSearchPage = () => {
        redirect(`/result?search_query=${encodeURIComponent(searchKey)}`)
    }
    return (
        <button id="search-button" aria-label="search button"
            onClick={() => goToSearchPage()}
          >
            <SearchIcon/>
          </button>
    )
}

export default SearchButton