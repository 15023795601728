import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export const CheckBoxField = ({ label, value, handleChange }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleCheckBoxChange = (e) => {
    setChecked((prevValue) => !prevValue);
    handleChange(e.target.checked);
  }
    return (
        <Form.Group>
        <Form.Check type={"checkbox"}>
          <Form.Check.Input
            type={"checkbox"}
            defaultChecked={checked}
            checked={checked}
            onClick={handleCheckBoxChange}
          />
          <Form.Check.Label>{label}</Form.Check.Label>
        </Form.Check>
      </Form.Group>
  );
};
