import axios from "axios";

const axiosMuiltipartConfig = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
axiosMuiltipartConfig.defaults.withCredentials = true;

export default axiosMuiltipartConfig