import axios from "axios";
import axiosConfig from "../utils/axiosConfigInstance";
import { authApis } from "./auth";
import { bannerApis } from "./banner";
import { fileUploadApis } from "./fileUpload";



const api = {

  //-----Auth api---------
  ...authApis,

  //-- File Upload Apis
  ...fileUploadApis,

  //--- Banner APis
  ...bannerApis,

  //-----Home page api------------
  getHome: () => axios.get(`${process.env.REACT_APP_API_URL}/home/video`),
  getHomeUsers: (token) => axios.get(`${process.env.REACT_APP_API_URL}/home/user/video`, { headers: { Authorization: `Bearer ${token}` } }),
  saveWishlist: (id, token) => axios.post(`${process.env.REACT_APP_API_URL}/save/Wishlist/video`, { id }, { headers: { Authorization: `Bearer ${token}` } }),
  unsaveWishlist: (id, token) => axios.post(`${process.env.REACT_APP_API_URL}/unsave/Wishlist/video`, { id }, { headers: { Authorization: `Bearer ${token}` } }),

  //---------Admin pannel api---------
  addCategory: (capitalizedCategory) =>
    axiosConfig.post(`${process.env.REACT_APP_API_URL}/add/category`, { capitalizedCategory }),
  getCategory: () => axiosConfig.get(`${process.env.REACT_APP_API_URL}/category`),
  searchTags: (query) =>
    axiosConfig.get(`${process.env.REACT_APP_API_URL}/search/tags`, { params: { query } }),
  searchProducts: (query) =>
    axiosConfig.get(`${process.env.REACT_APP_API_URL}/search/products`, { params: { query } }),
  searchVideo: (query, sort_by, endIndex, startIndex, userId) =>
    axiosConfig.get(`${process.env.REACT_APP_API_URL}/search/video`, {
      params: { query, sort_by, endIndex, startIndex, userId },
    }),
  videoPlayerPage: (query, token) =>
    axiosConfig.get(`${process.env.REACT_APP_API_URL}/playerpage/video`, { params: { query }, headers: { Authorization: `Bearer ${token}` }}),
  removeCategory: (categoryId) =>
    axiosConfig.delete(`/delete/category/${categoryId}`),
  getSubscriptioDetails: (userId) =>
    axiosConfig.get(`${process.env.REACT_APP_API_URL}/subscription/details`, { params: { userId } }),
  getPersonalInformation: (userId) =>
    axiosConfig.get(`${process.env.REACT_APP_API_URL}/personal/details`, { params: { userId } }),
  UpdatePersonalInformationName: (firstname, lastname, userId) =>
    axiosConfig.post(`${process.env.REACT_APP_API_URL}/update/personal/details/name`, {
      firstname,
      lastname,
      userId,
    }),
  UpdatePersonalInformationEmail: (email, userId) =>
    axiosConfig.post(`${process.env.REACT_APP_API_URL}/update/personal/details/email`, { email, userId }),
  // Example POST request
  createUser: (userData) => axiosConfig.post(`${process.env.REACT_APP_API_URL}/users`, userData),

  // Add more API endpoints as needed
  updateNotifications: ({ commentNotifications }) =>
    axiosConfig.post(`${process.env.REACT_APP_API_URL}/user/notifications`, { commentNotifications }),
};

export default api;
