import React, { useEffect, useState } from 'react'
import Logo from "../../../Asset/images/logo-blaxk.png";
import { useForm, Controller } from 'react-hook-form';
import { FormFeedback, Input } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useFeaturedPlayListByIdQuery, useGetVideosListQuery, useSubmitFeaturedPlayListMutation } from '../../../service';
import { getVideoDropDownList } from '../../../redux/videoSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';


function FeaturedPlayListForm({ show, setShow, editPlaylistID }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [reqFeatured, resFeatured] = useSubmitFeaturedPlayListMutation()
  const authToken = useSelector(state => state.authState.userToken)
  const videoDropdownList = useSelector(state => state.videoState.videoDropdownList)

  console.log('authToken', authToken);
  const [videoFiles, setVideoFiles] = useState([]);
  console.log('videoFiles', videoFiles);
  const resVideo = useGetVideosListQuery()
  // const resGetById = useFeaturedPlayListByIdQuery(location?.state?.playId, {
  //   skip: !location?.state?.playId,
  // }) 

  console.log("editPlaylistID", editPlaylistID)

  const resGetById = useFeaturedPlayListByIdQuery(editPlaylistID, {
    skip: !editPlaylistID,
  });

  console.log("resGetById", resGetById)
  console.log('resVideo', resVideo, videoDropdownList);
  console.log('resGetById', resGetById);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError
  } = useForm();


  useEffect(() => {
    if (resGetById?.isSuccess && resGetById?.data?.existingVideos) {
      reset({
        createdAt: resGetById?.data?.existingVideos?.createdAt,
        description: resGetById?.data?.existingVideos?.description,
        name: resGetById?.data?.existingVideos?.name,
        videos: resGetById?.data?.existingVideos?.videos,
        id: resGetById?.data?.existingVideos?._id,
      })
    }
  }, [resGetById?.isSuccess])

  useEffect(() => {
    if (resVideo?.isSuccess && resVideo?.data) {
      const res1 = resVideo?.data?.map(el => ({ ...el, label: el?.title, value: el?.title }))
      console.log('res1', res1);
      dispatch(getVideoDropDownList(res1))
    }
  }, [resVideo])

  // const handleFile = (e, name) => {
  //   console.log("eeeee",e.target.files, name);
  //   let attach = []
  //   if(e.target.files){
  //     for (let i = 0; i < e.target.files.length; i++) {
  //       attach = [...attach, e.target.files[i]]
  //       setValue(name,e.target.files[i])
  //       setError(name,'')
  //     }
  //   }
  //   console.log('attach',attach);
  //   setVideoFiles([...videoFiles,...attach])
  // };

  const onNext = (state) => {
    console.log('state', state);
    reqFeatured(state)
  }

  useEffect(() => {
    if (resFeatured?.isSuccess) {
      toast.success('Featured playlist save successfully', {
        position: "top-center",
        duration: 3000
      })

      setShow(!show);
      // setTimeout(() => {
      //   navigate('/featured-playlist')
      // }, 1000);
    }
    if (resFeatured?.isError) {
      toast.error('Something went wrong', {
        position: "top-center",
        duration: 3000
      })
    }
  }, [resFeatured])
  return (
    <>
      {/* <header className="c-sing-header">
        <div className="row m-0">
          <div className="col-md-12">
            <div className="c-sing_logo">
              <img src={Logo} alt="" srcSet="" />
            </div>
          </div>
        </div>
      </header> c-sing_form */}
      <section className="">
        <div className="container">
          <div>
            {/* <div class="c-sing_heading">
              <h2>Featured</h2>
            </div> */}
            <div className="c-sing_form-bo">
              <div>
                <form onSubmit={handleSubmit(onNext)}>
                  <div className="row g-2">
                    <div className="col-md-12 form-group">
                      <Controller
                        id="name"
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                          <Input
                            placeholder="Enter Name"
                            className="form-control name"
                            {...field}
                            type="text"
                          />
                        )}
                      />
                      {errors.name && (
                        <FormFeedback>
                          {errors?.name?.message}
                        </FormFeedback>
                      )}
                    </div>
                    <div className="col-md-12 form-group">
                      <Controller
                        id="description"
                        name="description"
                        control={control}
                        rules={{ required: "Description is required" }}
                        render={({ field }) => (
                          <textarea
                            placeholder="Enter description"
                            className="form-control h-auto"
                            {...field}
                          />
                        )}
                      />

                      {errors.description && (
                        <FormFeedback>
                          {errors?.description?.message}
                        </FormFeedback>
                      )}
                    </div>
                    <div className='col-md-12 form-group'>
                      <Controller
                        id="videos"
                        name="videos"
                        control={control}
                        rules={{ required: "Video is required" }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            isMulti
                            isClearable
                            options={videoDropdownList || []}
                            className="react-select"
                            classNamePrefix="select"
                            placeholder="Select a videos"
                            onChange={onChange}
                            value={value ? value : null}
                          />
                        )}
                      />
                      {errors.videos && (
                        <FormFeedback>
                          {errors?.videos?.message}
                        </FormFeedback>
                      )}
                    </div>


                    {/* <div className="col-md-12 form-group">
                    <div class="upload-btn-wrapper">
  <button class="upload_btn">Upload a video</button>
  <Controller
                              id="video_file"
                              name="video_file"
                              control={control}
                              rules={{ required: "Video file is required" }}
                              render={({ field: { onChange, value } }) => (
                                <Input
                                  multiple
                                  type='file'
                                  accept="video/*"
                                  className="form-control"
                                  onChange={(e) => {
                                      onChange(e.target.files);
                                      handleFile(e, "video_file");
                                    }}
                                />
                              )}
                            />
                      </div>
                      </div> */}



                    <div className="col-md-12 form-group text-end action__btns">
                      <button type="submit" className="btn btn-primary px-4 px-md-5">
                        Save
                      </button>
                      {/* {errMess && <p style={{ color: "red" }}>{errMess}</p>} */}
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FeaturedPlayListForm
