// CustomPagination.js

import React from 'react';

const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {
  const renderPageNumbers = () => {
    console.log(totalPages);
    console.log(currentPage);
    if (totalPages === 0) {
      return <div></div>; // Show a blank div when there are no pages
    }

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li key={i} className={i === currentPage ? 'show' : ''} onClick={() => onPageChange(i)}>
          {i}
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="c-video_pagesias">
      {currentPage > 1 ? (
        <div className="c-video_pagesias_btn">
          <button className="prev" onClick={() => onPageChange(currentPage - 1)}>
            <span>
              <i className="fa-solid fa-arrow-left"></i>
            </span>
            Previous
          </button>
        </div>
      ) : (
        <div className="c-video_pagesias_btn"></div>
      )}
      <div className="c-video_pagesi_list">
        <ul>
          {renderPageNumbers()}
        </ul>
      </div>  
      {currentPage < totalPages ? (
        <div className="c-video_pagesias_btn">
          <button className="next" onClick={() => onPageChange(currentPage + 1)}>
            Next
            <span>
              <i className="fa-solid fa-arrow-right"></i>
            </span>
          </button>
        </div>
      ) : (
        <div className="c-video_pagesias_btn">
        </div>
      )}
    </div>
  );
};

export default CustomPagination;
