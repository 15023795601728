import React, { useEffect, useState, useRef } from "react";
import { useAtomValue } from "jotai";
import {
  getAvailableSeats,
  getSubUsers,
  addSubUser,
  deleteSubUser,
  updateSubUser,
} from "../../api/studioOwnerApi";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import Loader from "../LoderComponent";
import toast from "react-hot-toast";
import { Delete, Edit, Person2Outlined } from "@mui/icons-material";
import ConfirmationModal from "../ConfirmationModal";
import EditSubUserModal from "../EditSubuserModal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const SubUserAdd = ({ subscriptionData,  setSeatsData }) => {
  const userData = useAtomValue(userInfoAtom);
  const [subUserData, setSubUserData] = useState([]);
  const [rowIndex, setRowIndex] = useState(null);
  const [activeInput, setActiveInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [availableSeats, setAvailaleSeats] = useState();
  const [newSubUserData, setNewSubUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const rowRef = useRef(null);
  const labels = ["Members", "", ""];

  const fetchData = async () => {
    try {
      const seatsResponse = await getAvailableSeats(userData?.token);
      const seats = seatsResponse.data.requiredSeats;

      const subUsersResponse = await getSubUsers(userData?.token);
      const existingSubUsers = subUsersResponse.data.subUsers;
      setAvailaleSeats(seats - existingSubUsers.length);
      setSeatsData(seats);

      const emptySubUsers = Array.from(
        { length: seats - existingSubUsers.length },
        () => ({
          firstname: "",
          lastname: "",
          email: "",
          createdAt: "",
        })
      );

      setSubUserData([...existingSubUsers, ...emptySubUsers]);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-center",
        duration: 3000,
      });
    }
  };

  const openEditModal = (user) => {
    setUserToEdit(user);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [userData?.token]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (rowRef.current && !rowRef.current.contains(event.target)) {
  //       setActiveInput(false);
  //       setRowIndex(null);
  //     }
  //   };

  //   if (activeInput) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [activeInput]);

  // const handleInputChange = (input, field, index) => {
  //   const updatedSubUserData = [...subUserData];
  //   updatedSubUserData[index][field] = input;
  //   updatedSubUserData[index].isEditing = true;
  //   setSubUserData(updatedSubUserData);
  //   setRowIndex(index);
  //   setActiveInput(true);
  // };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await addSubUser(userData?.token, newSubUserData);
      if (response.status === 200) {
        toast.success("User added successfully", {
          position: "top-center",
          duration: 3000,
        });
        
        fetchData();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-center",
        duration: 3000,
      });

      const updatedSubUserData = [...subUserData];

      setSubUserData(updatedSubUserData);
    } finally {
      setActiveInput(false);
      
      newSubUserData.firstname = "";
      newSubUserData.lastname = "";
      newSubUserData.email = "";
      setLoading(false);
    }
  };

  const handleEdit = async (updatedUserData) => {
    try {
      const response = await updateSubUser(userData?.token, updatedUserData);

      if (response.status === 200) {
        toast.success("User updated successfully", {
          position: "top-center",
          duration: 3000,
        });
        fetchData(); // Refresh the subuser data after editing
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to update user", {
        position: "top-center",
        duration: 3000,
      });
    } finally {
      setIsEditModalOpen(false); // Close the edit modal after saving
      setUserToEdit(null); // Clear the userToEdit state
    }
  };

  const handleDeleteClick = (data) => {
    setUserToDelete(data);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await deleteSubUser(userData?.token, userToDelete._id);
      if (response.status === 200) {
        toast.success("User Deleted Successfully", {
          position: "top-center",
          duration: 3000,
        });
        fetchData();
      }
      if (response.status === 201) {
        toast.error("Please Login Again", {
          position: "top-center",
          duration: 3000,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-center",
        duration: 3000,
      });
    } finally {
      setIsModalOpen(false);
      setUserToDelete(null);
    }
  };

  const formatName = (firstname, lastname) => {
    return firstname && lastname
      ? `${firstname} ${lastname}`
      : "Available Seat";
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  // console.log(subUserData);

  return (
    <div className="sub-user-add-table">
      {loading && <Loader/>}
      {subscriptionData?.subscriptionStatus === "active" ? (
        <>
        <div className="add_subuser_form">
            <div className="add_subuser_form_head">
              <div className="form_head">
                <h4>ASSIGN SEAT .</h4>
              </div>
              {availableSeats > 0 ? (
                <span className="available_seats">
                  {availableSeats} seats left
                </span>
              ) : (
                <span className="available_seats">No seats left</span>
              )}
            </div>
            <div className="add_subuser_form_container">
              <div className="add_subuser_form_body">
                <div className="c-my-acc_form_item_subuser">
                <input
                  value={newSubUserData.firstname}
                  onChange={(e) =>
                    setNewSubUserData({
                      ...newSubUserData,
                      firstname: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="First Name"
                  className="first_name"
                  disabled = {availableSeats <= 0}
                />
                </div>
                <div className="c-my-acc_form_item_subuser">
                <input
                  value={newSubUserData.lastname}
                  onChange={(e) =>
                    setNewSubUserData({
                      ...newSubUserData,
                      lastname: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Last Name"
                  className="last_name"
                  disabled = {availableSeats <= 0}
                />
                </div>
                <div className="c-my-acc_form_item_subuser">
                <input
                  value={newSubUserData.email}
                  onChange={(e) =>
                    setNewSubUserData({
                      ...newSubUserData,
                      email: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Email"
                  className="email"
                  disabled = {availableSeats <= 0}
                />
                </div>
              </div>
              {availableSeats > 0 ? (
                <button
                className="c-form_my-btn"
                onClick={handleSave}
              >
                ADD MEMBER
              </button>
              ): (
                <button
                className="no-seats-left-button"
                disabled={availableSeats <= 0}
              >
                NO SEATS LEFT
              </button>
              )}
             
            </div>
          </div>
          <div className="sub_user_add_table_container">
            <TableContainer sx={{ paddingRight: "10px", paddingLeft: "10px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ padding:3 }}> 
                  <TableRow>
                    {labels.map((label, index) => (
                      <TableCell
                        sx={{
                          padding: "0px",
                          paddingTop: "10px",
                          paddingBottom:"10px",
                          paddingLeft: "10px",
                          textAlign: "left",
                          justifySelf: "start",
                          
                        }}
                        key={index}
                        // align="left"
                      >
                        <div className = "table-label"><h4>{label}</h4></div>
                      </TableCell>
                    ))}

                    {/* <TableCell
                    sx={{
                      color: "white",
                      padding: "0px",
                      paddingTop: "10px",
                      textAlign: "left",
                      width: "30%",
                    }}
                  >
                    Date Added
                  </TableCell> */}
                    {/* <TableCell
                    sx={{
                      color: "white",
                      padding: "0px",
                      paddingTop: "10px",
                      width: "20%",
                    }}
                  /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subUserData.map((data, index) => (
                    <TableRow
                      key={index}
                      ref={rowIndex === index ? rowRef : null}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        scope="row"
                        sx={{
                          color: "white",
                          paddingLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Person2Outlined
                            style={{
                              border: "1px solid #e5",
                              borderRadius: "50%",
                              width: "3rem",
                              height: "3rem",
                              padding: "2px",
                              backgroundColor:
                                data.firstname && data.lastname
                                  ? "#3cb4e4"
                                  : "gray", // Conditional background color
                            }}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              fontSize: "14px",
                              justifyContent: "center",
                              marginLeft: "20px",
                            }}
                          >
                            {formatName(data.firstname, data.lastname)}
                            {data.email && (
                              <p style={{ marginTop: "5px" , color: "gray" }}>{data.email}</p>
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {data.createdAt ? (
                          <>
                            <div style={{ textAlign: "left" }}>
                              {formatDate(data.createdAt)}
                            </div>
                          </>
                        ) : (
                          <div style={{ textAlign: "left"}}>
                              EMPTY  SEAT
                        </div>
                        )}
                      </TableCell>
                        {data.userType === "studio-owner"? (<TableCell></TableCell>): (
                          <TableCell>
                        { data.firstname && data.lastname && data.email ? (
                          <>
                            <Edit
                              onClick={() => openEditModal(data)} // Open edit modal on click
                              sx={{
                                color: "white",
                                cursor: "pointer",
                                marginRight: "10px",
                              }}
                            />
                            <Delete
                              onClick={() => handleDeleteClick(data)}
                              sx={{
                                color: "white",
                                cursor: "pointer",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Edit
                              sx={{
                                color: "gray", // Disable color
                                cursor: "not-allowed", // Show disabled cursor
                                marginRight: "10px",
                              }}
                              disabled
                            />
                            <Delete
                              sx={{
                                color: "gray", // Disable color
                                cursor: "not-allowed", // Show disabled cursor
                              }}
                              disabled
                            />
                          </>
                        )}
                      </TableCell>
                        )}
                     
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
         
          {userToEdit && (
            <EditSubUserModal
              open={isEditModalOpen}
              handleClose={() => setIsEditModalOpen(false)}
              userData={userToEdit}
              onSave={handleEdit} // Pass the handleSave function
            />
          )}

          <ConfirmationModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={handleDeleteConfirm}
          />
        </>
      ) : (
        <div className="activate-subscription">* Please activate any of the studio plans</div>
      )}
    </div>
  );
};

export default SubUserAdd;
