import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import "./PlayerPage.css";
import NewComment from "./NewComment";
import axios from "axios";
import "./PlayerPage.css";
import { CommentsContext } from "./Comments";
import toast from "react-hot-toast";
import UserImage from "../UserImage/UserImage";
import Popover from "@mui/material/Popover";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function DisplayComment({ comment, ...props }) {
  const location = useLocation();
  const userInfo = useSelector((state) => state.authState.userInfo);
  const [originalCommentForEdit, setOriginalCommentForEdit] = useState("");
  const [editedComment, setEditedComment] = useState("");
  const [showMoreReplies, setShowMoreReplies] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState("");
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const contentRef = useRef(null);
  const editingRef = useRef(null);

  const {
    comments,
    setComments,
    replying,
    setReplying,
    editing,
    setEditing,
    deleteComment,
    setDeleteIsOpen,
    setDeleteComment,
    setTotalComments,
  } = useContext(CommentsContext);

  const setReplyCallback = useCallback(() => {
    setEditing("");

    if (replying === comment._id) {
      setReplying("");
    } else {
      setReplying(comment._id);
    }
  }, [replying]);

  const editLock = useRef(false);
  const goEditComment = useCallback(() => {
    if (editLock.current) {
      return null;
    }

    editLock.current = true;
    setShiftPressed(false);

    if (
      editedComment.length === 0 ||
      editedComment === originalCommentForEdit
    ) {
      setEditing("");
      editLock.current = false;
      return null;
    }

    const loadingToastId = toast.loading("Editing comment..", {
      position: "bottom-center",
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/comments/edit`, {
        commentId: editing,
        content: editedComment,
      })
      .then(({ data }) => {
        toast.dismiss(loadingToastId);

        if (data.error) {
          toast.error(data.error, {
            position: "bottom-center",
          });
          editLock.current = false;
          return null;
        }

        if (data.requiresValidation) {
          toast.success(
            "Edited comment is now pending for approval from administration.",
            {
              position: "bottom-center",
            }
          );
        } else {
          toast.success("Successfully updated!", {
            position: "bottom-center",
          });

          const updatedComments = [...comments].map((comment) => {
            if (comment._id === editing) {
              comment = {
                ...comment,
                content: editedComment,
              };
            }

            comment.replies = comment.replies.map((reply) => {
              if (reply._id === editing) {
                reply = {
                  ...reply,
                  content: editedComment,
                };
              }

              return reply;
            });

            return comment;
          });

          setComments(updatedComments);
        }

        setEditing("");
        editLock.current = false;
      });
  }, [editedComment, originalCommentForEdit, editing, editLock, comments]);

  const [popoverEditItem, setPopoverEditItem] = useState({});

  const editPopoverHandle = useCallback(() => {
    setReplying("");
    setEditing(popoverEditItem._id);
    setEditedComment(popoverEditItem.content);
    setOriginalCommentForEdit(popoverEditItem.content);
    setPopoverAnchor(null);

    setTimeout(() => {
      if (editingRef.current) {
        editingRef.current.focus();
        editingRef.current.setSelectionRange(
          popoverEditItem.content.length,
          popoverEditItem.content.length
        );
      }
    });
  }, [popoverEditItem]);

  const deletePopoverHandle = useCallback(() => {
    setCommentToDelete(popoverEditItem._id);
    setDeleteIsOpen(true);
    setPopoverAnchor(null);
  }, [popoverEditItem]);

  const deleteLock = useRef(false);
  const goDeleteComment = useCallback(
    (commentId) => {
      if (deleteLock.current) {
        return null;
      }

      deleteLock.current = true;

      const loadingToastId = toast.loading("Deleting comment..", {
        position: "bottom-center",
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/comments/delete`, {
          commentId,
        })
        .then(({ data }) => {
          toast.dismiss(loadingToastId);

          if (data.error) {
            toast.error(data.error, {
              position: "bottom-center",
            });
            deleteLock.current = false;
            return null;
          }

          toast.success("Successfully deleted!", {
            position: "bottom-center",
          });

          const updatedComments = [...comments]
            .map((comment) => {
              comment.replies = comment.replies.filter(
                (reply) => reply._id !== commentId
              );
              return comment;
            })
            .filter((comment) => comment._id !== commentId);

          setTotalComments((prev) => {
            const comment = comments.find((c) => c._id === commentId);
            if (comment && comment.replies.length > 0) {
              return prev - comment.replies.length - 1;
            }
            return prev - 1;
          });

          setDeleteComment(false);
          setComments(updatedComments);
          deleteLock.current = false;
        });
    },
    [comments, deleteLock]
  );

  const handleDeleteComment = useCallback(() => {
    if (commentToDelete.length === 0) {
      return null;
    }

    if (
      commentToDelete === comment._id ||
      comment.replies.some((reply) => reply._id === commentToDelete)
    ) {
      goDeleteComment(commentToDelete);
    }
  }, [deleteLock, commentToDelete]);

  useEffect(() => {
    if (deleteComment) {
      handleDeleteComment();
    }
  }, [deleteComment]);

  const repliesRef = useRef([]);
  const [isTargetted, setIsTargetted] = useState(null); // parent or index of replies or null

  const [timeoutId, setTimeoutId] = useState(0);
  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  // Scroll on element after rendering is finished if target is present.
  useEffect(() => {
    const timer = setTimeout(() => {
      const target = new URLSearchParams(location.search).get("target");

      if (target) {
        if (target === comment._id && contentRef.current) {
          setIsTargetted("parent");

          const position =
            contentRef.current.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            148;
          window.scrollTo({ behavior: "instant", top: position });
        } else if (repliesRef.current) {
          const replyIndex = comment.replies.findIndex(
            (reply) => reply._id === target
          );

          if (replyIndex > -1) {
            setShowMoreReplies(true);
          }

          if (replyIndex > -1 && repliesRef.current[replyIndex]) {
            setIsTargetted(replyIndex);

            const position =
              repliesRef.current[replyIndex].getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              148;
            window.scrollTo({ behavior: "instant", top: position });
          }
        }
      }
    }, 500);
    setTimeoutId(timer);
  }, [repliesRef]);

  useEffect(() => {
    repliesRef.current = repliesRef.current.slice(0, comment.replies.length);
  }, [comment.replies]);

  const popoverOpen = Boolean(popoverAnchor);
  const popoverId = popoverOpen ? "comment-utility-popover" : undefined;

  if (comment.replyTo) {
    return null;
  }

  return (
    <div className="c-video_comment-wrapper" {...props}>
      <div
        className="c-video_comment-show"
        style={
          isTargetted === "parent"
            ? {
              boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 10px 2px",
              padding: "8px",
              margin: "4px",
              borderRadius: "8px",
            }
            : {}
        }
      >
        <UserImage imageURL={comment.image} isComment />

        <div
          className="c-video_comment-details"
          style={{
            marginTop:
              userInfo.userId === comment.userID || userInfo.role === "admin"
                ? "6px"
                : "12px",
          }}
        >
          <div className="c-video_comment-author">
            <span className="c-video_comment-author-name">{`${comment.userDetails[0].firstname} ${comment.userDetails[0].lastname}`}</span>
            <time
              className="c-video_comment-author-date"
              date={comment.createdAt}
            >
              {new Date(comment.createdAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </time>
            {(userInfo.userId === comment.userID ||
              userInfo.role === "admin") && (
                <div
                  className="c-video_comment-options"
                  onClick={(e) => {
                    setPopoverAnchor(e.currentTarget);
                    setPopoverEditItem(comment);
                  }}
                >
                  <div className={"c-video_comment-dot"} />
                  <div className={"c-video_comment-dot"} />
                  <div className={"c-video_comment-dot"} />
                </div>
              )}
          </div>
          <div ref={contentRef} className="c-video_comment-content">
            {editing !== comment._id ? (
              comment.content.split("\n").map((line, index) => (
                <p key={index} className={"c-video_comment-content-p"}>
                  {line}
                </p>
              ))
            ) : (
              <>
                <textarea
                  ref={editingRef}
                  className={"c-video_new-comment-area"}
                  style={{ marginTop: "-8px" }}
                  rows={2}
                  placeholder={`Edited comment cannot be empty!`}
                  onChange={(e) => {
                    setEditedComment(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Shift") {
                      setShiftPressed(true);
                    }

                    if (e.key === "Enter" && !shiftPressed) {
                      e.preventDefault();
                      goEditComment();
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Shift") {
                      setShiftPressed(false);
                    }
                  }}
                >
                  {comment.content}
                </textarea>
                <button
                  className={"c-video_comments-button-wrapper"}
                  style={{
                    width: "max-content",
                    height: "max-content",
                    marginBottom: "12px",
                  }}
                  onClick={goEditComment}
                >
                  <div className={"c-video_comments-button-wrapper-inner"}>
                    SAVE
                  </div>
                </button>
              </>
            )}
          </div>
          <div
            classname={"c-video_comment-reply-utility"}
            style={{
              display: "flex",
              marginTop: "-20px",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {comment.replies.length > 1 && !showMoreReplies ? (
              <div
                className={"c-video_comment-show-more"}
                onClick={() => {
                  setShowMoreReplies(true);
                }}
              >
                <span className={"c-video_comment-show-more-text"}>{`Show ${comment.replies.length - 1
                  } more ${comment.replies.length - 1 === 1 ? "Reply" : "Replies"
                  }`}</span>
              </div>
            ) : (
              comment.replies.length > 1 &&
              showMoreReplies && (
                <div
                  className={"c-video_comment-show-more"}
                  onClick={() => {
                    setShowMoreReplies(false);
                    if (contentRef.current) {
                      const rect = contentRef.current.getBoundingClientRect();
                      const isOffScreen =
                        rect.bottom < 0 ||
                        rect.right < 0 ||
                        rect.left > window.innerWidth ||
                        rect.top > window.innerHeight;

                      if (isOffScreen) {
                        contentRef.current.scrollIntoView({
                          behavior: "instant",
                        });
                      }
                    }
                  }}
                >
                  <span
                    className={"c-video_comment-show-more-text"}
                  >{`Hide Replies`}</span>
                </div>
              )
            )}
            <div
              className={"c-video_comment-reply-plain-button"}
              onClick={setReplyCallback}
            >
              Reply
            </div>
          </div>

          {replying === comment._id && (
            <div className="c-video_comment-reply">
              <NewComment videoId={comment.videoID} replyTo={comment._id} />
            </div>
          )}

          {comment.replies.length > 0 && (
            <div
              key={showMoreReplies}
              className={"c-video_comment-replies-wrapper"}
            >
              {showMoreReplies ? (
                comment.replies.map((reply, idx) => (
                  <div
                    key={idx}
                    ref={(el) => (repliesRef.current[idx] = el)}
                    className={"c-video_comment-replies-wrapper slide-fade-in"}
                  >
                    <div
                      key={idx}
                      className="c-video_comment-show"
                      style={
                        isTargetted === idx
                          ? {
                            boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 10px 2px",
                            padding: "8px",
                            margin: "4px",
                            borderRadius: "8px",
                          }
                          : {}
                      }
                    >
                      <UserImage
                        imageURL={reply?.images[0]?.url}
                        isComment
                      />

                      <div
                        className="c-video_comment-details"
                        style={{
                          marginTop:
                            userInfo.userId === reply.userID ||
                              userInfo.role === "admin"
                              ? "6px"
                              : "12px",
                        }}
                      >
                        <div className="c-video_comment-author">
                          <span className="c-video_comment-author-name">{`${reply.userDetails[0].firstname} ${reply.userDetails[0].lastname}`}</span>
                          <time
                            className="c-video_comment-author-date"
                            date={reply.createdAt}
                          >
                            {new Date(reply.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </time>
                          {(userInfo.userId === reply.userID ||
                            userInfo.role === "admin") && (
                              <div
                                className="c-video_comment-options"
                                onClick={(e) => {
                                  setPopoverAnchor(e.currentTarget);
                                  setPopoverEditItem(reply);
                                }}
                              >
                                <div className={"c-video_comment-dot"} />
                                <div className={"c-video_comment-dot"} />
                                <div className={"c-video_comment-dot"} />
                              </div>
                            )}
                        </div>
                        <div className="c-video_comment-content">
                          {editing !== reply._id ? (
                            reply.content.split("\n").map((line, index) => (
                              <p
                                key={index}
                                className={"c-video_comment-content-p"}
                              >
                                {line}
                              </p>
                            ))
                          ) : (
                            <>
                              <textarea
                                ref={editingRef}
                                className={"c-video_new-comment-area"}
                                style={{ marginTop: "-8px" }}
                                rows={2}
                                placeholder={`Edited comment cannot be empty!`}
                                onChange={(e) => {
                                  setEditedComment(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Shift") {
                                    setShiftPressed(true);
                                  }

                                  if (e.key === "Enter" && !shiftPressed) {
                                    e.preventDefault();
                                    goEditComment();
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (e.key === "Shift") {
                                    setShiftPressed(false);
                                  }
                                }}
                                defaultValue={reply.content}
                              />
                              <button
                                className={"c-video_comments-button-wrapper"}
                                style={{
                                  width: "max-content",
                                  height: "max-content",
                                  marginBottom: "12px",
                                }}
                                onClick={goEditComment}
                              >
                                <div
                                  className={
                                    "c-video_comments-button-wrapper-inner"
                                  }
                                >
                                  SAVE
                                </div>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="c-video_comment-show slide-fade-up"
                  style={
                    isTargetted === "parent"
                      ? {
                        boxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 10px 2px",
                        padding: "8px",
                        margin: "4px",
                        borderRadius: "8px",
                      }
                      : {}
                  }
                >
                  <UserImage
                    imageURL={comment.replies[0]?.images[0]?.url }
                    isComment
                  />

                  <div className="c-video_comment-details">
                    <div className="c-video_comment-author">
                      <span className="c-video_comment-author-name">{`${comment.replies[0].userDetails[0].firstname} ${comment.replies[0].userDetails[0].lastname}`}</span>
                      <time
                        className="c-video_comment-author-date"
                        date={comment.replies[0].createdAt}
                      >
                        {new Date(
                          comment.replies[0].createdAt
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </time>
                      {(userInfo.userId === comment.replies[0].userID ||
                        userInfo.role === "admin") && (
                          <div
                            className="c-video_comment-options"
                            onClick={(e) => {
                              setPopoverAnchor(e.currentTarget);
                              setPopoverEditItem(comment.replies[0]);
                            }}
                          >
                            <div className={"c-video_comment-dot"} />
                            <div className={"c-video_comment-dot"} />
                            <div className={"c-video_comment-dot"} />
                          </div>
                        )}
                    </div>

                    <div className="c-video_comment-content">
                      {editing !== comment.replies[0]._id ? (
                        comment.replies[0].content
                          .split("\n")
                          .map((line, index) => (
                            <p
                              key={index}
                              className={"c-video_comment-content-p"}
                            >
                              {line}
                            </p>
                          ))
                      ) : (
                        <>
                          <textarea
                            ref={editingRef}
                            className={"c-video_new-comment-area"}
                            style={{ marginTop: "-8px" }}
                            rows={2}
                            placeholder={`Edited comment cannot be empty!`}
                            onChange={(e) => {
                              setEditedComment(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Shift") {
                                setShiftPressed(true);
                              }

                              if (e.key === "Enter" && !shiftPressed) {
                                e.preventDefault();
                                goEditComment();
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.key === "Shift") {
                                setShiftPressed(false);
                              }
                            }}
                            defaultValue={comment.replies[0].content}
                          />
                          <button
                            className={"c-video_comments-button-wrapper"}
                            style={{
                              width: "max-content",
                              height: "max-content",
                              marginBottom: "12px",
                            }}
                            onClick={goEditComment}
                          >
                            <div
                              className={
                                "c-video_comments-button-wrapper-inner"
                              }
                            >
                              SAVE
                            </div>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={popoverAnchor}
        onClose={() => {
          setPopoverAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
            backgroundColor: "transparent",
          },
        }}
      >
        <div className={"c-video_comment-utility-popover"}>
          <button className={"c-video_comments-button-wrapper"}>
            <div
              className={"c-video_comments-button-wrapper-inner"}
              onClick={editPopoverHandle}
            >
              EDIT
            </div>
          </button>
          <button
            className={"c-video_comments-button-wrapper"}
            onClick={deletePopoverHandle}
          >
            <div className={"c-video_comments-button-wrapper-inner"}>
              DELETE
            </div>
          </button>
        </div>
      </Popover>
    </div>
  );
}

export default DisplayComment;
