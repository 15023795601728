import "../../Asset/NewSignUp.css";

function XButton({ ...props }) {
  return (
    <button
      className={"x-button"}
      aria-label="navigate-out-button"
      type="button"
      {...props}
    >
      X
    </button>
  );
}

export default XButton;
