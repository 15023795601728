import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "../../App.css";
import Video_title from "../Video_title";
import Card from "../Card";
import { VideoContext } from "../Video_player/VideoContext";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { IoMdHeartEmpty } from "react-icons/io";
import { FcLike } from "react-icons/fc";
import CustomPagination from "../Pagination/CustomPagination";
import checkApiStatus from "../SubscripStatus";
import { useSelector } from "react-redux";
import MobileViewSearch from "../MobileViewSearch";
import toast from "react-hot-toast"
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import AddToPlayListModal from "../playlist/personal-playlist/AddToPlayListModal";
import api from "../../data/api";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
const SearchPageList = () => {
  const [responseData, setResponseData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //const {responseData, setResponseData} = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortby, setSortBy] = useState("");
  const location = useLocation();
  const redirect = useNavigate();
  const [videos, setVideos] = useState("");
  const userInfo = useSelector((state) => state.authState.userInfo);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [totalVideos, setTotalVideos] = useState(0);
  const [stIndex, setStIndex] = useState(0);
  const [perPage, setPerPage] = useState(24);
  const [videoData2, setVideoData2] = useState({});
  const [modal, setModal] = useState(false);
  const userData = useAtomValue(userInfoAtom);
  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    console.log("New Page:", newPage);
    console.log("Sort BY:", sortby);
    if (newPage <= 0) {
      newPage = 1;
    }
    setCurrentPage(newPage);
    const newStartIndex = (newPage - 1) * perPage;
    console.log("New Start Index:", newStartIndex);
    setStIndex(newStartIndex);
  };

  console.log(searchKeyword);
  //const categoryId="ObjectId('6541e9cdf1f99641d644a357')";
  useEffect(() => {
    const token = userData.token;
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData.token]);
  //----------------|Add To playlist----------------
  const handelSubmitPlaylist = async (_id) => {
    //setModal(true)
    try {
      const response = await api.videoPlayerPage(_id);
      if (response.status === 200) {
        setModal(false)
        const jsonData = (response.data[0]);
        setVideoData2(jsonData);
        setModal(true)
      }
    } catch (error) {
      setModal(false);
      toast.error('Something went wrong', {
        position: 'top-center',
        duration: 3000
      })
    }
  }
  //
  useEffect(() => {
    const searchquery = new URLSearchParams(location.search).get(
      "search_query"
    );
    console.log(searchquery);
    if (searchquery) {
      console.log("searchquery", searchquery);
      fetchSearchResults(searchquery);
    }
  }, [location.search, currentPage, stIndex, sortby]);

  const fetchSearchResults = async (searchquery) => {
    try {
      const userId = userInfo && userInfo.userId ? userInfo.userId : "";
      const endIndex = stIndex + perPage - 1;
      const response = await api.searchVideo(
        searchquery,
        sortby,
        endIndex,
        stIndex,
        userId
      );
      console.log(response.data);
      console.log(response.data.videoCount);
      console.log(
        "Total Pages:" + Math.ceil(response.data.videoCount / perPage)
      );
      setTotalVideos(Math.ceil(response.data.videoCount / perPage));
      setResponseData(response.data.videos);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const categoryId = "6541ea30f1f99641d644a35d";
  const { setSelectedVideo } = useContext(VideoContext);

  const handleSubmit = async (id, video) => {
    console.log(id);
    const isLiked = video.videoDetails?.savedvideo || video.savedvideo;
    try {
      if (isLiked) {
        const response = await api.unsaveWishlist(id);
        console.log(response.data);
        const uniqueIdentifier = id;
        if (response.status === 200) {
          const updatedResponseData = responseData.map((video) => {
            if (video?._id === uniqueIdentifier) {
              return {
                ...video,
                savedvideo: false,
              };
            }
            return video;
          });

          // Update the state (setResponseData) with the updated responseData
          setResponseData(updatedResponseData);
        }
      } else {
        const response = await api.saveWishlist(id);
        if (response.status === 200) {
          console.log(response.data);
          const uniqueIdentifier = id;
          const updatedResponseData = responseData.map((video) => {
            if (video?._id === uniqueIdentifier) {
              return {
                ...video,
                savedvideo: true,
              };
            }
            return video;
          });

          setResponseData(updatedResponseData);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  //--------for video player page------
  const handlePlayerVideo = async (id) => {
    const apistatus = await checkApiStatus(userData.token);
    if (isLoggedIn) {
      if (apistatus) {
        // User is logged in and subscribed, redirect to PlayerPage
        console.log("video is is -----", id);
        redirect(`/PlayerPage?v=${id}`);
      } else {
        // User is logged in but not subscribed, redirect to PlanPage
        redirect("/Plans");
      }
    } else {
      // User is not logged in, redirect to SignUp
      redirect("/SignUp");
    }
  };

  const handleFilterChange = (event) => {
    setSortBy(event.target.value);
  };

  // const fetchVideosByTags = async (sort_by, newStartIndex) => {
  //   try {
  //     const userId = userInfo && userInfo.userId ? userInfo.userId : '';
  //     console.log('Sort St Index:' + stIndex);
  //     const endIndex = newStartIndex + perPage - 1;
  //     console.log('Sort End Index:' + endIndex);

  //     const response = await api.searchVideo(searchKeyword, sort_by, endIndex, newStartIndex, userId);

  //     setTotalVideos(
  //       Math.ceil(response.data.videocount / perPage)
  //     );

  //     if (response.data.videos.length > 0) {
  //       setResponseData(response.data.videos);
  //     } else {
  //       console.log("No Data");
  //     }

  //   } catch (error) {
  //     console.error('Error fetching filtered videos: ', error);
  //   }
  // };

  const handleShuffle = (categoryId) => {
    setResponseData((prevData) => {
      // Find the category to shuffle
      const shuffledCategory = prevData.find(
        (category) => category._id === categoryId
      );

      // Shuffle the videos in the category
      if (shuffledCategory) {
        shuffledCategory.videos = shuffleArray(shuffledCategory.videos);
      }

      return [...prevData];
    });
  };
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  return (
    <>
      {/* ///////////////////////////////////////////////Mobile */}
      <div id="c-mobile_search">
        <div class="row m-0 c-tab_box">
          <div class="p-0">
            <div className="c-filter_btn">
              <MobileViewSearch />
              <div className="c-filter_btn-one c-mobile_filter_btn2">
                <span>Sort by</span>
                <select className="filterby" onChange={handleFilterChange}>
                  <option value={"latest"}>Latest First</option>
                  <option value={"oldest"}>Oldest First</option>
                  <option value={"title_asc"}>Title (A - Z)</option>
                  <option value={"title_desc"}>Title (Z - A)</option>
                  <option value={"long_duration"}>Long duration</option>
                  <option value={"short_duration"}>Short duration</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////////////////////////////loptop */}

      <div
        id="c-mobile_tab_hide"
        className="c-tab_cata c-tab_cata_across pb-2 "
      >
        <div className="row m-0 c-tab_box">
          <div className="col-md-8">
            <div className="c-filter_btn c-filter_btn-lessons">
              <div className="c-filter_btn-one">
                <span>Sort by</span>
                <select className="filterby" onChange={handleFilterChange}>
                  <option value={"latest"}>Latest First</option>
                  <option value={"oldest"}>Oldest First</option>
                  <option value={"title_asc"}>Title (A - Z)</option>
                  <option value={"title_desc"}>Title (Z - A)</option>
                  <option value={"long_duration"}>Long duration</option>
                  <option value={"short_duration"}>Short duration</option>
                </select>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="tab-content">
          <div id="menu2" className="tab-pane fade active show">
            <div>
              <div className="c-video ">
                <div className="row c-video_textrow c-video_list pb-2 pt-3">
                  <div className="col-md-8 c-video_textcol c-mobile_videotext-1 search">
                    <h4>Search</h4>
                  </div>
                </div>
                {/* Your video list here */}
                <div className="c-video_list">
                  <div className="row m-0">
                    {responseData?.map((video, index) => (
                      <div className="col-md-3 c-mobile_col">
                        <div className="c-video_box">
                          <div className="c-video_icon-box">
                            <div onClick={() => handlePlayerVideo(video?._id)}>
                              <Card
                                thumb_url={video?.thumnailLink}
                                video_url={video?.videoDetails}
                                thumbnail_pic={video?.thumnailLink}
                                video_duration={video?.duration}
                              // playerRef={playerRef}
                              />
                            </div>
                            {isLoggedIn && (
                              <PlaylistAddRoundedIcon
                                fontSize="large"
                                style={{
                                  cursor: "pointer",
                                  boxSizing: "border-box",
                                  position: "absolute",
                                  top: "1px",
                                  right: "1px",
                                }}
                                onClick={() => handelSubmitPlaylist(video.videoDetails?._id || video?._id)}
                              />
                            )}
                            {isLoggedIn && (
                              <div className="c-video_time">
                                <span
                                  id={video.videoDetails?._id || video._id}
                                  onClick={(e) => {
                                    const id = e.currentTarget.id; // Accessing the id from the clicked element
                                    handleSubmit(id, video);
                                  }}
                                >
                                  {video?.savedvideo || video?.savedvideo ? (
                                    <FcLike />
                                  ) : (
                                    <IoMdHeartEmpty />
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                          {/* Add conditional rendering for Video_title */}
                          <div
                            onClick={() =>
                              handlePlayerVideo(
                                video.videoDetails?._id || video._id
                              )
                            }
                          >
                            <Video_title
                              title={video?.title}
                              tags={(video?.tags || [])
                                .map((tag) => ({
                                  name: tag.referalId ? tag.referalId.name : "",
                                  color: tag.referalId ? tag.referalId.color : "",
                                  priority: tag.referalId
                                    ? tag.referalId.priority || 0
                                    : 0,
                                }))
                                .sort((a, b) => a.priority - b.priority)}
                              releaseDate={video?.releaseDateTime}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* Material-UI Pagination */}
                <CustomPagination
                  totalPages={totalVideos}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddToPlayListModal
        modal={modal}
        setModal={setModal}
        activeObject={videoData2}
        viewData={videos}
      />
    </>
  );
};

export default SearchPageList;
