import axios from "axios";
// apiUtils.js
const checkApiStatus = async (token = "") => {
  axios.defaults.withCredentials = true;
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fetch/subscribtion`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 200) {
      if (response.data.data === "in_trial") {
        localStorage.setItem("subscriptionId", response.data.subData.SubscribtionId);
        return true;
      } else if (response.data.data === "active") {
        localStorage.setItem("subscriptionId", response.data.subData?.SubscribtionId);
        return true;
      } else if (response.data.data === "non_renewing") {
        localStorage.setItem("subscriptionId", response.data.subData.SubscribtionId);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error calling API:", error);
    return false;
  }
};
export default checkApiStatus;
