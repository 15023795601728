import { useAtomValue } from "jotai";
import { baseUrl } from "../utils/globals";
import { useSwrData } from "./swrWrapper";
import { userInfoAtom } from "../atoms/utilsAtoms";

export const useFetchAllBanners = () => {
    const user = useAtomValue(userInfoAtom)
    const url = `${baseUrl}/banner/all`;
    const  data  = useSwrData(url, user.token, null);
    return data;
}

export const useFetchBannerById = (id) => {
    const user = useAtomValue(userInfoAtom)
    const url = `${baseUrl}/banner/${id}`;
    const  data  = useSwrData(url, user.token, null);
    return data;
}

export const useFetchActiveBanner = () => {
    const user = useAtomValue(userInfoAtom)
    const url = `${baseUrl}/banner`;
    const  data  = useSwrData(url, user.token, null);
    return data;
}