import React, { useState, useEffect, useRef, useContext } from "react";
import "../App.css";
import "../Asset/Mobile/Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Video_title from "./Video_title";
import Card from "../component/Card";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import checkApiStatus from "./SubscripStatus";
import { IoMdHeartEmpty } from "react-icons/io";
import { FcLike } from "react-icons/fc";
import { useSelector, useDispatch } from "react-redux";
import { setUserInfo } from "../redux/authSlice";
import Cookiesj from "universal-cookie";
import MobileViewSearch from "./MobileViewSearch";
import toast from "react-hot-toast";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import AddToPlayListModal from "./playlist/personal-playlist/AddToPlayListModal";
import { VideoContext } from "./Video_player/VideoContext";
import api from "../data/api";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import { useFetchActiveBanner } from "../data/swrs/banners";
import videoApi from "../api/videoApi";

function VideoSection() {
  axios.defaults.withCredentials = true;
  const userInfo = useSelector((state) => state.authState.userInfo);
  const cookies = new Cookiesj();
  const dispatch = useDispatch();

  const [likedStates, setLikedStates] = useState([]);
  const playerRef = useRef(null);
  const redirect = useNavigate();
  const [responseData, setResponseData] = useState([]);
  const [modal, setModal] = useState(false);
  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);
  const [videoData, setVideoData] = useState({});
  const [videoData2, setVideoData2] = useState({});
  const { data: bannerImage } = useFetchActiveBanner();

  const [savedVideoIds, setSavedVideoIds] = useState([])
  const userData = useAtomValue(userInfoAtom)

  useEffect(() => {
    if (userData?.token) {
      videoApi.getAllSavedVideoIds(userData.user?.userId)
        .then((res) => {
          setSavedVideoIds(res.data.savedVideos)
        })
    }
  }, [userData.token, responseData])

  useEffect(() => {
    const data = window.localStorage.getItem("selectedVideo");

    if (data) {
      setVideoData(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      setVideoData(selectedVideo);
    }
  }, [selectedVideo]);

  // ----------| Add TO Playlist |--------------

  const handelSubmitPlaylist = async (_id) => {
    //setModal(true)
    try {
      const response = await api.videoPlayerPage(_id);
      if (response.status === 200) {
        setModal(false);
        const jsonData = response.data[0];
        setVideoData2(jsonData);
        setModal(true);
      }
    } catch (error) {
      setModal(false);
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  };

  //--------for video player page------
  const handlePlayerVideo = async (id) => {
    const apistatus = await checkApiStatus(userData.token);
    if (userData.token) {
      if (apistatus) {
        redirect(`/PlayerPage?v=${id}`);
      } else {
        //------- User is logged in but not subscribed, redirect to PlanPage----------
        redirect("/Plans");
      }
    } else {
      //---------User is not logged in, redirect to SignUp------------
      redirect("/SignUp");
    }
  };

  const handleSubmit = async (id, video) => {
    //console.log(id);
    const isLiked =
      likedStates[`${video.videoDetails?._id || video._id}`] ||
      video.videoDetails?.savedvideo ||
      video.savedvideo || (savedVideoIds?.length > 0 && savedVideoIds?.includes(video?.videoDetails?._id || video?._id));

    if (isLiked) {
      try {
        const response = await api.unsaveWishlist(id, userData.token);
        //console.log(response.data);
        const uniqueIdentifier = id;
        if (response.status === 200) {
          toast.success("Unsaved", {
            position: "top-center",
            duration: 3000,
          });
          const updatedResponseData = responseData.map((item) => {
            const updatedVideos = item.videos.map((video) => {
              if (video?._id === uniqueIdentifier) {
                return {
                  ...video,
                  savedvideo: false, // Set savedvideo to false for the matched video
                };
              }
              return video;
            });
            return {
              ...item,
              videos: updatedVideos,
            };
          });

          // Update the state (setResponseData) with the updated responseData
          setResponseData(updatedResponseData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      try {
        const response = await api.saveWishlist(id, userData.token);
        if (response.status === 200) {
          toast.success("Saved", {
            position: "top-center",
            duration: 3000,
          });
          //console.log(response.data);
          const uniqueIdentifier = id;
          const updatedResponseData = responseData.map((item) => {
            const updatedVideos = item.videos.map((video) => {
              if (video?._id === uniqueIdentifier) {
                return {
                  ...video,
                  savedvideo: true, // Set savedvideo to false for the matched video
                };
              }
              return video;
            });
            return {
              ...item,
              videos: updatedVideos,
            };
          });

          setResponseData(updatedResponseData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userData.token) {
          const response = await api.getHomeUsers(userData.token);
          if (response.status === 200) {
            setResponseData(response.data);
          } else {
            // Cookies.remove("token");
            // cookies.remove("user");
            // dispatch(setUserInfo(null));
            // redirect("/");
          }
        } else {
          const response = await api.getHome();
          setResponseData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [userData.token]);

  const handleShowMoreClick = () => {
    redirect("/saved");
  };

  return (
    <>
      <div className="row m-0 c-mobile_honm-serch">
        <MobileViewSearch />
      </div>
      <div className="container-c-section">
        {userData.user ? (
          <div className="c-join-section">
            {userData.user
              ? `Welcome, ${userData?.user?.firstname} ${userData?.user?.lastname}.`
              : ""}
          </div>
        ) : (
          <div className="c-join-section">
            Join The Community and Access Exclusive Content
          </div>
        )}
      </div>
      {/* Banner */}
      
      <div className="c-banner-img">
        <img
          src={bannerImage?.data?.image ?? process.env.REACT_APP_DEFAULT_BANNER}
          width="1450"
          height="420"
          alt="Main banner"
        />
      </div>
      <div className="c-tab_cata c-tab_list-sa">
        <div className="row ml-0 mr-0 c-tab_box c-tab_list-sasa">
          <div className="col-md-8">
            <div className="c-tab_list">
              <ul className="nav nav-tabs Name d-lg-none">
                <li>
                  <a
                    data-toggle="tab"
                    class="c-tab_mobile-list"
                    href="#Featured"
                  >
                    Featured
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" href="#Recent">
                    Recent
                  </a>
                </li>

                {userData.token && (
                  <li>
                    <a
                      data-toggle="tab"
                      class="c-tab_list-border-l"
                      href="#Saved"
                    >
                      Saved
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="tab-content">
          <div id="home" className="tab-pane fade in active show">
            {responseData &&
              responseData?.map((category, index) => (
                <div
                  style={{ scrollMarginTop: "6rem" }}
                  key={index}
                  id={category.name.split(" ")[0]}
                >
                  <div key={index} className="c-tab_cata-text">
                    {category.name.includes("Featured") ? (
                      <div className="c-tab_cata c-tab_list-sa">
                        <div className="row ml-0 mr-0 c-tab_box c-tab_list-sasa">
                          <div className="c-tab_list">
                            <ul className="nav nav-tabs Name">
                              <li>
                                <a
                                  data-toggle="tab"
                                  class="c-tab_mobile-list"
                                  href="#Featured"
                                >
                                  Featured
                                </a>
                              </li>
                              <li>
                                <a data-toggle="tab" href="#Recent">
                                  Recent
                                </a>
                              </li>
                              {userData.token && (
                                <li>
                                  <a
                                    data-toggle="tab"
                                    class="c-tab_list-border-l"
                                    href="#Saved"
                                  >
                                    Saved
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                    <h4>{category.name.split(" ")[0]}</h4>
                  </div>
                  <div className="c-video">
                    <div className="row m-0 c-video_textrow">
                      <div className="col-md-8 c-video_textcol">
                        <h4>{category.name.split(" ")[0]}</h4>
                      </div>
                      <div className="col-md-4 c-video_textcol">
                        {/* <p className="c-view_more" onClick={() => handleShowMoreClick(category.name)}>View all </p> */}
                      </div>
                    </div>
                  </div>
                  {/* // Video card starts from here */}
                  <div className="c-video_list">
                    <div className="row m-0">
                      {category?.videos.map((video, index) => (
                        <div key={index} className="col-md-3 c-mobile_col">
                          <div className="c-video_box">
                            <div className="c-video_icon-box">
                              <div>
                                <Card
                                  thumb_url={
                                    video.videoDetails?.thumnailLink ||
                                    video.thumnailLink || video._doc?.thumnailLink
                                  }
                                  video_url={
                                    video.videoDetails?.videolink ||
                                    video.videoDetails || video._doc?.videolink
                                  }
                                  thumbnail_pic={
                                    video.videoDetails?.thumnailLink ||
                                    video.thumnailLink || video._doc?.thumnailLink
                                  }
                                  playerRef={playerRef}
                                  video_duration={
                                    video.videoDetails?.duration ||
                                    video.duration || video._doc?.duration
                                  }
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video._id || video._doc?._id
                                    )
                                  }
                                />
                              </div>
                              {userData.token && (
                                <PlaylistAddRoundedIcon
                                  fontSize="large"
                                  style={{
                                    cursor: "pointer",
                                    boxSizing: "border-box",
                                    position: "absolute",
                                    top: "1px",
                                    right: "1px",
                                  }}
                                  onClick={() =>
                                    handelSubmitPlaylist(
                                      video.videoDetails?._id || video?._id || video._doc?._id
                                    )
                                  }
                                />
                              )}
                              {userData.token && (
                                <div className="c-video_time">
                                  <span
                                    id={video.videoDetails?._id || video?._id || video._doc?._id}
                                    onClick={(e) => {
                                      const id = e.currentTarget.id; // Accessing the id from the clicked element
                                      handleSubmit(id, video);
                                    }}
                                  >
                                    {video?.videoDetails?.savedvideo || video?.savedvideo 
                                      || (savedVideoIds?.length > 0 && savedVideoIds?.includes(video?.videoDetails?._id || video?._id)) ? (
                                      <FcLike />
                                    ) : (
                                      <IoMdHeartEmpty />
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                            {/* Add conditional rendering for Video_title */}
                            <div
                              onClick={() =>
                                handlePlayerVideo(
                                  video.videoDetails?._id || video._id || video._doc?._id
                                )
                              }
                            >
                              {video?.videoDetails ? (
                                <Video_title
                                  title={video.videoDetails.title || ""}
                                  tags={
                                    (video.videoDetails.tagsDetails || [])
                                      .map((tag) => ({
                                        _id: tag._id,
                                        name: tag.name,
                                        color: tag.color,
                                        priority: tag.priority || 0, // Assign priority or default to 0
                                      }))
                                      .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                                  }
                                  releaseDate={
                                    video.videoDetails.releaseDateTime || ""
                                  }
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id
                                    )
                                  }
                                />
                              ) : (
                                <Video_title
                                  title={video?.title || video._doc?.title || ""}
                                  tags={
                                    (video?.tagsDetails || video._doc?.tags || [])
                                      .map((tag) => ({
                                        _id: tag._id,
                                        name: tag.name,
                                        color: tag.color,
                                        priority: tag.priority || 0, // Assign priority or default to 0
                                      }))
                                      .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                                  }
                                  releaseDate={video?.releaseDateTime || video._doc?.releaseDateTime || ""}
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id || video._doc?._id
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* //////////////////////Mobile Show More Button //////////// */}
                  </div>
                    {index === 2 && (
                      <div className="c-mobile_show-btn">
                        <button
                          onClick={() => handleShowMoreClick(category.name)}
                        >
                          Show More
                        </button>
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </div>

      <AddToPlayListModal
        modal={modal}
        setModal={setModal}
        activeObject={videoData2}
        viewData={videoData}
      />
    </>
  );
}

export default VideoSection;
