export const UserPlan = [
  {
    name: "ANNUAL PLAN",
    price: "$12.49",
    frequency: "month",
    description: "$149.99 billed annually",
    feature: "Access our full video library",
  },
  {
    name: "MONTHLY PLAN",
    price: "$19.99",
    frequency: "month",
    feature: "Access our full video library",
  },
];

export const StudioPlan = [
  {
    name: "STUDIO PLAN FOR 5 SEATS",
    price: "$449.00",
    seats: 5,
    description: "Forever",
    feature: [
      "Account Owner + 4 Additional Users",
      "FREE registration for all Godfrey Method Virtual Classes for active users.",
      "Individual Playlists",
      "FREE 30-minute Zoom call with studio owner & faculty from Jonathan & Chelsea Godfrey",
      "Priority Support",
    ],
  },
  {
    name: "STUDIO PLAN FOR 10 SEATS",
    price: "$699.00",
    seats: 10,
    description: "Forever",
    feature: [
      "Account Owner + 9 Additional Users",
      "FREE registration for all Godfrey Method Virtual Classes for active users.",
      "Individual Playlists",
      "FREE 60-minute Zoom call with studio owner & faculty from Jonathan & Chelsea Godfrey",
      "Priority Support",
    ],
  },
];
