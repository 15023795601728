import axios from "axios";
import { useSetAtom } from "jotai";
import useSWR from "swr";
import { loaderState } from "../atoms/utilsAtoms";

const fetcher = async ({url, token = ""}) => {
  axios.defaults.withCredentials = true;
  if (token?.trim()?.length === 0) return axios.get(url).then((res) => res.data);
  return axios
    .get(url, { headers: { Authorization: "Bearer " + token } })
    .then((res) => res.data);
};

/**
 *
 * @param {String} address - api url
 * @returns
 */
export const useSwrData = (address, token = "", options = {}) => {
  const setLoader = useSetAtom(loaderState);
  const data = useSWR({url:address, token}, fetcher, options);
  setLoader(data.isLoading);
  return data;
};
